import moment from 'moment';
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  Chip,
  Container,
  Grid,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import axios from 'axios';
import { BorderColor, DeleteForever, Edit, Report, WarningAmberOutlined } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Page from '../components/Page';
import BillEditForm from '../components/BillEditForm';
import PaymentsTable from '../components/PaymentsTable';

const ViewBill = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [editMode, setEditMode] = useState(false);
  const [bill, setBill] = useState({});

  const getBill = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/billing`, { params: { invoiceNumber: id } });
      console.log(res.data.data, 'bill');
      setBill(res.data.data.invoice);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getBill();
  }, []);

  return (
    <Page title="View Bill">
      <Container maxWidth="xl">
        <Stack spacing={2}>
          <Card>
            <Box sx={{ padding: '10px' }}>
              {editMode && (
                <>
                  <BillEditForm bill={bill} setEditMode={setEditMode} />
                </>
              )}
              {!editMode && (
                <>
                  <Stack
                    direction={'row'}
                    px={5}
                    py={3}
                    sx={{ backgroundColor: '#fabb0071' }}
                    justifyContent={'space-between'}
                  >
                    {/* <Box sx={{ flex: '1' }}>Logo</Box> */}
                    <Stack direction={'row'} spacing={1} alignItems={'center'}>
                      <Stack justifyContent="center" direction={'column'}>
                        <Stack direction={'row'} spacing={2} alignItems="center">
                          <Typography variant="h5" fontWeight={'bold'}>
                            {bill?.invoiceNumber}
                          </Typography>
                          {/* if bill is overdue show an alert */}
                          {moment(bill?.dueDate).startOf('day').isBefore(moment().startOf('day')) && (
                            <Chip size="small" icon={<WarningAmberOutlined />} label="Overdue" color="error" />
                          )}
                        </Stack>
                        <Stack direction={'row'} spacing={2}>
                          <Typography variant="body2" fontWeight={'bold'}>
                            Date:
                          </Typography>
                          <Typography variant="body2">{moment(bill?.invoiceDate).format('DD/MM/YYYY')}</Typography>
                        </Stack>
                        <Stack direction={'row'} spacing={1}>
                          <Typography variant="body2" fontWeight={'bold'}>
                            Due Date:
                          </Typography>
                          <Typography variant="body2">{moment(bill?.dueDate).format('DD/MM/YYYY')}</Typography>
                        </Stack>
                      </Stack>
                    </Stack>

                    <Stack direction={'row'} spacing={3}>
                      <IconButton
                        onClick={() => setEditMode(true)}
                        sx={{
                          height: 40,
                          width: 40,
                        }}
                      >
                        <BorderColor />
                      </IconButton>
                    </Stack>
                  </Stack>
                  <Grid container>
                    <Grid item xs={12} sm={6}>
                      <Stack px={5} pt={5}>
                        <Typography variant="h6" fontWeight={'bold'} gutterBottom>
                          From
                        </Typography>
                        <Stack paddingLeft={4}>
                          <Typography>{bill?.from?.name}</Typography>
                          <Typography>{bill?.from?.address}</Typography>
                          <Typography>{bill?.from?.pin}</Typography>
                          <Typography>{bill?.from?.phone}</Typography>
                          <Typography>{bill?.from?.email}</Typography>
                        </Stack>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack px={5} pt={5}>
                        <Typography variant="h6" fontWeight={'bold'} gutterBottom>
                          To
                        </Typography>
                        <Stack paddingLeft={4}>
                          <Typography>{bill?.to?.fullname}</Typography>
                          <Typography>{bill?.to?.address}</Typography>
                          <Typography>{bill?.to?.phone}</Typography>
                          <Typography>{bill?.to?.email}</Typography>
                        </Stack>
                      </Stack>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      margin={1}
                      sx={{
                        borderRadius: '5px',
                      }}
                    >
                      <Stack p={5}>
                        <TableContainer component={Paper}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Quantity</TableCell>
                                <TableCell>Unit Price</TableCell>
                                <TableCell>Taxable Amount</TableCell>
                                <TableCell>Tax</TableCell>
                                <TableCell>Total</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {bill?.items?.map((item, index) => (
                                <TableRow key={index}>
                                  <TableCell>{item.name}</TableCell>
                                  <TableCell>₹{item.unitPrice}</TableCell>
                                  <TableCell>{item.quantity}</TableCell>
                                  <TableCell>₹{Number(item.unitPrice) * Number(item.quantity)}</TableCell>
                                  <TableCell>
                                    <Stack direction={'column'} spacing={1}>
                                      {item.tax.map((tax, key) => (
                                        <Typography variant="body2" key={key}>
                                          {tax.name} ({tax.percentage}%) : ₹
                                          {parseFloat(Number(tax.amount) * Number(item.quantity)).toFixed(2)}
                                        </Typography>
                                      ))}
                                    </Stack>
                                  </TableCell>
                                  <TableCell>₹{item.total}</TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Stack>
                    </Grid>
                  </Grid>
                  <Stack direction={'row'} pb={5} justifyContent={'end'}>
                    <Stack justifyContent="center" spacing={2} sx={{ width: '400px', paddingRight: '200px' }}>
                      <Stack direction={'row'} spacing={1} sx={{ width: '100%' }} justifyContent={'space-between'}>
                        <Typography variant="body2" fontWeight={'bold'}>
                          Sub Total:
                        </Typography>
                        <Typography variant="body2">₹{bill?.subTotal}</Typography>
                      </Stack>
                      <Stack direction={'row'} spacing={1} sx={{ width: '100%' }} justifyContent={'space-between'}>
                        <Typography variant="body2" fontWeight={'bold'}>
                          Grand Total:
                        </Typography>
                        <Typography variant="body2">₹{bill?.grandTotal}</Typography>
                      </Stack>
                      <Stack direction={'row'} spacing={1} sx={{ width: '100%' }} justifyContent={'space-between'}>
                        <Typography variant="body2" fontWeight={'bold'}>
                          Discount:
                        </Typography>
                        <Typography variant="body2">₹{bill?.discount}</Typography>
                      </Stack>
                      <Stack direction={'row'} spacing={1} sx={{ width: '100%' }} justifyContent={'space-between'}>
                        <Typography variant="body2" fontWeight={'bold'}>
                          Paid:
                        </Typography>
                        <Typography variant="body2">₹{bill?.paidAmount}</Typography>
                      </Stack>
                      <Stack direction={'row'} spacing={1} sx={{ width: '100%' }} justifyContent={'space-between'}>
                        <Typography variant="body2" fontWeight={'bold'}>
                          Pending:
                        </Typography>
                        <Typography variant="body2">
                          ₹{Number(bill?.grandTotal) - Number(bill?.paidAmount) - Number(bill?.discount)}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                  <Stack justifyContent="center" alignItems={'center'} spacing={2} sx={{ width: '100%' }}>
                    {bill.termsAndConditions && (
                      <Typography variant="body2" sx={{ width: '80%' }} textAlign="center" fontWeight={'bold'}>
                        Terms and Conditions
                      </Typography>
                    )}
                    <Typography sx={{ width: '80%' }} variant="body2">
                      {bill?.termsAndConditions}
                    </Typography>
                  </Stack>
                  {!bill.isPaymentCompleted && (
                    <Stack direction={'row'} p={2} justifyContent={'end'} sx={{ width: '100%' }}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() =>
                          navigate(`/dashboard/accounts/pending-payments/bill/view-payment/${bill.customerId}?`, {
                            state: { invoice: bill },
                          })
                        }
                      >
                        Make Payment
                      </Button>
                    </Stack>
                  )}
                </>
              )}
            </Box>
          </Card>
          {bill?.transactions && !!bill.transactions.length && <PaymentsTable transactions={bill?.transactions} />}
        </Stack>
      </Container>
    </Page>
  );
};

export default ViewBill;
