import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import { useParams } from 'react-router-dom';
import React, { useState } from 'react';
import PaymentsCustomer from './PaymentsCustomer';
import BillTable from './BillTable';

function CustomerTables({ customer }) {
  const { id } = useParams();
  const [value, setValue] = useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange}>
            <Tab label="Payments" value="1" />
            <Tab label="Pending Bills" value="2" />
            <Tab label="Paid Bills" value="3" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <PaymentsCustomer customer={customer} />{' '}
        </TabPanel>
        <TabPanel value="2">
          <BillTable status={'pending'} customerId={customer?._id} />
        </TabPanel>
        <TabPanel value="3">
          <BillTable status={'completed'} customerId={customer?._id} />
        </TabPanel>
      </TabContext>
    </>
  );
}

export default CustomerTables;
