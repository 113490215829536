import moment from 'moment';
import {
  Box,
  Card,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Stack,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  FormControlLabel,
  Checkbox,
  IconButton,
  TextField,
} from '@mui/material';
import { toast } from 'react-toastify';
import { ExpandMore } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import Scrollbar from './Scrollbar';
import PaymentForm from './PaymentForm';

function InvoiceTable({ bill }) {
  const [markToPay, setMarkToPay] = useState([]);
  const [markToPayInvoiceNumber, setMarkToPayInvoiceNumber] = useState([]);
  const [expand, setExpand] = useState([...bill?.invoices?.map((invoice) => false)]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [error, setError] = useState(true);

  const handleMarkToPay = (e, item) => {
    if (e.target.checked) {
      setMarkToPayInvoiceNumber([...markToPayInvoiceNumber, item.invoiceNumber]);
      setMarkToPay([...markToPay, { amount: 0, invoiceNumber: item.invoiceNumber, _id: item._id, discount: 0 }]);
    } else {
      setMarkToPayInvoiceNumber(markToPayInvoiceNumber.filter((invoiceNumber) => invoiceNumber !== item.invoiceNumber));
      setMarkToPay(markToPay.filter((invoice) => invoice.invoiceNumber !== item.invoiceNumber));
    }
  };

  const handleAmountChange = (e, item) => {
    if (e.target.value < 0) {
      toast.error('Amount cannot be negative');
      return;
    }

    const updatedMarkToPay = markToPay.map((invoice) => {
      if (invoice.invoiceNumber === item.invoiceNumber) {
        console.log(e.target.value, 'e.target.value');
        return { ...invoice, amount: e.target.value };
      }
      return invoice;
    });
    console.log(updatedMarkToPay, 'updatedMarkToPay');
    setMarkToPay(updatedMarkToPay);
    const selectedInvoice = bill?.invoices?.find((invoice) => invoice.invoiceNumber === item.invoiceNumber);
    if (parseFloat(Number(selectedInvoice?.grandTotal) - Number(selectedInvoice?.paidAmount)) < e.target.value) {
      setError(true);
      return;
    }
    setError(false);
  };

  const handleDiscountChange = (e, item) => {
    if (e.target.value < 0) {
      toast.error('Discount cannot be negative');
      return;
    }
    const updatedMarkToPay = markToPay.map((invoice) => {
      if (invoice.invoiceNumber === item.invoiceNumber) {
        return { ...invoice, discount: e.target.value };
      }
      return invoice;
    });
    setMarkToPay(updatedMarkToPay);
  };

  const toggleAccordion = (index) => {
    setExpand(
      expand.map((item, i) => {
        if (i === index) {
          return !item;
        }
        return item;
      })
    );
  };

  useEffect(() => {
    const total = markToPay.reduce((acc, item) => acc + Number(item.amount), 0);
    setTotalAmount(total);
    const totalDiscountAmount = markToPay.reduce((acc, item) => acc + Number(item.discount), 0);
    console.log(totalDiscountAmount, 'totalDiscountAmount');
    setTotalDiscount(totalDiscountAmount);
  }, [markToPay]);

  return (
    <Stack spacing={2}>
      <Box padding={'10px'}>
        <Stack spacing={2}>
          <Scrollbar>
            <Typography gutterBottom variant="h5">
              Invoices
            </Typography>
            {bill?.invoices?.map((invoice, index) => (
              <Accordion key={index} expanded={expand[index]}>
                <AccordionSummary
                  aria-controls="panel1a-content"
                  sx={{
                    backgroundColor: '#fabb0071',
                    borderRadius: '10px',
                  }}
                >
                  <Stack
                    direction={'row'}
                    px={5}
                    py={3}
                    justifyContent={'space-between'}
                    sx={{
                      width: '100%',
                    }}
                  >
                    {' '}
                    <Stack direction={'row'} spacing={1}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={!!markToPayInvoiceNumber.find((item) => item === invoice.invoiceNumber)}
                            onChange={(e) => handleMarkToPay(e, invoice)}
                          />
                        }
                      />
                      <Stack justifyContent="center" direction={'column'}>
                        <Typography variant="h5" fontWeight={'bold'}>
                          {invoice?.invoiceNumber}
                        </Typography>
                        <Stack direction={'row'} spacing={2}>
                          <Typography variant="body2" fontWeight={'bold'}>
                            Date:
                          </Typography>
                          <Typography variant="body2">{moment(invoice?.invoiceDate).format('DD/MM/YYYY')}</Typography>
                        </Stack>
                      </Stack>
                    </Stack>
                    <Stack direction={'row'} spacing={2} alignItems={'center'}>
                      <Stack direction={'column'} spacing={1}>
                        <TextField
                          label="Balance Amount"
                          size="small"
                          variant="outlined"
                          value={parseFloat(Number(invoice?.grandTotal) - Number(invoice?.paidAmount)).toFixed(2)}
                        />
                        <TextField
                          label="Amount (&#8377;)"
                          size="small"
                          variant="outlined"
                          disabled={!markToPayInvoiceNumber.find((item) => item === invoice.invoiceNumber)}
                          value={markToPay.find((item) => item.invoiceNumber === invoice.invoiceNumber)?.amount || ''}
                          onChange={(e) => handleAmountChange(e, invoice)}
                          error={
                            (Number(markToPay.find((item) => item.invoiceNumber === invoice.invoiceNumber)?.discount) ||
                              0) +
                              (Number(markToPay.find((item) => item.invoiceNumber === invoice.invoiceNumber)?.amount) ||
                                0) >
                            Number(invoice?.grandTotal) - Number(invoice?.paidAmount)
                          }
                          helperText={
                            (Number(markToPay.find((item) => item.invoiceNumber === invoice.invoiceNumber)?.discount) ||
                              0) +
                              (Number(markToPay.find((item) => item.invoiceNumber === invoice.invoiceNumber)?.amount) ||
                                0) >
                            Number(invoice?.grandTotal) - Number(invoice?.paidAmount)
                              ? 'Discount + Amount cannot be greater than balance amount'
                              : ''
                          }
                        />
                        <TextField
                          label="Discount (&#8377;)"
                          size="small"
                          variant="outlined"
                          disabled={!markToPayInvoiceNumber.find((item) => item === invoice.invoiceNumber)}
                          value={markToPay.find((item) => item.invoiceNumber === invoice.invoiceNumber)?.discount || ''}
                          onChange={(e) => handleDiscountChange(e, invoice)}
                          error={
                            (Number(markToPay.find((item) => item.invoiceNumber === invoice.invoiceNumber)?.discount) ||
                              0) +
                              (Number(markToPay.find((item) => item.invoiceNumber === invoice.invoiceNumber)?.amount) ||
                                0) >
                            Number(invoice?.grandTotal) - Number(invoice?.paidAmount)
                          }
                          helperText={
                            (Number(markToPay.find((item) => item.invoiceNumber === invoice.invoiceNumber)?.discount) ||
                              0) +
                              (Number(markToPay.find((item) => item.invoiceNumber === invoice.invoiceNumber)?.amount) ||
                                0) >
                            Number(invoice?.grandTotal) - Number(invoice?.paidAmount)
                              ? 'Discount + Amount cannot be greater than balance amount'
                              : ''
                          }
                        />
                      </Stack>

                      <IconButton onClick={() => toggleAccordion(index)}>
                        <ExpandMore />
                      </IconButton>
                    </Stack>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails>
                  <Box>
                    <Grid container>
                      <Grid item xs={12} sm={6}>
                        <Stack px={5} pt={5}>
                          <Typography variant="h6" fontWeight={'bold'} gutterBottom>
                            From
                          </Typography>
                          <Stack paddingLeft={4}>
                            <Typography>{invoice?.from?.name}</Typography>
                            <Typography>{invoice?.from?.address}</Typography>
                            <Typography>{invoice?.from?.pin}</Typography>
                            <Typography>{invoice?.from?.phone}</Typography>
                            <Typography>{invoice?.from?.email}</Typography>
                          </Stack>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Stack px={5} pt={5}>
                          <Typography variant="h6" fontWeight={'bold'} gutterBottom>
                            To
                          </Typography>
                          <Stack paddingLeft={4}>
                            <Typography>{invoice?.to?.fullname}</Typography>
                            <Typography>{invoice?.to?.address}</Typography>
                            <Typography>{invoice?.to?.phone}</Typography>
                            <Typography>{invoice?.to?.email}</Typography>
                          </Stack>
                        </Stack>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        margin={1}
                        sx={{
                          borderRadius: '5px',
                        }}
                      >
                        <Stack p={5}>
                          <TableContainer component={Paper}>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell>Name</TableCell>
                                  <TableCell>Unit Price</TableCell>
                                  <TableCell>Tax</TableCell>
                                  <TableCell>Quantity</TableCell>
                                  <TableCell>Total</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {invoice?.items?.map((item, index) => (
                                  <TableRow key={index}>
                                    <TableCell>{item.name}</TableCell>
                                    <TableCell>{item.unitPrice}</TableCell>
                                    <TableCell>{item.tax?.amount}</TableCell>
                                    <TableCell>{item.quantity}</TableCell>
                                    <TableCell>{item.total}</TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Stack>
                      </Grid>
                    </Grid>
                    <Stack direction={'row'} pb={5} justifyContent={'end'}>
                      <Stack justifyContent="center" spacing={2} sx={{ width: '400px', paddingRight: '200px' }}>
                        <Stack direction={'row'} spacing={1} sx={{ width: '100%' }} justifyContent={'space-between'}>
                          <Typography variant="body2" fontWeight={'bold'}>
                            Sub Total:
                          </Typography>
                          <Typography variant="body2">{invoice?.subTotal}</Typography>
                        </Stack>
                        <Stack direction={'row'} spacing={1} sx={{ width: '100%' }} justifyContent={'space-between'}>
                          <Typography variant="body2" fontWeight={'bold'}>
                            Discount:
                          </Typography>
                          <Typography variant="body2">{invoice?.discount}</Typography>
                        </Stack>
                        <Stack direction={'row'} spacing={1} sx={{ width: '100%' }} justifyContent={'space-between'}>
                          <Typography variant="body2" fontWeight={'bold'}>
                            {invoice?.tax?.name} ({invoice?.tax?.percentage}%):
                          </Typography>
                          <Typography variant="body2">{invoice?.tax?.amount} </Typography>
                        </Stack>
                        <Stack direction={'row'} spacing={1} sx={{ width: '100%' }} justifyContent={'space-between'}>
                          <Typography variant="body2" fontWeight={'bold'}>
                            Grand Total:
                          </Typography>
                          <Typography variant="body2">{invoice?.grandTotal}</Typography>
                        </Stack>
                        <Stack direction={'row'} spacing={1} sx={{ width: '100%' }} justifyContent={'space-between'}>
                          <Typography variant="body2" fontWeight={'bold'}>
                            Amount Paid:
                          </Typography>
                          <Typography variant="body2">{invoice?.paidAmount}</Typography>
                        </Stack>
                        <Stack direction={'row'} spacing={1} sx={{ width: '100%' }} justifyContent={'space-between'}>
                          <Typography variant="body2" fontWeight={'bold'}>
                            Balance Amount:
                          </Typography>
                          <Typography variant="body2">
                            {parseFloat(Number(invoice?.grandTotal) - Number(invoice?.paidAmount)).toFixed(2)}
                          </Typography>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Box>
                </AccordionDetails>
              </Accordion>
            ))}
          </Scrollbar>
        </Stack>
      </Box>
      <Card>
        <Box padding={'10px'}>
          <Typography gutterBottom variant="h5" mb={1}>
            Payment Details
          </Typography>
          <PaymentForm
            invoices={markToPay}
            bill={bill}
            totalAmount={totalAmount}
            error={error}
            totalDiscount={totalDiscount}
          />
        </Box>
      </Card>
    </Stack>
  );
}

export default InvoiceTable;
