import {
  Box,
  CircularProgress,
  Container,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  tableCellClasses,
} from '@mui/material';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import ViewBoqBasicDetails from '../components/ViewBoqBasicDetails';
import NavigateBack from '../components/smallComponents/NavigateBack';

const StyledTableCell = styled(TableCell)(
  ({ theme, cellBorderLeft, cellBorderRight, fontWeight, bgcolor, textAlign }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: bgcolor || theme.palette.primary.main,
      color: theme.palette.text.primary,
      fontWeight: 'bold',
      textAlign: textAlign || 'left',
      border: '0.5px solid #d49292',
      borderLeft: cellBorderLeft || '0.5px solid #d49292',
      borderRight: cellBorderRight || '0.5px solid #d49292',
      fontSize: 14,
    },

    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      textAlign: textAlign || 'left',
      border: '0.5px solid #d49292',
      borderLeft: cellBorderLeft || '0.5px solid #d49292',
      borderRight: cellBorderRight || '0.5px solid #d49292',
      fontWeight: fontWeight || 'normal',
      backgroundColor: bgcolor || '#fff',
    },
  })
);

function ViewBoq() {
  const { id } = useParams();
  const [boq, setBoq] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [overHeadError, setOverHeadError] = useState(false);
  const formik = useFormik({
    initialValues: {},
    onSubmit: async () => {
      try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/quotation/boq`, values);
        console.log(res.data);
        toast.success(res.data.message);
        getBoq();
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  const setValues = (data) => {
    formik.setFieldValue('id', data._id);
    formik.setFieldValue('items', data.items);
  };

  const getBoq = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/quotation/boq`, { params: { id } });
      console.log(res.data);
      setBoq(res.data.data);
      setValues(res.data.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getBoq();
  }, []);

  return (
    <Page title="View BOQ">
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Container maxWidth="xl">
          <Stack direction={'row'} alignItems="center" justifyContent="space-between" mt={2}>
            <Typography variant="h4">Bill of Quantities</Typography>
            <NavigateBack />
          </Stack>
          <ViewBoqBasicDetails data={boq} getBoq={getBoq} />
          <Box paddingBottom={10}>
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Scrollbar sx={{ marginTop: '5px' }}>
                  <TableContainer component={Paper} sx={{ padding: '3px', marginBottom: '20px' }}>
                    <Table
                      sx={{
                        '& .MuiTableCell-root': {
                          padding: '3px',
                        },
                        border: '3px solid #000',
                      }}
                    >
                      <TableHead>
                        <TableRow>
                          <StyledTableCell textAlign="center" bgcolor="#fff" colSpan={11}>
                            <Typography variant="h5"> Bill of Quantities</Typography>
                          </StyledTableCell>
                          <StyledTableCell textAlign="center" bgcolor="#fff">
                            MAC/TNDR/RFQ
                          </StyledTableCell>
                        </TableRow>
                        <TableRow>
                          <StyledTableCell bgcolor="#fff">Client</StyledTableCell>
                          <StyledTableCell colSpan={5} bgcolor="#fff">
                            {boq?.customerId?.fullname}
                          </StyledTableCell>
                          <StyledTableCell rowSpan={2} colSpan={2} bgcolor="#fff">
                            {''}
                          </StyledTableCell>
                          <StyledTableCell
                            rowSpan={4}
                            bgcolor="#a1a1e3"
                            sx={{ writingMode: 'vertical-lr', transform: 'rotate(180deg)' }}
                          >
                            OVERHEAD
                          </StyledTableCell>
                          <StyledTableCell rowSpan={4} colSpan={2} textAlign="center" bgcolor="#fff">
                            ACTUALS
                          </StyledTableCell>
                          <StyledTableCell rowSpan={3} textAlign="center" bgcolor="#fff">
                            REF
                          </StyledTableCell>
                        </TableRow>
                        <TableRow>
                          <StyledTableCell rowSpan={2} bgcolor="#fff">
                            Project
                          </StyledTableCell>
                          <StyledTableCell rowSpan={2} colSpan={5} bgcolor="#fff">
                            {boq?.projectName}
                          </StyledTableCell>
                        </TableRow>
                        <TableRow>
                          <StyledTableCell colSpan={2} rowSpan={2} bgcolor="#fff" sx={{ maxWidth: '100px' }}>
                            Adjustments to make rate in rounded figures
                          </StyledTableCell>
                        </TableRow>
                        <TableRow>
                          <StyledTableCell bgcolor="#fff">Location</StyledTableCell>
                          <StyledTableCell colSpan={5} bgcolor="#fff">
                            {boq?.location}
                          </StyledTableCell>
                          <StyledTableCell textAlign="center" bgcolor="#fff">
                            MAC/TNDR/RFQ/23-
                          </StyledTableCell>
                        </TableRow>

                        <TableRow>
                          <StyledTableCell textAlign="center">SL NO.</StyledTableCell>
                          <StyledTableCell textAlign="center" sx={{ minWidth: '350px' }}>
                            DESCRIPTION
                          </StyledTableCell>
                          <StyledTableCell textAlign="center">UNIT</StyledTableCell>
                          <StyledTableCell textAlign="center">QTY</StyledTableCell>
                          <StyledTableCell textAlign="center">RATE</StyledTableCell>
                          <StyledTableCell textAlign="center">AMT [AED]</StyledTableCell>
                          <StyledTableCell textAlign="center">RATE</StyledTableCell>
                          <StyledTableCell textAlign="center">AMT [AED]</StyledTableCell>
                          <StyledTableCell textAlign="center">%</StyledTableCell>
                          <StyledTableCell textAlign="center">RATE</StyledTableCell>
                          <StyledTableCell textAlign="center">AMT [AED]</StyledTableCell>
                          <StyledTableCell textAlign="center">REMARKS</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {values?.items?.map((item, index) => (
                          <TableRow key={index}>
                            <StyledTableCell>{index + 1}</StyledTableCell>
                            <StyledTableCell>{item.name}</StyledTableCell>
                            <StyledTableCell>{item.unit}</StyledTableCell>
                            <StyledTableCell>{item.quantity}</StyledTableCell>
                            <StyledTableCell>{item.unitPriceAfterOverhead}</StyledTableCell>
                            <StyledTableCell>{item.totalPriceAfterOverhead}</StyledTableCell>
                            <StyledTableCell>{item.roundedUnitPriceAfterOverHead}</StyledTableCell>
                            <StyledTableCell>{item.roundedTotalPriceAfterOverhead}</StyledTableCell>
                            <StyledTableCell
                              contentEditable
                              suppressContentEditableWarning
                              onBlur={(e) => {
                                if (e.target.innerText >= 100) {
                                  toast.error('Overhead percentage cannot be greater than 100');
                                  setOverHeadError(true);
                                  return;
                                }
                                setOverHeadError(false);
                                const items = [...values.items];
                                items[index].overheadPercentage = Number(e.target.innerText);
                                items[index].unitPriceAfterOverhead = parseFloat(
                                  Number(items[index].unitPrice) *
                                    (100 / (100 - Number(items[index].overheadPercentage)))
                                ).toFixed(2);
                                items[index].totalPriceAfterOverhead = parseFloat(
                                  Number(items[index].unitPriceAfterOverhead) * Number(items[index].quantity)
                                ).toFixed(2);
                                items[index].roundedUnitPriceAfterOverHead = Math.ceil(
                                  Number(items[index].unitPriceAfterOverhead)
                                );
                                items[index].roundedTotalPriceAfterOverhead = parseFloat(
                                  Number(items[index].roundedUnitPriceAfterOverHead) * Number(items[index].quantity)
                                ).toFixed(2);
                                formik.setFieldValue('items', items);
                              }}
                            >
                              {item.overheadPercentage}
                            </StyledTableCell>
                            <StyledTableCell>{item.unitPrice}</StyledTableCell>
                            <StyledTableCell>{item.totalPrice}</StyledTableCell>
                            <StyledTableCell>{item.remarks}</StyledTableCell>
                          </TableRow>
                        ))}
                        <TableRow>
                          <StyledTableCell>{''}</StyledTableCell>
                          <StyledTableCell fontWeight="bold">TOTAL</StyledTableCell>
                          <StyledTableCell>{''}</StyledTableCell>
                          <StyledTableCell>{''}</StyledTableCell>
                          <StyledTableCell>{''}</StyledTableCell>
                          <StyledTableCell fontWeight="bold">
                            {parseFloat(
                              values?.items?.reduce((a, b) => a + Number(b.totalPriceAfterOverhead), 0)
                            ).toFixed(2)}
                          </StyledTableCell>
                          <StyledTableCell>{''}</StyledTableCell>
                          <StyledTableCell fontWeight="bold">
                            {parseFloat(
                              values?.items?.reduce((a, b) => a + Number(b.roundedTotalPriceAfterOverhead), 0)
                            ).toFixed(2)}
                          </StyledTableCell>
                          <StyledTableCell>{''}</StyledTableCell>
                          <StyledTableCell>{''}</StyledTableCell>
                          <StyledTableCell fontWeight="bold">
                            {parseFloat(values?.items?.reduce((a, b) => a + Number(b.totalPrice), 0)).toFixed(2)}
                          </StyledTableCell>
                        </TableRow>
                        <TableRow>
                          <StyledTableCell>{''}</StyledTableCell>
                          <StyledTableCell fontWeight="bold">TOTAL(VAT 5%)</StyledTableCell>
                          <StyledTableCell>{''}</StyledTableCell>
                          <StyledTableCell>{''}</StyledTableCell>
                          <StyledTableCell>{''}</StyledTableCell>
                          <StyledTableCell fontWeight="bold">
                            {parseFloat(
                              values?.items?.reduce((a, b) => a + Number(b.totalPriceAfterOverhead), 0) * (1 + 0.05)
                            ).toFixed(2)}
                          </StyledTableCell>
                          <StyledTableCell>{''}</StyledTableCell>
                          <StyledTableCell fontWeight="bold">
                            {parseFloat(
                              values?.items?.reduce((a, b) => a + Number(b.roundedTotalPriceAfterOverhead), 0) *
                                (1 + 0.05)
                            ).toFixed(2)}
                          </StyledTableCell>
                          <StyledTableCell>{''}</StyledTableCell>
                          <StyledTableCell>{''}</StyledTableCell>
                          <StyledTableCell fontWeight="bold">
                            {parseFloat(
                              values?.items?.reduce((a, b) => a + Number(b.totalPrice), 0) * (1 + 0.05)
                            ).toFixed(2)}
                          </StyledTableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Scrollbar>
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  spacing={1}
                  sx={{ padding: '10px' }}
                >
                  <LoadingButton type="submit" variant="contained" loading={isSubmitting} disabled={overHeadError}>
                    Submit
                  </LoadingButton>
                </Stack>
              </Form>
            </FormikProvider>
          </Box>
        </Container>
      )}
    </Page>
  );
}

export default ViewBoq;
