import moment from 'moment';
import { Box, Button, Card, Container, Stack, Typography } from '@mui/material';
import axios from 'axios';
import { Attachment } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Page from '../components/Page';
import NavigateBack from '../components/smallComponents/NavigateBack';

const ViewPurchaseUpload = () => {
  const { id } = useParams();
  const [purchase, setPurchase] = useState({});

  const getPurchase = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/purchaseUpload`, { params: { _id: id } });
      console.log(res.data.data, 'purchase Upload');
      setPurchase(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const downloadDoc = async (e, name) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/purchaseUpload/download`, {
        params: {
          file: name,
        },
      });
      window.open(res.data.data.url);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPurchase();
  }, []);

  return (
    <Page title="View Purchase">
      <Container maxWidth="xl">
        <Stack spacing={2} mt={1}>
          <Stack direction={'row'} alignItems="center" justifyContent="space-between">
            <Typography variant="h4">Purchase Details</Typography>
            <NavigateBack />
          </Stack>
          <Card>
            <Box sx={{ padding: '10px' }}>
              <Stack spacing={1} sx={{ width: '100%' }} paddingX={2}>
                <Stack direction={'row'} alignItems="center" spacing={1}>
                  <Typography>Project:</Typography>
                  <Typography variant="h6">{purchase?.projectId?.name}</Typography>
                </Stack>
                <Stack direction={'row'} alignItems="center" spacing={1}>
                  <Typography>Title:</Typography>
                  <Typography variant="h6">{purchase?.title}</Typography>
                </Stack>
                <Stack direction={'row'} alignItems="center" spacing={1}>
                  <Typography>Purchase Date:</Typography>
                  <Typography variant="h6">
                    {moment(purchase?.purchaseDate ? purchase.purchaseDate : purchase?.createdAt).format('DD-MM-YYYY')}
                  </Typography>
                </Stack>
                <Stack direction={'row'} alignItems="center" spacing={1}>
                  <Typography>Uploaded By:</Typography>
                  <Typography variant="h6">{purchase?.memberId?.fullname}</Typography>
                </Stack>
                <Stack direction={'row'} alignItems="center" spacing={1}>
                  <Typography>Remarks:</Typography>
                  <Typography variant="h6">{purchase?.remarks}</Typography>
                </Stack>
              </Stack>
              <Box mt={2} paddingX={2}>
                <Stack direction={'row'} alignItems="center">
                  <Attachment />
                  <Typography variant="h6" fontWeight={'bold'}>
                    Files
                  </Typography>
                </Stack>
                <Stack direction={'row'} spacing={1} sx={{ flexWrap: 'wrap' }}>
                  {purchase?.files?.map((file, index) => (
                    <Button
                      key={index}
                      variant="contained"
                      color="primary"
                      onClick={(e) => downloadDoc(e, file.fileUrl)}
                    >
                      {`${file?.originalName?.slice(0, 10)}...${file?.originalName?.slice(-5)}`}
                    </Button>
                  ))}
                </Stack>
              </Box>
            </Box>
          </Card>
        </Stack>
      </Container>
    </Page>
  );
};

export default ViewPurchaseUpload;
