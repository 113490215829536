import { Box, Card, CircularProgress, Container, Grid, Stack, Typography } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Iconify from '../components/Iconify';
import InvoiceTableSalary from '../components/InvoiceTableSalary';

function DetailItem({ icon, title, data, background, sx, onClick }) {
  return (
    <Stack
      alignItems="center"
      spacing={1}
      direction="row"
      sx={{ height: '100%', background: '#f0f0f0', minHeight: '65px', borderRadius: '5px', ...sx }}
      onClick={onClick}
    >
      <Stack
        direction="row"
        alignItems="center"
        sx={{ height: '100%', borderRadius: '5px 0px 0px 5px' }}
        background={background}
        padding={1}
      >
        <Iconify icon={icon} style={{ height: '25px', width: '25px' }} />
      </Stack>

      <Stack direction="column" sx={{ padding: 1 }}>
        <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>{title}</Typography>
        <Typography style={{ wordBreak: 'break-all' }}>{data}</Typography>
      </Stack>
    </Stack>
  );
}

function ViewPendingSalaryPayment() {
  const { employeeId } = useParams();
  const location = useLocation();
  const invoice = location.state && location.state.invoice;
  const [pendingSalary, setPendingSalary] = useState({});
  const [isLoading, setIsLoading] = useState(true);
       
  const getPendingSalary = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/payment/salary/pending`, {
        params: { employeeId, _id: invoice ? invoice._id : '' },
      });
      console.log(res.data.data, 'pending salary');
      if (!res.data.isError) {
        setPendingSalary(res.data.data);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPendingSalary();
  }, []);

  return (
    <Container maxWidth="xl">
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItem: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Stack spacing={2}>
          <h2> Pending Salary</h2>
          <Card>
            <Grid container spacing={2} padding={2}>
              <Grid item xs={12} md={6} lg={4}>
                <DetailItem icon="mdi:account" title="Customer Name" data={pendingSalary.fullname} />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <DetailItem icon="mdi:email" title="Email" data={pendingSalary.email} />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <DetailItem icon="mdi:phone" title="Phone" data={pendingSalary.phone} />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <DetailItem icon="mdi:currency-inr" title="Grand Total" data={pendingSalary.grandTotal} />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <DetailItem icon="mdi:currency-inr" title="Paid Amount" data={pendingSalary.paidAmount} />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <DetailItem icon="mdi:currency-inr" title="Balance Amount" data={pendingSalary.balanceAmount} />
              </Grid>
            </Grid>
          </Card>
          <InvoiceTableSalary salary={pendingSalary} />
        </Stack>
      )}
    </Container>
  );
}

export default ViewPendingSalaryPayment;
