import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import axios from 'axios';
import moment from 'moment';
// material
import { styled } from '@mui/material/styles';
import {
  Card,
  Table,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  CircularProgress,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  FormControl,
  Select,
  Icon,
  Container,
  Typography,
  MenuItem,
  TableContainer,
  TablePagination,
  TextField,
  Grid,
  IconButton,
  Toolbar,
  OutlinedInput,
  InputAdornment,
  useMediaQuery,
  FormControlLabel,
  Checkbox,
  Chip,
  Avatar,
} from '@mui/material';
import { Box } from '@mui/system';
import { ContentCopy, CopyAll } from '@mui/icons-material';
import CopyToClipboard from 'react-copy-to-clipboard';
import { LoadingButton } from '@mui/lab';
import { useFormik, Form, FormikProvider } from 'formik';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import AddIcon from '@mui/icons-material/Add';
import ModeEditSharpIcon from '@mui/icons-material/ModeEditSharp';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Filter from '../components/filters/Vendor';

// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import Popup from '../components/popups/VendorStatusChange';
import NavigateBack from '../components/smallComponents/NavigateBack';

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

/// custom tablecell with custom padding
const TableCellCustom = styled(TableCell)(({ theme }) => ({
  padding: '14px 8px',
}));

export default function Vendors() {
  const matches = useMediaQuery('(max-width:600px)');
  const [vendorData, setVendorData] = useState();
  const [name, setName] = useState('');
  const [pages, setPages] = useState(0);
  const [vendorCount, setVendorCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoading1, setIsLoading1] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState();
  const [selectedIndex1, setSelectedIndex1] = useState();
  const [size, setSize] = useState(10);
  const [options, setOptions] = useState({ size, page: 0 });
  const [open, setOpen] = useState(false);
  const [input, setInput] = useState(1);
  const [role, setRole] = useState([]);

  const vendorSchema = Yup.object().shape({
    companyName: Yup.string().required('Company Name is required'),
  });

  const formik = useFormik({
    initialValues: {
      companyName: '',
      contactPerson: '',
      email: '',
      phone1: [],
      phone2: [],
      companyAddress: '',
      isBlocked: false,
      notes: '',
    },
    validationSchema: vendorSchema,
    onSubmit: async () => {
      try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/vendor`, values, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        if (!res.data.isError) {
          closeAddVendorModal(res.data.message);
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
        getVendors(options);
        resetForm();
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  const handleStatusChange = (event, item, index) => {
    console.log('item', item);
    if (`${item.isBlocked}` === event.target.value) {
      return;
    }
    console.log(index);
    setSelectedIndex(index);
  };
  const handleStatusChange1 = (event, item, index) => {
    console.log('item', item);
    if (`${item.isPopular}` === event.target.value) {
      return;
    }
    console.log(index);
    setSelectedIndex1(index);
  };

  const handleClose = (refresh = false, message = '') => {
    setSelectedIndex();
    setSelectedIndex1();
    if (refresh) {
      getVendors(options);
    }
    if (message) {
      toast(message);
    }
  };
  const openAddVendorModal = () => {
    setOpen(true);
  };

  const openExcelUploadModal = () => {
    // setOpen1(true);
  };
  const closeAddVendorModal = () => {
    setOpen(false);
    formik.resetForm();
    setRole([]);
  };

  const setChipIcon = (role) => {
    switch (role) {
      case 'TELE_CALLER':
        return 'mdi:phone-outline';
      case 'LEAD_MANAGER':
        return 'mdi:account-tie';
      default:
        return 'mdi:account';
    }
  };

  const setChipColor = (role) => {
    switch (role) {
      case 'TELE_CALLER':
        return 'bluee';
      case 'LEAD_MANAGER':
        return 'greenish';
      default:
        return 'purplish';
    }
  };

  const handlePageChange = (event, newPage) => {
    const temp = { ...options, page: newPage };
    setPages(newPage);
    setOptions(temp);
    setIsLoading(true);
    getVendors(temp);
  };

  const getVendors = (options) => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/vendor/all`, {
        params: options,
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setIsLoading(false);
          setVendorCount(res.data.data.maxRecords);
          setVendorData(res.data.data.records);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const getVendorsWithoutLoading = (options) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/vendor/all`, {
        params: options,
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setVendorCount(res.data.data.maxRecords);
          setVendorData(res.data.data.records);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const goToPage = () => {
    console.log('hi');
    if (input > Math.ceil(vendorCount / size)) {
      return;
    }
    setInput(input > 0 ? input : 1);
    setPages(input - 1 >= 0 ? input - 1 : 0);
    const temp = { ...options, page: input - 1 };
    setOptions(temp);
    getVendors(temp);
  };

  const handleChangeCheckbox = (event) => {
    if (event.target.checked) {
      setRole([event.target.value]);
    } else {
      setRole([]);
    }
  };

  const onSearch = (e) => {
    e.preventDefault();
    setName(e.target.value);
    const temp = { ...options, page: 0, companyName: e.target.value };
    setOptions(temp);
    setPages(0);
    getVendorsWithoutLoading(temp);
  };

  const applyFilters = (filter) => {
    console.log('filter', filter);
    const temp = { page: 0, size };
    if (filter._id.length) {
      temp._id = filter._id;
    }
    if (filter.isBlocked.length) {
      temp.isBlocked = filter.isBlocked.trim();
    }
    if (filter.contactPerson) {
      temp.contactPerson = filter.contactPerson;
    }
    if (filter.companyName) {
      temp.companyName = filter.companyName;
    }
    if (filter.email) {
      temp.email = filter.email;
    }
    if (filter.phone1) {
      temp.phone1 = filter.phone1;
    }
    if (filter.phone2) {
      temp.phone2 = filter.phone2;
    }
    if (filter.companyAddress) {
      temp.companyAddress = filter.companyAddress;
    }

    setOptions(temp);
    setPages(0);
    getVendors(temp);
  };
  useEffect(() => {
    getVendors(options);
  }, []);

  useEffect(() => {
    const temp = { ...options, size, page: 0 };
    setPages(0);
    setOptions(temp);
    getVendors(temp);
  }, [size]);

  useEffect(() => {
    formik.setFieldValue('role', role);
  }, [role]);

  return (
    <>
      <Page title="Vendors">
        {isLoading ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '50vh',
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            {/* Add vendor Dialog */}
            <Dialog open={open} onClose={closeAddVendorModal} fullScreen={matches}>
              <DialogTitle>Add Vendor</DialogTitle>
              <Box paddingBottom={'10px'} width={{ xs: '100%', sm: '600px' }}>
                <DialogContent paddingTop="20px">
                  <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            label="Company Name"
                            {...getFieldProps('companyName')}
                            error={Boolean(touched.companyName && errors.companyName)}
                            helperText={touched.companyName && errors.companyName}
                          />
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <TextField fullWidth label="Contact Person" {...getFieldProps('contactPerson')} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField fullWidth label="Email" {...getFieldProps('email')} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField fullWidth label="Phone 1" {...getFieldProps('phone1')} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField fullWidth label="Phone 2" {...getFieldProps('phone2')} />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField fullWidth multiline label="Company Address" {...getFieldProps('companyAddress')} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField fullWidth select label="Status" {...getFieldProps('isBlocked')}>
                            <MenuItem value={'true'}>Blocked</MenuItem>
                            <MenuItem value={'false'}>Active</MenuItem>
                          </TextField>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField fullWidth multiline label="Notes" {...getFieldProps('notes')} />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            type="file"
                            fullWidth
                            label="Profile Pic"
                            onChange={(e) => {
                              formik.setFieldValue('image', e.target.files[0]);
                            }}
                          />
                        </Grid>
                      </Grid>

                      <Stack direction={'row'} justifyContent="flex-end" paddingTop="20px">
                        <Button onClick={closeAddVendorModal}>Cancel</Button>
                        <LoadingButton color="primaryDark" type="submit" loading={isSubmitting}>
                          Add Vendor
                        </LoadingButton>
                      </Stack>
                    </Form>
                  </FormikProvider>
                </DialogContent>
              </Box>
            </Dialog>
            {/* Add vendor dialog end */}
            <Container maxWidth="xl">
              <Stack direction="row" alignItems="end" justifyContent="space-between">
                <Typography variant="h4" gutterBottom>
                  Vendors
                </Typography>
                <NavigateBack />
              </Stack>
              <Card>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Stack direction="row" spacing={2} padding={2}>
                    <SearchStyle
                      value={name}
                      onChange={onSearch}
                      placeholder="Search vendor..."
                      startAdornment={
                        <InputAdornment position="start">
                          <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                        </InputAdornment>
                      }
                    />
                  </Stack>
                  <Stack padding={1} direction="row" justifyContent="flex-end" spacing={2} alignItems="center">
                    <Button startIcon={<AddIcon />} variant="contained" onClick={openAddVendorModal}>
                      Add Vendor
                    </Button>
                    <Filter applyFilters={applyFilters} />
                  </Stack>
                </Stack>

                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCellCustom>Sl No</TableCellCustom>
                          <TableCellCustom>Profile Pic</TableCellCustom>
                          <TableCellCustom>Company Name</TableCellCustom>
                          <TableCellCustom>Contact Person</TableCellCustom>
                          <TableCellCustom>Email</TableCellCustom>
                          <TableCellCustom>Phone </TableCellCustom>
                          <TableCellCustom>Status</TableCellCustom>
                          <TableCellCustom>Action</TableCellCustom>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {vendorData?.map((item, id) => (
                          <TableRow key={id} sx={{ '&:hover': { backgroundColor: '#f5f5f5' } }}>
                            <TableCellCustom>{pages * size + (id + 1)}</TableCellCustom>
                            <TableCellCustom>
                              <Avatar
                                src={
                                  (item?.profilePic && process.env.REACT_APP_API_URL + item.profilePic) ||
                                  'https://www.w3schools.com/howto/img_avatar.png'
                                }
                                sx={{ width: 40, height: 40 }}
                              />
                            </TableCellCustom>
                            <TableCellCustom>{item?.companyName}</TableCellCustom>
                            <TableCellCustom>{item.contactPerson}</TableCellCustom>
                            <TableCellCustom>{item.email}</TableCellCustom>

                            <TableCellCustom sx={{ minWidth: '110px', maxWidth: '120px' }}>
                              <Stack>
                                <Typography variant="p">{item.phone1}</Typography>
                                <Typography variant="p">{item.phone2}</Typography>
                              </Stack>
                            </TableCellCustom>
                            <TableCellCustom>
                              <FormControl sx={{ minWidth: 100 }}>
                                <Select
                                  size="small"
                                  value={item.isBlocked}
                                  onChange={(e) => handleStatusChange(e, item, id)}
                                >
                                  <MenuItem value={'false'}>🟢 Active</MenuItem>
                                  <MenuItem value={'true'}>🔴 Blocked</MenuItem>
                                </Select>
                                {selectedIndex === id && <Popup item={item} handleClose={handleClose} />}
                              </FormControl>
                            </TableCellCustom>
                            <TableCellCustom>
                              <Box>
                                <Link to={`/dashboard/general/vendors/view-vendor/${item._id}`}>
                                  <Icon sx={{ color: 'gray' }}>
                                    <RemoveRedEyeIcon />
                                  </Icon>
                                </Link>
                              </Box>
                            </TableCellCustom>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Scrollbar>
                <TablePagination
                  rowsPerPageOptions={[10, 20, 30]}
                  onRowsPerPageChange={(e) => {
                    setSize(e.target.value);
                  }}
                  component={'div'}
                  count={vendorCount}
                  showFirstButton
                  showLastButton
                  onPageChange={handlePageChange}
                  rowsPerPage={size}
                  page={pages}
                />
              </Card>
            </Container>
          </>
        )}
      </Page>
    </>
  );
}
