import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import axios from 'axios';
import Moment from 'moment';
// material
import {
  Card,
  Table,
  Stack,
  CircularProgress,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Container,
  TableContainer,
  Typography,
  Box,
} from '@mui/material';
// import Filter from './filters/BalanceSheet';

// components
import Scrollbar from './Scrollbar';

const CustomTableCell = ({ children }) => <TableCell sx={{ height: '60px', paddingY: 0 }}>{children}</TableCell>;

export default function BalanceSheet() {
  const navigate = useNavigate();

  const [transactionData, setTransactionData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [options, setOptions] = useState({});

  const getBalanceSheet = (options) => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/ledger/trading-profit-loss`, {
        params: options,
      })
      .then((res) => {
        console.log('Payments', res);
        if (res.data.isError === false) {
          setIsLoading(false);
          setTransactionData(res.data.data);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const applyFilters = (filter) => {
    console.log('filter', filter);
    const temp = {};

    if (filter.transactionType) {
      temp.transactionType = filter.transactionType.trim();
    }
    if (filter.ledgerId) {
      temp.ledgerId = filter.ledgerId;
    }

    if (filter.paymentStartDate) {
      temp.paymentStartDate = filter.paymentStartDate;
    }
    if (filter.paymentEndDate) {
      temp.paymentEndDate = filter.paymentEndDate;
    }
    if (filter.txnStartDate) {
      temp.txnStartDate = filter.txnStartDate;
    }
    if (filter.txnEndDate) {
      temp.txnEndDate = filter.txnEndDate;
    }
    if (filter.paymentMode) {
      temp.paymentMode = filter.paymentMode;
    }

    setOptions(temp);
    getBalanceSheet(temp);
  };

  useEffect(() => {
    getBalanceSheet(options);
  }, []);

  const formatNumber = (num) => parseFloat(num).toFixed(2);

  return (
    <>
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <Container maxWidth="xl">
            <Card>
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Stack padding={1} direction="row" justifyContent="flex-end" spacing={2} alignItems="center">
                  {/* <Filter applyFilters={applyFilters} /> */}
                </Stack>
              </Stack>

              <Stack direction="row" justifyContent="space-between">
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <CustomTableCell>LIABILITIES</CustomTableCell>
                        <CustomTableCell>AMOUNT</CustomTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {transactionData.records
                        .filter((item) => item.accountCategory === 'Capital')
                        .map((item1, key) => (
                          <>
                            <TableRow>
                              <CustomTableCell>
                                <Typography sx={{ fontWeight: 'bold' }}>{item1.accountType}</Typography>
                              </CustomTableCell>
                              <CustomTableCell>{''}</CustomTableCell>
                            </TableRow>
                            {item1.docs.map((doc, key) => (
                              <React.Fragment key={key}>
                                <TableRow>
                                  <CustomTableCell>{doc.name}</CustomTableCell>
                                  <CustomTableCell>₹{formatNumber(doc.balance)}</CustomTableCell>
                                </TableRow>
                              </React.Fragment>
                            ))}
                          </>
                        ))}
                      <TableRow>
                        <CustomTableCell>
                          <Typography sx={{ fontWeight: 'bold' }}>Add Net Profit</Typography>
                        </CustomTableCell>
                        <CustomTableCell>
                          <Typography sx={{ fontWeight: 'bold' }}>
                            {transactionData.isNetProfit
                              ? Number(transactionData?.netProfit).toFixed(2)
                              : `-${Number(transactionData.netLoss).toFixed(2)}`}
                          </Typography>
                        </CustomTableCell>
                      </TableRow>
                      {transactionData.records
                        .filter((item) => item.accountCategory === 'Liability')
                        .map((item1, key) => (
                          <>
                            <TableRow>
                              <CustomTableCell>
                                <Typography sx={{ fontWeight: 'bold' }}>{item1.accountType}</Typography>
                              </CustomTableCell>
                              <CustomTableCell>{''}</CustomTableCell>
                            </TableRow>
                            {item1.docs.map((doc, key) => (
                              <React.Fragment key={key}>
                                <TableRow>
                                  <CustomTableCell>{doc.name}</CustomTableCell>
                                  <CustomTableCell>₹{formatNumber(doc.balance)}</CustomTableCell>
                                </TableRow>
                              </React.Fragment>
                            ))}
                          </>
                        ))}
                      <TableRow>
                        <CustomTableCell>{''} </CustomTableCell>
                        <CustomTableCell>
                          <Box border={1} borderColor={'#000'} textAlign={'center'}>
                            ₹{transactionData.liabilities}
                          </Box>
                        </CustomTableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <CustomTableCell>ASSET</CustomTableCell>
                        <CustomTableCell>AMOUNT</CustomTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {transactionData.records
                        .filter((item) => item.accountCategory === 'Assets')
                        .map((item1, key) => (
                          <>
                            <TableRow>
                              <CustomTableCell>
                                <Typography sx={{ fontWeight: 'bold' }}>{item1.accountType}</Typography>
                              </CustomTableCell>
                              <CustomTableCell>{''}</CustomTableCell>
                            </TableRow>
                            {item1.docs.map((doc, key) => (
                              <React.Fragment key={key}>
                                <TableRow>
                                  <CustomTableCell>{doc.name}</CustomTableCell>
                                  <CustomTableCell>₹{doc.balance}</CustomTableCell>
                                </TableRow>
                              </React.Fragment>
                            ))}
                          </>
                        ))}
                      <TableRow>
                        <CustomTableCell>{''} </CustomTableCell>
                        <CustomTableCell>
                          <Box border={1} borderColor={'#000'} textAlign={'center'}>
                            ₹{formatNumber(transactionData.asset)}
                          </Box>
                        </CustomTableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Stack>
            </Card>
          </Container>
        </>
      )}
    </>
  );
}
