import { useState } from 'react';
// material
import { Container, Typography, Card, Tab, Box, Stack } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Bills from './accountsTables/Bills';
import Purchases from './accountsTables/Purchases';

// components

export default function ProjectsAccountTab({ project }) {
  const [value, setValue] = useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange}>
            <Tab label="Bill" value="1" />
            <Tab label="Purchase" value="2" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <Bills project={project} />
        </TabPanel>
        <TabPanel value="2">
          <Purchases project={project} />
        </TabPanel>
      </TabContext>
    </Box>
  );
}
