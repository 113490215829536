import React from 'react';
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Icon,
  Link,
  Stack,
  Card,
  Box,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Edit, RemoveRedEye } from '@mui/icons-material';
import moment from 'moment';
import Scrollbar from './Scrollbar';

function TransactionsTable({ transactions }) {
  const navigate = useNavigate();
  return (
    <Card>
      <Box sx={{ padding: '10px' }}>
        <h3>Transactions</h3>
        <Scrollbar>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Sl No</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Transaction ID</TableCell>
                  <TableCell>Ledger</TableCell>
                  <TableCell>Transaction Type</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transactions?.map((item, id) => (
                  <TableRow key={id} sx={{ '&:hover': { backgroundColor: '#f5f5f5' } }}>
                    <TableCell>{id + 1}</TableCell>
                    <TableCell>{moment(item?.createdAt).format('DD-MM-YYYY')}</TableCell>
                    <TableCell>{item?.txnId}</TableCell>
                    <TableCell>{item?.ledgerId?.name}</TableCell>
                    <TableCell>{item?.transactionType}</TableCell>
                    <TableCell>₹{Number(item.amount).toFixed(2)}</TableCell>
                    <TableCell>
                      <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                        <Icon
                          sx={{ color: 'gray', cursor: 'pointer' }}
                          onClick={() => navigate(`/dashboard/accounts/payments/view-transaction/${item.txnId}`)}
                        >
                          <RemoveRedEye />
                        </Icon>
                      </Stack>
                    </TableCell>
                  </TableRow> 
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
      </Box>
    </Card>
  );
}

export default TransactionsTable;
