import { Avatar, Box, Card, Container, Grid, Stack, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Page from '../components/Page';
import Iconify from '../components/Iconify';
import VendorTables from '../components/VendorTables ';
import NavigateBack from '../components/smallComponents/NavigateBack';

function DetailItem({ icon, title, data, background, sx, onClick, profilePic }) {
  return (
    <Stack
      alignItems="center"
      spacing={1}
      direction="row"
      sx={{ height: '100%', background: '#f0f0f0', minHeight: '65px', borderRadius: '5px', ...sx }}
      onClick={onClick}
    >
      <Stack
        direction="row"
        alignItems="center"
        sx={{ height: '100%', borderRadius: '5px 0px 0px 5px' }}
        background={background}
        padding={profilePic ? 0 : 1}
      >
        {profilePic && <Avatar src={profilePic} alt={title} style={{ height: '100px', width: '100px' }} />}
        {!profilePic && <Iconify icon={icon} style={{ height: '25px', width: '25px' }} />}
      </Stack>

      <Stack direction="column" sx={{ padding: 1 }}>
        <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>{title}</Typography>
        <Typography style={{ wordBreak: 'break-all' }}>{data}</Typography>
      </Stack>
    </Stack>
  );
}

function ViewVendor() {
  const { id } = useParams();

  const [vendor, setVendor] = useState({});
  const [paymentData, setPaymentData] = useState({});

  const getVendor = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/vendor/one`, { params: { _id: id } });
      console.log(res);
      if (res.data.isError === false) {
        setVendor(res.data.data.vendor);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getPaymentData = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/payment/purchase/all`, {
        params: { vendorId: id },
      });
      setPaymentData(res.data.data.records);
      console.log('res', res);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getVendor();
    getPaymentData();
  }, [id]);

  return (
    <Page title="View Vendor">
      <Container maxWidth="xl">
        <Stack direction="row" alignItems="end" justifyContent="space-between">
          <Typography variant="h4" gutterBottom>
            Vendor
          </Typography>
          <NavigateBack />
        </Stack>
        <Stack direction={'column'} spacing={2}>
          <Card>
            <Box padding={'10px'}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={4}>
                  <DetailItem
                    title="Vendor ID"
                    data={vendor?._id}
                    icon={'mdi:account'}
                    profilePic={process.env.REACT_APP_API_URL + vendor.profilePic}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <DetailItem title="Company Name" data={vendor?.companyName} icon={'mdi:account'} />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <DetailItem title="Contact Person" data={vendor?.contactPerson} icon={'mdi:account'} />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <DetailItem title=" Email" data={vendor?.email} icon={'mdi:email'} />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <DetailItem title=" Phone" data={`${vendor?.phone1}, ${vendor?.phone2}`} icon={'mdi:phone'} />
                </Grid>
                <Grid item xs={12}>
                  <DetailItem title=" Address" data={vendor?.companyAddress} icon={'mdi:map-marker'} />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <DetailItem title="Grand Total" icon="mdi:currency-inr" data={paymentData?.grandTotal || 0} />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <DetailItem title="Paid Amount" icon="mdi:currency-inr" data={paymentData?.paidAmount || 0} />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <DetailItem title="Balance Amount" icon="mdi:currency-inr" data={paymentData?.balanceAmount || 0} />
                </Grid>
              </Grid>
            </Box>
          </Card>
          <VendorTables />
        </Stack>
      </Container>
    </Page>
  );
}

export default ViewVendor;
