import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  Container,
  FormControlLabel,
  Grid,
  MenuItem,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { ExpandMore } from '@mui/icons-material';

export default function ViewQuoteBasicDetails({ data, getQuote }) {
  console.log(data);
  const { id } = useParams();
  const navigate = useNavigate();
  const [isEditing, setIsEditing] = useState(false);

  const formik = useFormik({
    initialValues: {
      projectName: data.projectName,
      customer: data.customerId?._id,
      location: data.location,
      referenceNumber: data.referenceNumber,
      boqStatus: data.boqStatus,
      quotationId: data.quotationId,
      quotationStatus: data.quotationStatus,
      isFinalised: data.isFinalised,
      version: data.version,
      id,
    },
    onSubmit: async () => {
      try {
        const removeEmptyValues = (obj) => {
          Object.keys(obj).forEach((key) => (obj[key] === undefined || obj[key] === '') && delete obj[key]);
          return obj;
        };
        const newValues = removeEmptyValues(formik.values);
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/quotation/quote/details/`, newValues);
        toast(res.data.message);
        getQuote();
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  useEffect(() => {
    formik.setValues({
      projectName: data.projectName,
      customer: data.customerId?._id,
      location: data.location,
      referenceNumber: data.referenceNumber,
      boqStatus: data.boqStatus,
      quotationId: data.quotationId,
      quotationStatus: data.quotationStatus,
      isFinalised: data.isFinalised,
      version: data.version,
      id,
    });
  }, [data]);

  return (
    <Box my={2}>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography variant="h6" sx={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
            Project Name: <span style={{ fontWeight: 'normal' }}>{data.projectName}</span>
          </Typography>
        </AccordionSummary>{' '}
        <AccordionDetails>
          {isEditing ? (
            <>
              <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        type="text"
                        label="Project"
                        fullWidth
                        sx={{ width: '100%' }}
                        {...getFieldProps('projectName')}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        type="text"
                        label="Customer"
                        fullWidth
                        sx={{ width: '100%' }}
                        {...getFieldProps('customer')}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        type="text"
                        label="Location"
                        fullWidth
                        sx={{ width: '100%' }}
                        {...getFieldProps('location')}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        type="text"
                        label="QTO Approved By"
                        fullWidth
                        sx={{ width: '100%' }}
                        {...getFieldProps('boqApprovedBy')}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        type="text"
                        label="QTO Prepared By"
                        fullWidth
                        sx={{ width: '100%' }}
                        {...getFieldProps('boqPreparedBy')}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        type="text"
                        label="Reference Number"
                        fullWidth
                        sx={{ width: '100%' }}
                        {...getFieldProps('referenceNumber')}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        select
                        label="BOQ Status"
                        fullWidth
                        sx={{ width: '100%' }}
                        {...getFieldProps('boqStatus')}
                      >
                        <MenuItem value="NA">NA</MenuItem>
                        <MenuItem value="PENDING">Pending</MenuItem>
                        <MenuItem value="APPROVED">Approved</MenuItem>
                        <MenuItem value="REJECTED">Rejected</MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        type="text"
                        label="Quotation ID"
                        fullWidth
                        sx={{ width: '100%' }}
                        {...getFieldProps('quotationId')}
                      />
                    </Grid>
                    {/* <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        type="text"
                        label="Quotation Status"
                        fullWidth
                        sx={{ width: '100%' }}
                      
                      />
                    </Grid> */}
                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        type="number"
                        label="Version"
                        fullWidth
                        sx={{ width: '100%' }}
                        value={values.version}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={values.isFinalised}
                            onChange={(e) => {
                              formik.setFieldValue('isFinalised', e.target.checked);
                            }}
                          />
                        }
                        label="Finalised"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Stack direction="row" sx={{ width: '100%' }} justifyContent="flex-end" spacing={2}>
                        <Button variant="contained" onClick={() => setIsEditing(false)}>
                          Cancel
                        </Button>
                        <LoadingButton variant="contained" type="submit" loading={isSubmitting}>
                          Submit
                        </LoadingButton>
                      </Stack>
                    </Grid>
                  </Grid>
                </Form>
              </FormikProvider>
            </>
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={4}>
                <Typography variant="h6" sx={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                  Customer: <span style={{ fontWeight: 'normal' }}>{data.customerId?.fullname}</span>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Typography variant="h6" sx={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                  Location: <span style={{ fontWeight: 'normal' }}>{data.location}</span>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Typography variant="h6" sx={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                  Quotation ID: <span style={{ fontWeight: 'normal' }}>{data.quotationId}</span>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Typography variant="h6" sx={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                  QTO Status: <span style={{ fontWeight: 'normal' }}>{data.qtoStatus}</span>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Typography variant="h6" sx={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                  BOQ Status: <span style={{ fontWeight: 'normal' }}>{data.boqStatus}</span>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Typography variant="h6" sx={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                  Quotation Status: <span style={{ fontWeight: 'normal' }}>{data.quotationStatus}</span>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Typography variant="h6" sx={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                  Version: <span style={{ fontWeight: 'normal' }}>{data.version}</span>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Typography variant="h6" sx={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                  Reference Number: <span style={{ fontWeight: 'normal' }}>{data.referenceNumber}</span>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Typography variant="h6" sx={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                  Is Finalised: <span style={{ fontWeight: 'normal' }}>{data.isFinalised ? 'Yes' : 'No'}</span>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Stack direction="row" sx={{ width: '100%' }} justifyContent="flex-end">
                  <Button variant="contained" onClick={() => setIsEditing(true)}>
                    Edit{' '}
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          )}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
