import { useState } from 'react';
// material
import { Container, Typography, Card, Tab, Box, Stack } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import CustomerTables from './CustomerTables';
import CustomerQuotationTabs from './CustomerQuotationTabs';
import ProjectsTable from './ProjectsTable';

// components

export default function CustomerTabs({ customer }) {
  const [value, setValue] = useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Card>
      <Box sx={{ width: '100%', marginTop: '20px' }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange}>
              <Tab label="Accounts" value="1" />
              <Tab label="Projects" value="2" />
              <Tab label="Quotations" value="3" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <CustomerTables customer={customer} />
          </TabPanel>
          <TabPanel value="2">
            <ProjectsTable customer={customer} />
          </TabPanel>
          <TabPanel value="3">
            <CustomerQuotationTabs customer={customer} />
          </TabPanel>
        </TabContext>
      </Box>
    </Card>
  );
}
