import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import axios from 'axios';
import moment from 'moment';
// material
import { styled } from '@mui/material/styles';
import {
  Card,
  Table,
  Stack,
  Button,
  CircularProgress,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Icon,
  Container,
  TableContainer,
  TablePagination,
  OutlinedInput,
  InputAdornment,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { ContentCopy, CopyAll, Edit, Person } from '@mui/icons-material';
import CopyToClipboard from 'react-copy-to-clipboard';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import AddIcon from '@mui/icons-material/Add';
import ModeEditSharpIcon from '@mui/icons-material/ModeEditSharp';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Filter from './filters/Bill';

// components
import Scrollbar from './Scrollbar';
import Iconify from './Iconify';
// import Popup from './popups/BillStatusChange';

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

/// custom tablecell with custom padding
const TableCellCustom = styled(TableCell)(({ theme }) => ({
  padding: '14px 8px',
}));

function BillTable({ status, customerId }) {
  const navigate = useNavigate();
  const [billData, setBillData] = useState();
  const [name, setName] = useState('');
  const [pages, setPages] = useState(0);
  const [billCount, setBillCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [size, setSize] = useState(10);
  const [options, setOptions] = useState({ size, page: 0, isPaymentCompleted: status === 'completed' });

  const handlePageChange = (event, newPage) => {
    const temp = { ...options, page: newPage };
    setPages(newPage);
    setOptions(temp);
    setIsLoading(true);
    getBills(temp);
  };

  const getBills = (options) => {
    const temp = { ...options, customerId };
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/billing/all`, {
        params: temp,
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setIsLoading(false);
          setBillCount(res.data.data.maxRecords);
          setBillData(res.data.data.records);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const getBillsWithoutLoading = (options) => {
    const temp = { ...options, customerId };
    axios
      .get(`${process.env.REACT_APP_API_URL}/billing/all`, {
        params: temp,
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setBillCount(res.data.data.maxRecords);
          setBillData(res.data.data.records);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const onSearch = (e) => {
    e.preventDefault();
    setName(e.target.value);
    const temp = { ...options, page: 0, customerName: e.target.value };
    setOptions(temp);
    setPages(0);
    getBillsWithoutLoading(temp);
  };

  const applyFilters = (filter) => {
    console.log('filter', filter);
    setName('');
    const temp = { page: 0, size };

    if (filter._id) {
      temp._id = filter._id;
    }
    if (filter.isPaymentCompleted.length) {
      temp.isPaymentCompleted = filter.isPaymentCompleted.trim();
    }
    if (filter.invoiceNumber) {
      temp.invoiceNumber = filter.invoiceNumber;
    }
    if (filter.customerId) {
      temp.customerId = filter.customerId;
    }
    if (filter.startDate) {
      temp.startDate = filter.startDate;
    }
    if (filter.endDate) {
      temp.endDate = filter.endDate;
    }
    if (filter.txnId) {
      temp.txnId = filter.txnId;
    }
    if (filter.phone) {
      temp.phone = filter.phone;
    }
    if (filter.email) {
      temp.email = filter.email;
    }

    setOptions(temp);
    setPages(0);
    getBills(temp);
  };
  useEffect(() => {
    getBills(options);
  }, []);

  useEffect(() => {
    const temp = { ...options, size, page: 0 };
    setPages(0);
    setOptions(temp);
    getBills(temp);
  }, [size]);

  return (
    <>
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <Stack direction="row" justifyContent={customerId ? 'end' : 'space-between'} alignItems="center">
            {!customerId && (
              <Stack direction="row" spacing={2} padding={2}>
                <SearchStyle
                  value={name}
                  onChange={onSearch}
                  placeholder="Search Customer name..."
                  startAdornment={
                    <InputAdornment position="start">
                      <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                    </InputAdornment>
                  }
                />
              </Stack>
            )}
            <Stack padding={1} direction="row" justifyContent="flex-end" spacing={2} alignItems="center">
              <Button
                startIcon={<AddIcon />}
                variant="contained"
                onClick={() => navigate('/dashboard/accounts/bill/add-bill')}
              >
                Add Bill
              </Button>

              <Filter applyFilters={applyFilters} customerId={customerId} />
            </Stack>
          </Stack>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCellCustom>Sl No</TableCellCustom>
                    <TableCellCustom>Invoice Date</TableCellCustom>
                    <TableCellCustom>Invoice Number</TableCellCustom>
                    {!customerId && <TableCellCustom>Customer</TableCellCustom>}
                    <TableCellCustom>Contact</TableCellCustom>
                    <TableCellCustom>Grand Total(&#8377;)</TableCellCustom>
                    {status === 'pending' && <TableCellCustom>Paid(&#8377;) </TableCellCustom>}
                    <TableCellCustom>Discount(&#8377;) </TableCellCustom>
                    {status === 'pending' && <TableCellCustom>Pending (&#8377;) </TableCellCustom>}
                    <TableCellCustom>View</TableCellCustom>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {billData?.map((item, id) => (
                    <TableRow key={id} sx={{ '&:hover': { backgroundColor: '#f5f5f5' } }}>
                      <TableCellCustom>{pages * size + (id + 1)}</TableCellCustom>
                      <TableCellCustom>{moment(item?.invoiceDate).format('DD-MM-YYYY')}</TableCellCustom>
                      {!customerId && (
                        <TableCellCustom style={{ cursor: 'pointer' }}>
                          <Stack direction="column" spacing={0.5}>
                            <CopyToClipboard
                              text={item.invoiceNumber}
                              onCopy={() => toast.info('Invoice Number copied')}
                            >
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={0.5}
                                sx={{ cursor: 'pointer' }}
                                justifyContent="flex-start"
                              >
                                <ContentCopy color="primaryDark" />
                                <Typography variant="p">{item.invoiceNumber}</Typography>
                              </Stack>
                            </CopyToClipboard>
                          </Stack>
                        </TableCellCustom>
                      )}
                      <TableCellCustom>
                        <Stack justifyContent={'left'} spacing={0.5}>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={0.5}
                            justifyContent="flex-start"
                            sx={{ cursor: 'pointer' }}
                            onClick={() => navigate(`/dashboard/general/customers/view/${item.customerId}`)}
                          >
                            <Person />
                            <Typography variant="p">{item?.billTo}</Typography>
                          </Stack>
                          <CopyToClipboard text={item.customerId} onCopy={() => toast.info(' Customer ID copied')}>
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={0.5}
                              justifyContent="flex-start"
                              sx={{ cursor: 'pointer' }}
                            >
                              <ContentCopy color="primaryDark" />
                              <Typography variant="p">Copy ID</Typography>
                            </Stack>
                          </CopyToClipboard>
                        </Stack>
                      </TableCellCustom>
                      <TableCellCustom>
                        <Stack justifyContent={'center'}>
                          <Typography variant="p">{item?.billToEmail}</Typography>
                          <Typography variant="p">{item?.billToPhone}</Typography>
                        </Stack>
                      </TableCellCustom>

                      <TableCellCustom>&#8377;{item.grandTotal}</TableCellCustom>
                      {status === 'pending' && <TableCellCustom>&#8377;{item.paidAmount}</TableCellCustom>}
                      <TableCellCustom>&#8377;{item.discount}</TableCellCustom>
                      {status === 'pending' && <TableCellCustom>&#8377;{item.pendingAmount}</TableCellCustom>}
                      <TableCellCustom>
                        <Box>
                          <Link to={`/dashboard/accounts/bill/view-bill/${item.invoiceNumber}`}>
                            <Icon sx={{ color: 'gray' }}>
                              <RemoveRedEyeIcon />
                            </Icon>
                          </Link>
                        </Box>
                      </TableCellCustom>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[10, 20, 30]}
            onRowsPerPageChange={(e) => {
              setSize(e.target.value);
            }}
            component={'div'}
            count={billCount}
            showFirstButton
            showLastButton
            onPageChange={handlePageChange}
            rowsPerPage={size}
            page={pages}
          />
        </>
      )}
    </>
  );
}

export default BillTable;
