import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
import NotFound from './pages/Page404';
import Register from './pages/Register';
import DashboardApp from './pages/DashboardApp';
import Leads from './pages/Leads';
import Telecallers from './pages/TeleCallers';
import Admins from './pages/Admins';
import LeadManagers from './pages/LeadManagers';
import Settings from './pages/Settings';
import Employees from './pages/Employees';
import ViewEmployee from './pages/ViewEmployee';
import ViewAdmin from './pages/ViewAdmin';
import ViewLead from './pages/ViewLead';
import ViewProfile from './pages/ViewProfile';
import Customers from './pages/Customers';
import ViewCustomer from './pages/ViewCustomer';
import AccountsSettings from './pages/AccountsSettings';
import GeneralsSettings from './pages/GeneralSettings';
import Vendors from './pages/Vendors';
import Bills from './pages/Bills';
import Purchases from './pages/Purchases';
import Salary from './pages/Salary';
import PendingPayments from './pages/PendingPayments';
import Payments from './pages/Payments';
import AddBill from './pages/AddBill';
import AddPurchase from './pages/AddPurchase';
import ViewBill from './pages/ViewBill';
import ViewPurchase from './pages/ViewPurchase';
import ViewSalary from './pages/ViewSalary';
import ViewPendingBillPayment from './pages/ViewPendingBillPayment';
import ViewPendingPurchasePayment from './pages/ViewPendingPurchasePayment';
import ViewPendingSalaryPayment from './pages/ViewPendingSalaryPayment';
import ViewTransaction from './pages/ViewTransaction';
import Ledgers from './pages/Ledgers';
import ViewVendor from './pages/ViewVendor';
import Transactions from './pages/Transactions';
import AddTransaction from './pages/AddTransaction';
import Reports from './pages/Reports';
import Qto from './pages/Qto';
import Boq from './pages/Boq';
import ViewBoq from './pages/ViewBoq';
import ViewQuote from './pages/ViewQuote';
import Quote from './pages/Quote';
import ViewQto from './pages/ViewQto';
import Projects from './pages/Projects';
import ViewProject from './pages/ViewProject';
import ProjectPurchaseUpload from './pages/ProjectPurchaseUpload';
import ViewPurchaseUpload from './pages/ViewPurchaseUpload';

// ----------------------------------------------------------------------

export default function Router() {
  const token = localStorage.getItem('accessToken');
  return useRoutes([
    {
      path: '/dashboard',
      element: token ? <DashboardLayout /> : <Navigate to="/" />,
      children: [
        { path: 'app', element: <DashboardApp /> },
        { path: 'profile', element: <ViewProfile /> },
        { path: 'general/settings', element: <GeneralsSettings /> },
        { path: 'general/employees', element: <Employees /> },
        { path: 'general/employees/view/:id', element: <ViewEmployee /> },
        { path: 'general/customers', element: <Customers /> },
        { path: 'general/customers/view/:id', element: <ViewCustomer /> },
        { path: 'general/vendors', element: <Vendors /> },
        { path: 'general/vendors/view-vendor/:id', element: <ViewVendor /> },
        { path: 'general/admin', element: <Admins /> },
        { path: 'general/admin/view/:id', element: <ViewAdmin /> },
        { path: 'crm/lead', element: <Leads /> },
        { path: 'crm/lead/view/:id', element: <ViewLead /> },
        { path: 'crm/leadmanager', element: <LeadManagers /> },
        { path: 'crm/telecaller', element: <Telecallers /> },
        { path: 'crm/settings', element: <Settings /> },
        { path: 'accounts/settings', element: <AccountsSettings /> },
        { path: 'accounts/ledger', element: <Ledgers /> },
        { path: 'accounts/bill', element: <Bills /> },
        { path: 'accounts/bill/add-bill', element: <AddBill /> },
        { path: 'accounts/bill/view-bill/:id', element: <ViewBill /> },
        { path: 'accounts/purchase', element: <Purchases /> },
        { path: 'accounts/purchase/add-purchase', element: <AddPurchase /> },
        { path: 'accounts/purchase/view-purchase/:id', element: <ViewPurchase /> },
        { path: 'accounts/salary', element: <Salary /> },
        { path: 'accounts/salary/view-salary/:id', element: <ViewSalary /> },
        { path: 'accounts/transaction', element: <Transactions /> },
        { path: 'accounts/transaction/add', element: <AddTransaction /> },
        { path: 'accounts/pending-payments', element: <PendingPayments /> },
        { path: 'accounts/pending-payments/bill/view-payment/:customerId', element: <ViewPendingBillPayment /> },
        { path: 'accounts/pending-payments/purchase/view-payment/:vendorId', element: <ViewPendingPurchasePayment /> },
        { path: 'accounts/pending-payments/salary/view-payment/:employeeId', element: <ViewPendingSalaryPayment /> },
        { path: 'accounts/payments', element: <Payments /> },
        { path: 'accounts/payments/view-transaction/:id', element: <ViewTransaction /> },
        { path: 'accounts/reports', element: <Reports /> },
        { path: 'quotation/qto', element: <Qto /> },
        { path: 'quotation/qto/view/:id', element: <ViewQto /> },
        { path: 'quotation/boq', element: <Boq /> },
        { path: 'quotation/boq/view/:id', element: <ViewBoq /> },
        { path: 'quotation/quote', element: <Quote /> },
        { path: 'quotation/quote/view/:id', element: <ViewQuote /> },
        { path: 'project', element: <Projects /> },
        { path: 'project/view/:id', element: <ViewProject /> },
        { path: 'project/purchase/upload', element: <ProjectPurchaseUpload /> },
        { path: 'project/purchase/view/:id', element: <ViewPurchaseUpload /> },
      ],
    },
    {
      path: 'login',
      element: <Login />,
    },
    {
      path: 'register',
      element: <Register />,
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: token ? <Navigate to="/dashboard/app" /> : <Login /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);
}
