import { useState, useEffect } from 'react';
import Menu from '@mui/material/Menu';
import axios from 'axios';
import moment from 'moment';
import { Formik, Form, FormikProvider } from 'formik';
import { IconButton, TextField, Typography, Button, Stack, Box, MenuItem } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Iconify from '../Iconify';

const TeleCallerFilter = ({ toggleFilter, applyFilters }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const [leadManagers, setLeadManagers] = useState([]);
  const [telecallers, setTelecallers] = useState([]);
  const [sources, setSources] = useState([]);
  const [categories, setCategories] = useState([]);
  const [status, setStatus] = useState([]);
  const [leadManagersLoading, setLeadManagersLoading] = useState(true);
  const [telecallersLoading, setTelecallersLoading] = useState(false);
  const [sourcesLoading, setSourcesLoading] = useState(true);
  const [statusLoading, setStatusLoading] = useState(true);
  const [categoriesLoading, setCategoriesLoading] = useState(true);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getTelecallers = async (managerId) => {
    try {
      setTelecallersLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/leadTeam/teleCaller/for-filter`, {
        params: {
          managerId,
        },
      });
      console.log('telecallers list for filter', res.data.data);
      if (!res.data.isError) {
        if (res.data.data.records) {
          setTelecallers(res.data.data.records);
        }
      }
      setTelecallersLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getSources = async () => {
    try {
      setSourcesLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/leadSource/all/active`);
      console.log('sources list', res.data.data.status);
      if (!res.data.isError) {
        if (res.data.data.status) {
          setSources(res.data.data.status);
        }
      }
      setSourcesLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getCategories = async () => {
    try {
      setCategoriesLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/leadCategory/all/for-filter`);
      console.log('categories list', res.data.data.status);
      if (!res.data.isError) {
        if (res.data.data.status) {
          setCategories(res.data.data.status);
        }
      }
      setCategoriesLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getStatus = async () => {
    try {
      setStatusLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/leadStatus/all/active`);
      console.log('status list', res.data.data.status);
      if (!res.data.isError) {
        if (res.data.data.status) {
          setStatus(res.data.data.status);
        }
      }
      setStatusLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getTelecallers();
    getSources();
    getCategories();
    getStatus();
  }, []);

  return (
    <>
      <div>
        <Box style={{ display: 'flex', justifyContent: 'end' }}>
          <IconButton onClick={handleClick}>
            <Iconify icon="ic:round-filter-list" />
            <Typography variant="h6"> Filter</Typography>
          </IconButton>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <Formik
              initialValues={{
                fullname: '',
                email: '',
                phone: '',
                source: '',
                category: '',
                status: '',
                startDate: '',
                endDate: '',
              }}
              onSubmit={async (values) => {
                const updateValues = {
                  ...values,
                  startDate: values.startDate ? new Date(moment(values.startDate).startOf('day')) : null,
                  endDate: values.endDate ? new Date(moment(values.endDate).endOf('day')) : null,
                };
                applyFilters(updateValues);
              }}
            >
              {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting }) => (
                <Form style={{ padding: '10px' }}>
                  <Stack direction="column" spacing={1} sx={{ width: '400px' }}>
                    <Stack direction="row" spacing={1}>
                      <TextField
                        id="fullname"
                        name="fullname"
                        fullWidth
                        label="Fullname"
                        value={values.fullname}
                        onChange={handleChange}
                      />
                      <TextField
                        id="email"
                        name="email"
                        fullWidth
                        label="Email"
                        value={values.email}
                        onChange={handleChange}
                      />
                    </Stack>
                    <Stack direction="row" spacing={1}>
                      <Autocomplete
                        fullWidth
                        id="category"
                        value={values.category ? categories.find((c) => c._id === values.category) : null}
                        onChange={(event, newValue) => {
                          setFieldValue('category', newValue ? newValue._id : '');
                        }}
                        options={categories}
                        getOptionLabel={(option) => option.name}
                        loading={categoriesLoading}
                        renderInput={(params) => <TextField {...params} label="Category" fullWidth />}
                      />

                      <Autocomplete
                        id="status"
                        fullWidth
                        value={values.status ? status.find((s) => s._id === values.status) : null}
                        onChange={(event, newValue) => {
                          setFieldValue('status', newValue ? newValue._id : '');
                        }}
                        options={status}
                        getOptionLabel={(option) => option.name}
                        loading={statusLoading}
                        renderInput={(params) => <TextField {...params} label="Status" fullWidth />}
                      />
                    </Stack>
                    <Autocomplete
                      id="source"
                      fullWidth
                      value={values.source ? sources.find((s) => s._id === values.source) : null}
                      onChange={(event, newValue) => {
                        setFieldValue('source', newValue ? newValue._id : '');
                      }}
                      options={sources}
                      getOptionLabel={(option) => option.name}
                      loading={sourcesLoading}
                      renderInput={(params) => <TextField {...params} label="Source" fullWidth />}
                    />
                    <Stack direction="row" spacing={1}>
                      <TextField
                        type="date"
                        id="startDate"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        name="startDate"
                        fullWidth
                        label="Start Date"
                        value={values.startDate}
                        onChange={handleChange}
                      />
                      <TextField
                        type="date"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        id="endDate"
                        name="endDate"
                        fullWidth
                        label="Start Date"
                        value={values.endDate}
                        onChange={handleChange}
                      />
                    </Stack>
                    <Button variant="contained" type="submit" style={{ marginTop: '10px' }}>
                      Filter
                    </Button>
                  </Stack>
                </Form>
              )}
            </Formik>
          </Menu>
        </Box>
      </div>
    </>
  );
};

export default TeleCallerFilter;
