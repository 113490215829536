import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import axios from 'axios';
import moment from 'moment';
// material
import { styled } from '@mui/material/styles';
import {
  Card,
  Table,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  CircularProgress,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  FormControl,
  Select,
  Icon,
  Container,
  Typography,
  MenuItem,
  TableContainer,
  TablePagination,
  TextField,
  Grid,
  IconButton,
  Toolbar,
  OutlinedInput,
  InputAdornment,
  useMediaQuery,
  Avatar,
  chip,
} from '@mui/material';
import { Box } from '@mui/system';
import { ContentCopy } from '@mui/icons-material';
import CopyToClipboard from 'react-copy-to-clipboard';
import { LoadingButton } from '@mui/lab';
import { useFormik, Form, FormikProvider } from 'formik';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import AddIcon from '@mui/icons-material/Add';
import ModeEditSharpIcon from '@mui/icons-material/ModeEditSharp';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import Filter from '../components/filters/TransactionCategories';

// components
import Page from './Page';
import Scrollbar from './Scrollbar';
import Iconify from './Iconify';
import Popup from './popups/TransactionCategoryStatusChange';

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

const TableCellCustom = styled(TableCell)(({ theme }) => ({
  padding: '10px 15px',
}));

export default function TransactionCategories() {
  const matches = useMediaQuery('(max-width:600px)');
  const [transactionCategoryData, setTransactionCategoryData] = useState();
  const [name, setName] = useState('');
  const [pages, setPages] = useState(0);
  const [transactionCategoryCount, setTransactionCategoryCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoading1, setIsLoading1] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState();
  const [selectedIndex1, setSelectedIndex1] = useState();
  const [size, setSize] = useState(10);
  const [options, setOptions] = useState({ size, page: 0 });
  const [open, setOpen] = useState(false);
  const [input, setInput] = useState(1);
  const mobileRegExp = '^[6789][0-9]{9}';

  const transactionCategorySchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
    },
    validationSchema: transactionCategorySchema,
    onSubmit: async () => {
      try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/transactionType`, values);
        if (!res.data.isError) {
          closeAddTransactionCategoryModal(res.data.message);
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
        getTransactionCategorys(options);
        resetForm();
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  const handleStatusChange = (event, item, index) => {
    console.log('item', item);
    if (`${item.isBlocked}` === event.target.value) {
      return;
    }
    console.log(index);
    setSelectedIndex(index);
  };
  const handleStatusChange1 = (event, item, index) => {
    console.log('item', item);
    if (`${item.isPopular}` === event.target.value) {
      return;
    }
    console.log(index);
    setSelectedIndex1(index);
  };

  const handleClose = (refresh = false, message = '') => {
    setSelectedIndex();
    setSelectedIndex1();
    if (refresh) {
      getTransactionCategorys(options);
    }
    if (message) {
      toast(message);
    }
  };
  const openAddTransactionCategoryModal = () => {
    setOpen(true);
  };
  const closeAddTransactionCategoryModal = () => {
    setOpen(false);
    formik.resetForm();
  };

  const handlePageChange = (event, newPage) => {
    const temp = { ...options, page: newPage };
    setPages(newPage);
    setOptions(temp);
    setIsLoading(true);
    getTransactionCategorys(temp);
  };

  const getTransactionCategorys = (options) => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/transactionType/all`, {
        params: options,
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setIsLoading(false);
          setTransactionCategoryCount(res.data.data.maxRecords);
          setTransactionCategoryData(res.data.data.records);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const getTransactionCategorysWithoutLoading = (options) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/transactionType/all`, {
        params: options,
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setTransactionCategoryCount(res.data.data.maxRecords);
          setTransactionCategoryData(res.data.data.records);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const goToPage = () => {
    console.log('hi');
    if (input > Math.ceil(transactionCategoryCount / size)) {
      return;
    }
    setInput(input > 0 ? input : 1);
    setPages(input - 1 >= 0 ? input - 1 : 0);
    const temp = { ...options, page: input - 1 };
    setOptions(temp);
    getTransactionCategorys(temp);
  };

  const onSearch = (e) => {
    e.preventDefault();
    setName(e.target.value);
    const temp = { ...options, page: 0, name: e.target.value };
    setOptions(temp);
    setPages(0);
    getTransactionCategorysWithoutLoading(temp);
  };

  const applyFilters = (filter) => {
    console.log('filter', filter);
    const temp = { page: 0, size };
    // if (filter.name.length) {
    //   temp.name = filter.name;
    // }
    if (filter.isBlocked.length) {
      temp.isBlocked = filter.isBlocked.trim();
    }
    if (filter.isPopular.length) {
      temp.isPopular = filter.isPopular;
    }

    setOptions(temp);
    setPages(0);
    getTransactionCategorys(temp);
  };
  useEffect(() => {
    getTransactionCategorys(options);
  }, []);

  useEffect(() => {
    const temp = { ...options, size, page: 0 };
    setPages(0);
    setOptions(temp);
    getTransactionCategorys(temp);
  }, [size]);

  return (
    <>
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          {/* Add transactionCategory Dialog */}
          <Dialog open={open} onClose={closeAddTransactionCategoryModal} fullScreen={matches}>
            <DialogTitle>Add TransactionCategory</DialogTitle>
            <Box paddingBottom={'10px'} width={{ xs: '100%', sm: '600px' }}>
              <DialogContent paddingTop="20px">
                <FormikProvider value={formik}>
                  <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={9} lg={9}>
                        <TextField
                          fullWidth
                          label="Name"
                          {...getFieldProps('name')}
                          error={Boolean(touched.name && errors.name)}
                          helperText={touched.name && errors.name}
                        />
                      </Grid>

                      <Grid item xs={6} md={6}>
                        <TextField fullWidth label="Description" {...getFieldProps('description')} />
                      </Grid>
                    </Grid>
                    <Stack direction={'row'} justifyContent="flex-end" paddingTop="20px">
                      <Button onClick={closeAddTransactionCategoryModal}>Cancel</Button>
                      <LoadingButton type="submit" loading={isSubmitting}>
                        Add TransactionCategory
                      </LoadingButton>
                    </Stack>
                  </Form>
                </FormikProvider>
              </DialogContent>
            </Box>
          </Dialog>
          {/* Add transactionCategory dialog end */}
          <Container maxWidth="xl">
            <>
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Stack direction="row" spacing={2} padding={2}>
                  <SearchStyle
                    value={name}
                    onChange={onSearch}
                    placeholder="Search Transaction name..."
                    startAdornment={
                      <InputAdornment position="start">
                        <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                      </InputAdornment>
                    }
                  />
                </Stack>
                <Stack padding={1} direction="row" justifyContent="flex-end" spacing={2} alignItems="center">
                  <Button startIcon={<AddIcon />} variant="contained" onClick={openAddTransactionCategoryModal}>
                    Add Transaction Category
                  </Button>

                  {/* <Filter applyFilters={applyFilters} /> */}
                </Stack>
              </Stack>

              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCellCustom>Sl No</TableCellCustom>
                        <TableCellCustom>Name</TableCellCustom>
                        <TableCellCustom>Description</TableCellCustom>
                        {/* <TableCell>Actions</TableCell> */}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {transactionCategoryData?.map((item, id) => (
                        <TableRow key={id} sx={{ '&:hover': { backgroundColor: '#f5f5f5' } }}>
                          <TableCellCustom>{pages * size + (id + 1)}</TableCellCustom>
                          <TableCellCustom>{item?.name?.replaceAll("_"," ")}</TableCellCustom>
                          <TableCellCustom>{item.description}</TableCellCustom>

                          {/* <TableCellCustom>
                            <Box>
                              <Link to={`/dashboard/transactionCategory/view/${item._id}`}>
                                <Icon sx={{ color: 'gray' }}>
                                  <RemoveRedEyeIcon />
                                </Icon>
                              </Link>
                            </Box>
                          </TableCellCustom> */}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>
              <TablePagination
                rowsPerPageOptions={[10, 20, 30]}
                onRowsPerPageChange={(e) => {
                  setSize(e.target.value);
                }}
                component={'div'}
                count={transactionCategoryCount}
                showFirstButton
                showLastButton
                onPageChange={handlePageChange}
                rowsPerPage={size}
                page={pages}
              />
            </>
          </Container>
        </>
      )}
    </>
  );
}
