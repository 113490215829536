import { useDispatch } from 'react-redux';
import axios from 'axios';
import { useRef, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { PowerSettingsNew } from '@mui/icons-material';
// @mui
import { alpha } from '@mui/material/styles';

import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton, Button } from '@mui/material';
import { removeUser } from '../../utils/redux/userSlice';
// components
import MenuPopover from '../../components/MenuPopover';
// mocks_
import account from '../../_mock/account';
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Home',
    icon: 'eva:home-fill',
    linkTo: '/',
  },
  {
    label: 'Profile',
    icon: 'eva:person-fill',
    linkTo: '/dashboard/profile',
  },
  // {
  //   label: 'Settings',
  //   icon: 'eva:settings-2-fill',
  //   linkTo: '#',
  // },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const profile = JSON.parse(localStorage.getItem('profile'));
  const anchorRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = async () => {
    setOpen(null);
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/member/logout`, {});
      console.log(response.data.message);
      dispatch(removeUser());
      localStorage.clear();
      navigate('/', { replace: true });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          ...(open && {
            '&:before': {
              zIndex: 1,  
              content: "''",
              position: 'absolute',
              textDecoration: 'none',
            },
          }),
        }}
      >
        <Stack direction="row" alignItems="center">
          <Avatar
            src={(profile?.profilePic && process.env.REACT_APP_API_URL + profile?.profilePic) || account.photoURL}
            alt="photoURL"
          />
          <Stack sx={{ my: 1.5 }} ml={1}>
            <Typography variant="subtitle2" noWrap textAlign={'left'}>
              {profile?.fullname?.toUpperCase() || account?.displayName?.toUpperCase()}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
              {profile?.email?.toLowerCase() || account?.email?.toLowerCase()}
            </Typography>
          </Stack>
        </Stack>
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {profile.fullname || account.displayName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {profile.email || account.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} to={option.linkTo} component={RouterLink} onClick={handleClose}>
              <Iconify icon={option.icon} sx={{ width: 20, height: 20, mr: 1 }} />
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          <Stack direction="row" alignItems="center" spacing={1} justifyContent="center" sx={{ width: '100%' }}>
            <Typography variant="body2" sx={{ color: 'primaryDark.main' }}>
              Logout
            </Typography>
            <PowerSettingsNew sx={{ fontSize: '20px', color: 'primaryDark.main' }} />
          </Stack>
        </MenuItem>
      </MenuPopover>
    </>
  );
}
