import { ArrowBack, Delete } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardHeader,
  CircularProgress,
  Container,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Page from '../components/Page';
import Popup1 from '../components/popups/LeadDelete';
import ProjectBasicInfo from '../components/ProjectBasicInfo';
import ProjectDetailsPageTabs from '../components/ProjectDetailsPageTabs';

export default function ViewProject() {
  const { accType, role } = useSelector((state) => state.user.userInfo);
  const admin = accType === 'SUPER_ADMIN' || accType === 'SUB_ADMIN';
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [project, setProject] = useState({});
  const [selectedIndexDelete, setSelectedIndexDelete] = useState(false);

  const handleCloseDelete = (refresh = false, message = '') => {
    setSelectedIndexDelete(false);
    if (refresh) {
      navigate('/dashboard/crm/project');
    }
    if (message) {
      toast(message);
    }
  };

  const getProject = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/project`, {
        params: {
          id,
        },
      });
      console.log('project', res);
      setProject(res.data.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getProject();
  }, []);

  return (
    <Page title="View Project">
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItem: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Container maxWidth="xl">
          <Stack paddingTop={1} paddingX={2} direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h5">Project</Typography>
            <Stack direction="row" spacing={1} alignItems="center">
              {selectedIndexDelete && <Popup1 item={project} handleCloseDelete={handleCloseDelete} />}
              <IconButton size="large" onClick={() => navigate(-1)}>
                <ArrowBack sx={{ color: 'primaryDark.main', width: '32px', height: '32px' }} />
              </IconButton>
            </Stack>
          </Stack>
          <Box paddingX={{ xl: 2, lg: 2, md: 2, sm: 1, xs: 0.5 }} paddingY={1}>
            <Box id="basic-info" marginBottom={1}>
              <ProjectBasicInfo project={project} getProject={getProject} />
            </Box>
          </Box>
          <Box paddingX={{ xl: 2, lg: 2, md: 2, sm: 1, xs: 0.5 }} paddingY={1}>
            <Box id="basic-info" marginBottom={1}>
              <ProjectDetailsPageTabs project={project} />
            </Box>
          </Box>
        </Container>
      )}
    </Page>
  );
}
