import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  tableCellClasses,
} from '@mui/material';
import { AlternateEmail, FmdGood, Phone, RemoveCircle } from '@mui/icons-material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import ViewQuoteBasicDetails from '../components/ViewQuoteBasicDetails';
import Logo from '../components/Logo';
import NavigateBack from '../components/smallComponents/NavigateBack';

const StyledTableCell = styled(TableCell)(
  ({ theme, cellBorderLeft, cellBorderRight, fontWeight, bgcolor, textAlign }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: bgcolor || theme.palette.primary.main,
      color: theme.palette.text.primary,
      fontWeight: 'bold',
      textAlign: textAlign || 'left',
      border: '0.5px solid #d49292',
      borderLeft: cellBorderLeft || '0.5px solid #d49292',
      borderRight: cellBorderRight || '0.5px solid #d49292',
      fontSize: 14,
    },

    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      textAlign: textAlign || 'left',
      border: '0.5px solid #d49292',
      borderLeft: cellBorderLeft || '0.5px solid #d49292',
      borderRight: cellBorderRight || '0.5px solid #d49292',
      fontWeight: fontWeight || 'normal',
      // eslint-disable-next-line no-nested-ternary
      backgroundColor: bgcolor ? (bgcolor === 'theme' ? theme.palette.primary.main : bgcolor) : '#fff',
    },
  })
);

function ViewQuote() {
  const { id } = useParams();
  const [quote, setQuote] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [companyAddress, setCompanyAddress] = useState({});

  const formik = useFormik({
    initialValues: {
      id,
      amcCharges: [],
      termsAndConditions: [],
      exclusions: [],
      remarks: [],
    },
    onSubmit: async () => {
      try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/quotation/quote`, values);
        toast.success(res.data.message);
        getQuote();
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  const setValues = (data) => {
    formik.setFieldValue('amcCharges', data.amcCharges);
    formik.setFieldValue('termsAndConditions', data.termsAndConditions);
    formik.setFieldValue('exclusions', data.exclusions);
    formik.setFieldValue('remarks', data.remarks);
    formik.setFieldValue('totalPriceInWords', data.totalPriceInWords);
  };

  const getQuote = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/quotation/quote`, { params: { id } });
      console.log('quote', res.data);
      setQuote(res.data.data);
      setValues(res.data.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getCompanyAddress = () => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/utils/companyAddress`)
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setCompanyAddress(res.data.data.company);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getQuote();
    getCompanyAddress();
  }, []);

  return (
    <Page title="View Quote">
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItem: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Container maxWidth="xl">
          <Stack direction="row" justifyContent="space-between" alignItems="center" mt={2}>
            <Typography variant="h4"> COMMERCIAL PROPOSAL</Typography>
            <NavigateBack />
          </Stack>

          <Box paddingBottom={10}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TableContainer component={Paper} sx={{ padding: '3px', marginBottom: '10px', height: '100%' }}>
                  <Table
                    sx={{
                      '& .MuiTableCell-root': {
                        padding: '3px',
                      },
                      border: '3px solid #000',
                    }}
                  >
                    <TableHead>
                      <TableRow sx={{ height: '60px' }}>
                        <StyledTableCell colSpan={2} fontWeight="bold" textAlign="center">
                          {companyAddress?.companyName}
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow sx={{ height: '60px' }}>
                        <StyledTableCell sx={{ width: '80px' }} fontWeight="bold" textAlign="center">
                          <FmdGood />
                        </StyledTableCell>
                        <StyledTableCell>
                          {companyAddress?.address}, <br />
                          {companyAddress?.pin}
                        </StyledTableCell>
                      </TableRow>
                      <TableRow sx={{ height: '40px' }}>
                        <StyledTableCell sx={{ width: '80px' }} fontWeight="bold" textAlign="center">
                          <Phone />
                        </StyledTableCell>
                        <StyledTableCell>{companyAddress?.phone}</StyledTableCell>
                      </TableRow>
                      <TableRow sx={{ height: '40px' }}>
                        <StyledTableCell sx={{ width: '80px' }} fontWeight="bold" textAlign="center">
                          <AlternateEmail />
                        </StyledTableCell>
                        <StyledTableCell>{companyAddress?.email}</StyledTableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TableContainer component={Paper} sx={{ padding: '3px', marginBottom: '10px', height: '100%' }}>
                  <Table
                    size="small"
                    sx={{
                      '& .MuiTableCell-root': {
                        padding: '3px',
                      },
                      border: '3px solid #000',
                    }}
                  >
                    <TableHead>
                      <TableRow sx={{ height: '60px' }}>
                        <StyledTableCell colSpan={2} fontWeight="bold" textAlign="center">
                          MEP | Technical Services | Energy Saver & Management | IoT | Conditional Monitoring | IT |
                          Wireless & Networking
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow sx={{ height: '140px' }}>
                        <StyledTableCell sx={{ width: '80px' }} fontWeight="bold" textAlign="center" colSpan={2}>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              width: '100%',
                              height: '100%',
                            }}
                          >
                            <Logo />
                          </Box>
                        </StyledTableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TableContainer component={Paper} sx={{ padding: '3px', marginBottom: '10px', height: '100%' }}>
                  <Table
                    sx={{
                      '& .MuiTableCell-root': {
                        padding: '3px',
                      },
                      border: '3px solid #000',
                    }}
                  >
                    <TableHead>
                      <TableRow sx={{ height: '60px' }}>
                        <StyledTableCell colSpan={2} fontWeight="bold" textAlign="center">
                          Customer Details
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow sx={{ height: '60px' }}>
                        <StyledTableCell sx={{ width: '80px' }} fontWeight="bold" textAlign="center">
                          <FmdGood />
                        </StyledTableCell>
                        <StyledTableCell>{quote?.customerId?.fullname}</StyledTableCell>
                      </TableRow>
                      <TableRow sx={{ height: '40px' }}>
                        <StyledTableCell sx={{ width: '80px' }} fontWeight="bold" textAlign="center">
                          <Phone />
                        </StyledTableCell>
                        <StyledTableCell>{quote?.customerId?.phone}</StyledTableCell>
                      </TableRow>
                      <TableRow sx={{ height: '40px' }}>
                        <StyledTableCell sx={{ width: '80px' }} fontWeight="bold" textAlign="center">
                          <AlternateEmail />
                        </StyledTableCell>
                        <StyledTableCell>{quote?.customerId?.email}</StyledTableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TableContainer component={Paper} sx={{ padding: '3px', marginBottom: '10px', height: '100%' }}>
                  <Table
                    sx={{
                      '& .MuiTableCell-root': {
                        padding: '3px',
                      },
                      border: '3px solid #000',
                    }}
                  >
                    <TableHead>
                      <TableRow sx={{ height: '60px' }}>
                        <StyledTableCell colSpan={2} fontWeight="bold" textAlign="center">
                          Quotation
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow sx={{ height: '60px' }}>
                        <StyledTableCell sx={{ width: '80px' }} fontWeight="bold" textAlign="center">
                          No
                        </StyledTableCell>
                        <StyledTableCell>{quote?.quotationId}</StyledTableCell>
                      </TableRow>
                      <TableRow sx={{ height: '40px' }}>
                        <StyledTableCell sx={{ width: '80px' }} fontWeight="bold" textAlign="center">
                          Date
                        </StyledTableCell>
                        <StyledTableCell>
                          {quote?.quotationDate ? moment(quote?.quotationDate).format('DD-MM-YYYY') : 'N/A'}
                        </StyledTableCell>
                      </TableRow>
                      <TableRow sx={{ height: '40px' }}>
                        <StyledTableCell sx={{ width: '80px' }} fontWeight="bold" textAlign="center">
                          Valid Till
                        </StyledTableCell>
                        <StyledTableCell>
                          {quote?.quotationValidTill ? moment(quote?.quotationValidTill).format('DD-MM-YYYY') : 'N/A'}
                        </StyledTableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
            <ViewQuoteBasicDetails data={quote} getQuote={getQuote} />

            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Scrollbar sx={{ marginTop: '5px' }}>
                  <TableContainer component={Paper} sx={{ padding: '3px', marginBottom: '20px' }}>
                    <Table
                      sx={{
                        '& .MuiTableCell-root': {
                          padding: '3px',
                        },
                        border: '3px solid #000',
                      }}
                    >
                      <TableHead>
                        <TableRow>
                          <StyledTableCell sx={{ width: '80px' }}>Sl. No.</StyledTableCell>
                          <StyledTableCell>Name</StyledTableCell>
                          <StyledTableCell>Unit</StyledTableCell>
                          <StyledTableCell>Qty</StyledTableCell>
                          <StyledTableCell>Unit Price</StyledTableCell>
                          <StyledTableCell>Total Price</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {quote?.items?.map((item, index) => (
                          <TableRow key={index}>
                            <StyledTableCell>{index + 1}</StyledTableCell>
                            <StyledTableCell>{item.name}</StyledTableCell>
                            <StyledTableCell>{item.unit}</StyledTableCell>
                            <StyledTableCell>{item.quantity}</StyledTableCell>
                            <StyledTableCell>{item.roundedUnitPriceAfterOverHead}</StyledTableCell>
                            <StyledTableCell>{item.roundedTotalPriceAfterOverhead}</StyledTableCell>
                          </TableRow>
                        ))}
                        <TableRow sx={{ height: '50px' }}>
                          <StyledTableCell
                            colSpan={3}
                            fontWeight="bold"
                            contentEditable
                            onBlur={(e) => {
                              formik.setFieldValue('totalPriceInWords', e.target.innerText);
                            }}
                          >
                            {values.totalPriceInWords}
                          </StyledTableCell>
                          <StyledTableCell colSpan={2} bgcolor="theme" fontWeight="bold">
                            Total
                          </StyledTableCell>
                          <StyledTableCell colSpan={2} fontWeight="bold">
                            {quote?.items?.reduce((acc, item) => acc + item.roundedTotalPriceAfterOverhead, 0)}
                          </StyledTableCell>
                        </TableRow>
                        <TableRow>
                          <StyledTableCell colSpan={7} textAlign="center">
                            Additional 5% VAT will be applicable as per UAE Federal rule
                          </StyledTableCell>
                        </TableRow>
                        <TableRow>
                          {/* AMC Table  */}
                          <StyledTableCell colSpan={7} textAlign="center" fontWeight="bold">
                            AMC Charges
                          </StyledTableCell>
                        </TableRow>
                        <TableRow>
                          <StyledTableCell textAlign="center" fontWeight="bold" bgcolor="theme">
                            Sl. No.
                          </StyledTableCell>
                          <StyledTableCell textAlign="center" fontWeight="bold" bgcolor="theme">
                            Description
                          </StyledTableCell>
                          <StyledTableCell textAlign="center" fontWeight="bold" bgcolor="theme">
                            Unit
                          </StyledTableCell>
                          <StyledTableCell textAlign="center" fontWeight="bold" bgcolor="theme">
                            Qty
                          </StyledTableCell>
                          <StyledTableCell textAlign="center" fontWeight="bold" bgcolor="theme">
                            Unit Price
                          </StyledTableCell>
                          <StyledTableCell textAlign="center" fontWeight="bold" bgcolor="theme">
                            Total Price
                          </StyledTableCell>
                        </TableRow>

                        {values.amcCharges.map((item, index) => (
                          <TableRow key={index}>
                            <StyledTableCell>
                              <Stack direction="row" spacing={0.5} alignItems={'center'} paddingLeft={2}>
                                <RemoveCircle
                                  sx={{ cursor: 'pointer' }}
                                  onClick={() => {
                                    const amcCharges = [...values.amcCharges];
                                    amcCharges.splice(index, 1);
                                    formik.setFieldValue('amcCharges', amcCharges);
                                  }}
                                />
                                {index + 1}
                              </Stack>
                            </StyledTableCell>
                            <StyledTableCell
                              contentEditable
                              onBlur={(e) => {
                                const amcCharges = [...values.amcCharges];
                                amcCharges[index].name = e.target.innerText;
                                formik.setFieldValue('amcCharges', amcCharges);
                              }}
                            >
                              {item.name}
                            </StyledTableCell>
                            <StyledTableCell
                              contentEditable
                              onBlur={(e) => {
                                const amcCharges = [...values.amcCharges];
                                amcCharges[index].unit = e.target.innerText;
                                formik.setFieldValue('amcCharges', amcCharges);
                              }}
                            >
                              {item.unit}
                            </StyledTableCell>
                            <StyledTableCell
                              contentEditable
                              onBlur={(e) => {
                                const amcCharges = [...values.amcCharges];
                                amcCharges[index].quantity = Number(e.target.innerText);
                                amcCharges[index].totalPrice = parseFloat(
                                  Number(item.unitPrice) * Number(e.target.innerText)
                                ).toFixed(2);
                                formik.setFieldValue('amcCharges', amcCharges);
                              }}
                            >
                              {item.quantity}
                            </StyledTableCell>
                            <StyledTableCell
                              contentEditable
                              onBlur={(e) => {
                                const amcCharges = [...values.amcCharges];
                                amcCharges[index].unitPrice = Number(e.target.innerText);
                                amcCharges[index].totalPrice = parseFloat(
                                  Number(item.quantity) * Number(e.target.innerText)
                                ).toFixed(2);
                                formik.setFieldValue('amcCharges', amcCharges);
                              }}
                            >
                              {item.unitPrice}
                            </StyledTableCell>
                            <StyledTableCell>
                              {item.totalPrice ? item.totalPrice : item.unitPrice * item.quantity}
                            </StyledTableCell>
                          </TableRow>
                        ))}

                        <TableRow>
                          <StyledTableCell colSpan={7}>
                            <Button
                              variant="contained"
                              color="primary"
                              sx={{ float: 'right' }}
                              onClick={() => {
                                const amcCharges = [...values.amcCharges];
                                amcCharges.push({
                                  name: '',
                                  unit: '',
                                  quantity: '',
                                  unitPrice: '',
                                  totalPrice: '',
                                });
                                formik.setFieldValue('amcCharges', amcCharges);
                              }}
                            >
                              Add Row
                            </Button>
                          </StyledTableCell>
                          {/* Terms and Conditions  */}
                        </TableRow>
                        <TableRow>
                          <StyledTableCell colSpan={7} fontWeight="bold" bgcolor="theme" textAlign="center">
                            TERMS & CONDITIONS
                          </StyledTableCell>
                        </TableRow>
                        {values?.termsAndConditions?.map((item, index) => (
                          <TableRow key={index}>
                            <StyledTableCell>
                              <Stack direction="row" spacing={0.5} alignItems={'center'} paddingLeft={2}>
                                <RemoveCircle
                                  sx={{ cursor: 'pointer' }}
                                  onClick={() => {
                                    const termsAndConditions = [...values.termsAndConditions];
                                    termsAndConditions.splice(index, 1);
                                    formik.setFieldValue('termsAndConditions', termsAndConditions);
                                  }}
                                />
                                {index + 1}
                              </Stack>
                            </StyledTableCell>
                            <StyledTableCell
                              colSpan={6}
                              contentEditable
                              onBlur={(e) => {
                                const termsAndConditions = [...values.termsAndConditions];
                                termsAndConditions[index] = e.target.innerText;
                                formik.setFieldValue('termsAndConditions', termsAndConditions);
                              }}
                            >
                              {item}
                            </StyledTableCell>
                          </TableRow>
                        ))}
                        <TableRow>
                          <StyledTableCell colSpan={7}>
                            <Button
                              variant="contained"
                              color="primary"
                              sx={{ float: 'right' }}
                              onClick={() => {
                                const termsAndConditions = [...values.termsAndConditions];
                                termsAndConditions.push('');
                                formik.setFieldValue('termsAndConditions', termsAndConditions);
                              }}
                            >
                              Add Row
                            </Button>
                          </StyledTableCell>
                        </TableRow>
                        {/* Terms and Conditions end  */}
                        {/* Exclusions  */}
                        <TableRow>
                          <StyledTableCell colSpan={7} fontWeight="bold" bgcolor="theme" textAlign="center">
                            EXCLUSIONS
                          </StyledTableCell>
                        </TableRow>
                        {values?.exclusions?.map((item, index) => (
                          <TableRow key={index}>
                            <StyledTableCell>
                              <Stack direction="row" spacing={0.5} alignItems={'center'} paddingLeft={2}>
                                <RemoveCircle
                                  sx={{ cursor: 'pointer' }}
                                  onClick={() => {
                                    const exclusions = [...values.exclusions];
                                    exclusions.splice(index, 1);
                                    formik.setFieldValue('exclusions', exclusions);
                                  }}
                                />
                                {index + 1}
                              </Stack>
                            </StyledTableCell>
                            <StyledTableCell
                              colSpan={6}
                              contentEditable
                              onBlur={(e) => {
                                const exclusions = [...values.exclusions];
                                exclusions[index] = e.target.innerText;
                                formik.setFieldValue('exclusions', exclusions);
                              }}
                            >
                              {item}
                            </StyledTableCell>
                          </TableRow>
                        ))}
                        <TableRow>
                          <StyledTableCell colSpan={7}>
                            <Button
                              variant="contained"
                              color="primary"
                              sx={{ float: 'right' }}
                              onClick={() => {
                                const exclusions = [...values.exclusions];
                                exclusions.push('');
                                formik.setFieldValue('exclusions', exclusions);
                              }}
                            >
                              Add Row
                            </Button>
                          </StyledTableCell>
                        </TableRow>
                        {/* Exclusions end  */}
                        {/* remarks  */}
                        <TableRow>
                          <StyledTableCell colSpan={7} fontWeight="bold" bgcolor="theme" textAlign="center">
                            REMARKS
                          </StyledTableCell>
                        </TableRow>
                        {values?.remarks?.map((item, index) => (
                          <TableRow key={index}>
                            <StyledTableCell>
                              <Stack direction="row" spacing={0.5} alignItems={'center'} paddingLeft={2}>
                                <RemoveCircle
                                  sx={{ cursor: 'pointer' }}
                                  onClick={() => {
                                    const remarks = [...values.remarks];
                                    remarks.splice(index, 1);
                                    formik.setFieldValue('remarks', remarks);
                                  }}
                                />
                                {index + 1}
                              </Stack>
                            </StyledTableCell>
                            <StyledTableCell
                              colSpan={6}
                              contentEditable
                              onBlur={(e) => {
                                const remarks = [...values.remarks];
                                remarks[index] = e.target.innerText;
                                formik.setFieldValue('remarks', remarks);
                              }}
                            >
                              {item}
                            </StyledTableCell>
                          </TableRow>
                        ))}
                        <TableRow>
                          <StyledTableCell colSpan={7}>
                            <Button
                              variant="contained"
                              color="primary"
                              sx={{ float: 'right' }}
                              onClick={() => {
                                const remarks = [...values.remarks];
                                remarks.push('');
                                formik.setFieldValue('remarks', remarks);
                              }}
                            >
                              Add Row
                            </Button>
                          </StyledTableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Scrollbar>
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  spacing={1}
                  sx={{ padding: '10px' }}
                >
                  <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                    Submit
                  </LoadingButton>
                </Stack>
              </Form>
            </FormikProvider>
          </Box>
        </Container>
      )}
    </Page>
  );
}

export default ViewQuote;
