import { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import axios from 'axios';
import Moment from 'moment';
// material
import {
  Card,
  Table,
  Stack,
  CircularProgress,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Container,
  TableContainer,
} from '@mui/material';
// import Filter from './filters/TrailBalance';

// components
import Scrollbar from './Scrollbar';

export default function TrailBalance() {
  const navigate = useNavigate();

  const [transactionData, setTransactionData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [options, setOptions] = useState({});
  const [summary, setSummary] = useState({});

  const getTrailBalance = (options) => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/ledger/trial-balance`, {
        params: options,
      })
      .then((res) => {
        console.log('Payments', res);
        if (res.data.isError === false) {
          setIsLoading(false);
          setTransactionData(res.data.data.records);
          setSummary(res.data.data.summary);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const applyFilters = (filter) => {
    console.log('filter', filter);
    const temp = {};

    if (filter.transactionType) {
      temp.transactionType = filter.transactionType.trim();
    }
    if (filter.ledgerId) {
      temp.ledgerId = filter.ledgerId;
    }

    if (filter.paymentStartDate) {
      temp.paymentStartDate = filter.paymentStartDate;
    }
    if (filter.paymentEndDate) {
      temp.paymentEndDate = filter.paymentEndDate;
    }
    if (filter.txnStartDate) {
      temp.txnStartDate = filter.txnStartDate;
    }
    if (filter.txnEndDate) {
      temp.txnEndDate = filter.txnEndDate;
    }
    if (filter.paymentMode) {
      temp.paymentMode = filter.paymentMode;
    }

    setOptions(temp);
    getTrailBalance(temp);
  };

  useEffect(() => {
    getTrailBalance(options);
  }, []);

  const formatNumber = (num) => parseFloat(num).toFixed(2);

  return (
    <>
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <Container maxWidth="xl">
            <Card>
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Stack padding={1} direction="row" justifyContent="flex-end" spacing={2} alignItems="center">
                  {/* <Filter applyFilters={applyFilters} /> */}
                </Stack>
              </Stack>

              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Sl No</TableCell>
                        <TableCell>Account Category</TableCell>
                        <TableCell>Account Type</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Credit</TableCell>
                        <TableCell>Debit</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {transactionData?.map((item, id) => (
                        <TableRow key={id} sx={{ '&:hover': { backgroundColor: '#f5f5f5' } }}>
                          <TableCell>{id + 1}</TableCell>
                          <TableCell>{item.accountCategory}</TableCell>
                          <TableCell>{item?.accountType}</TableCell>
                          <TableCell>{item?.name}</TableCell>
                          <TableCell>₹{formatNumber(item?.credit)}</TableCell>
                          <TableCell>₹{formatNumber(item?.debit)}</TableCell>
                        </TableRow>
                      ))}
                      <TableRow>
                        <TableCell colSpan={4} align="right">
                          <b>Total</b>
                        </TableCell>
                        <TableCell>
                          <b>₹{formatNumber(summary?.totalCredit)}</b>
                        </TableCell>
                        <TableCell>
                          <b>₹{formatNumber(summary?.totalDebit)}</b>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>
            </Card>
          </Container>
        </>
      )}
    </>
  );
}
