import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  Container,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import axios from 'axios';
import { useFormik, Form, FormikProvider } from 'formik';
import { DeleteForever } from '@mui/icons-material';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Page from '../components/Page';

const AddTransaction = () => {
  const navigate = useNavigate();
  const [transactionTypes, setTransactionTypes] = useState([]);
  const [ledgers, setLedgers] = useState([]);

  const convertToParseFloat = (value) => parseFloat(value).toFixed(2);

  // const transactionSchema = Yup.object().shape({
  // });

  const formik = useFormik({
    initialValues: {
      items: [
        {
          ledgerId: '',
          transactionCategory: '',
          transactionType: '',
          amount: '',
          paymentMode: '',
        },
      ],
    },
    onSubmit: async () => {
      try {
        let creditAmount = 0;
        let debitAmount = 0;
        values.items.forEach((item) => {
          if (item.transactionType === 'CREDIT') {
            creditAmount += parseFloat(item.amount);
          } else if (item.transactionType === 'DEBIT') {
            debitAmount += parseFloat(item.amount);
          }
        });
        if (creditAmount !== debitAmount) {
          toast.error("Credit and Debit Amount doesn't match"); 
          return;
        }
        if (creditAmount === 0 || debitAmount === 0) {
          toast.error("Credit and Debit Amount can't be 0");
          return;
        }
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/payment/journal`, {
          ...values,
        });

        console.log(res.data.data);
        toast.success(res.data.message); 
        resetForm();
        navigate('/dashboard/accounts/transaction');
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });

  const getTransactionTypes = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/transactionType/other/active`);
      console.log(res.data.data);
      setTransactionTypes(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getLedgers = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/ledger/all/active`);
      console.log(res.data.data, 'ledgers');
      if (!res.data.isError) {
        setLedgers(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  useEffect(() => {
    getTransactionTypes();
    getLedgers();
  }, []);

  return (
    <Page title="Add Transactions">
      <Container maxWidth="xl">
        <Typography variant="h5" fontWeight={'bold'}>
          Add Transaction
        </Typography>
        <Card>
          <Box>
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    margin={1}
                    sx={{
                      borderRadius: '5px',
                    }}
                  >
                    <Stack>
                      <TableContainer component={Paper}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Ledger</TableCell>
                              <TableCell>Transaction Category</TableCell>
                              <TableCell>Payment Mode</TableCell>
                              <TableCell>Amount</TableCell>
                              <TableCell>Type</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {values.items.map((item, index) => (
                              <TableRow key={index}>
                                <TableCell>
                                  <Autocomplete
                                    fullWidth
                                    id="asynchronous-demo"
                                    isOptionEqualToValue={(option, value) => option.name === value.name}
                                    getOptionLabel={(option) => option.name}
                                    getOptionSelected={(option, value) => option.name === value.name}
                                    options={ledgers}
                                    onChange={(_, value) => {
                                      if (value) {
                                        const newItems = [...values.items];
                                        newItems[index] = {
                                          ...newItems[index],
                                          ledgerId: value._id,
                                        };
                                        formik.setFieldValue('items', newItems);
                                      }
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Ledger"
                                        InputProps={{
                                          ...params.InputProps,
                                        }}
                                      />
                                    )}
                                    renderOption={(props, option) => (
                                      <li {...props}>
                                        <Stack direction={'column'}>
                                          <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1' }}>
                                            {option.name}
                                          </Typography>
                                        </Stack>
                                      </li>
                                    )}
                                  />
                                </TableCell>
                                <TableCell>
                                  <TextField
                                    fullWidth
                                    select
                                    label="Transaction Category"
                                    placeholder="Transaction Category"
                                    onChange={(e) => {
                                      const newItems = [...values.items];
                                      newItems[index] = {
                                        ...newItems[index],
                                        transactionCategory: e.target.value,
                                      };
                                      formik.setFieldValue('items', newItems);
                                    }}
                                  >
                                    <MenuItem value="INCOME">Income</MenuItem>
                                    <MenuItem value="EXPENSE">Expense</MenuItem>
                                    <MenuItem value="JOURNAL">Journal</MenuItem>
                                  </TextField>
                                </TableCell>

                                <TableCell>
                                  <TextField
                                    select
                                    label="Payment Mode"
                                    fullWidth
                                    value={item.paymentMode}
                                    sx={{ width: '100%' }}
                                    onChange={(e) => {
                                      const newItems = [...values.items];
                                      newItems.forEach((item, i) => {
                                        newItems[i] = {
                                          ...newItems[i],
                                          paymentMode: e.target.value,
                                        };
                                      });
                                      formik.setFieldValue('items', newItems);
                                    }}
                                  >
                                    <MenuItem value="UPI">UPI</MenuItem>
                                    <MenuItem value="NEFT">NEFT</MenuItem>
                                    <MenuItem value="RTGS">RTGS</MenuItem>
                                    <MenuItem value="IMPS">IMPS</MenuItem>
                                    <MenuItem value="CASH">CASH</MenuItem>
                                    <MenuItem value="CHEQUE">CHEQUE</MenuItem>
                                    <MenuItem value="DD">DD</MenuItem>
                                    <MenuItem value="CARD">CARD</MenuItem>
                                    <MenuItem value="OTHER">OTHER</MenuItem>
                                  </TextField>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    maxWidth: '120px',
                                  }}
                                >
                                  <TextField
                                    fullWidth
                                    label="Amount"
                                    placeholder="Amount"
                                    onChange={(e) => {
                                      const newItems = [...values.items];
                                      newItems[index] = {
                                        ...newItems[index],
                                        amount: e.target.value,
                                      };
                                      formik.setFieldValue('items', newItems);
                                    }}
                                  />
                                </TableCell>
                                <TableCell
                                  sx={{
                                    minWidth: '100px',
                                  }}
                                >
                                  <TextField
                                    fullWidth
                                    select
                                    label="Type"
                                    placeholder="Type"
                                    onChange={(e) => {
                                      const newItems = [...values.items];
                                      newItems[index] = {
                                        ...newItems[index],
                                        transactionType: e.target.value,
                                      };
                                      formik.setFieldValue('items', newItems);
                                    }}
                                  >
                                    <MenuItem value="CREDIT">Credit</MenuItem>
                                    <MenuItem value="DEBIT">Debit</MenuItem>
                                  </TextField>
                                </TableCell>
                                {index !== 0 && (
                                  <TableCell sx={{ width: '120px' }}>
                                    <IconButton
                                      onClick={() => {
                                        const newItems = [...values.items];
                                        newItems.splice(index, 1);
                                        formik.setFieldValue('items', newItems);
                                      }}
                                      variant="outlined"
                                      color="error"
                                    >
                                      <DeleteForever />
                                    </IconButton>
                                  </TableCell>
                                )}
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <Stack sx={{ width: '100%' }} justifyContent={'flex-end'} direction={'row'} mt={1}>
                        <Button
                          variant="contained"
                          onClick={() => {
                            const newItems = [...values.items];
                            newItems.push({
                              ledgerId: '',
                              transactionName: 0,
                              transactionType: 0,
                              amount: 0,
                              paymentMode: values.items[0].paymentMode,
                            });
                            formik.setFieldValue('items', newItems);
                          }}
                        >
                          Add Item
                        </Button>
                      </Stack>
                    </Stack>
                  </Grid>
                </Grid>
                <Box p={5} sx={{ textAlign: 'right' }}>
                  <Button type="submit" variant="contained" disabled={isSubmitting}>
                    Submit
                  </Button>
                  <Button type="button" variant="contained" sx={{ marginLeft: 2 }} onClick={() => resetForm()}>
                    Reset
                  </Button>
                </Box>
              </Form>
            </FormikProvider>
          </Box>
        </Card>
      </Container>
    </Page>
  );
};

export default AddTransaction;
