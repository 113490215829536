// component
import { Box } from '@mui/material';
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={32} height={32} />;

const navConfig = [
  // {
  //   title: 'dashboard',
  //   path: '/dashboard/app',
  //   icon: getIcon('eva:pie-chart-2-fill'),
  // },
  {
    title: 'general',
    path: '/dashboard/general',
    icon: getIcon('carbon:home'),
    permission: ['SUPER_ADMIN', 'SUB_ADMIN'],
    children: [
      {
        title: 'employees',
        path: '/dashboard/general/employees',
        icon: 'clarity:employee-solid',
        permission: ['SUPER_ADMIN', 'SUB_ADMIN'],
      },
      {
        title: 'customers',
        path: '/dashboard/general/customers',
        icon: 'clarity:users-solid',
        permission: ['SUPER_ADMIN', 'SUB_ADMIN'],
      },
      {
        title: 'vendors',
        path: '/dashboard/general/vendors',
        icon: 'clarity:users-solid',
        permission: ['SUPER_ADMIN', 'SUB_ADMIN'],
      },
      {
        title: 'Admins',
        path: '/dashboard/general/admin',
        icon: 'eos-icons:admin',
        permission: ['SUPER_ADMIN'],
      },
      {
        title: 'settings',
        path: '/dashboard/general/settings',
        icon: 'carbon:settings',
        permission: ['SUPER_ADMIN', 'SUB_ADMIN'],
      },
    ],
  },

  {
    title: 'CRM',
    path: '/dashboard/crm',
    icon: getIcon('fa-solid:handshake'),
    permission: ['SUPER_ADMIN', 'SUB_ADMIN', 'TELE_CALLER', 'LEAD_MANAGER'],
    children: [
      {
        title: 'lead',
        path: '/dashboard/crm/lead',
        icon: 'majesticons:list-box',
        permission: ['SUPER_ADMIN', 'SUB_ADMIN', 'LEAD_MANAGER', 'TELE_CALLER'],
      },
      {
        title: 'manager',
        path: '/dashboard/crm/leadmanager',
        icon: 'mdi:account-tie',
        permission: ['SUPER_ADMIN', 'SUB_ADMIN'],
      },
      {
        title: 'telecaller',
        path: '/dashboard/crm/telecaller',
        icon: 'mdi:phone-outline',
        permission: ['SUPER_ADMIN', 'SUB_ADMIN', 'LEAD_MANAGER'],
      },
      {
        title: 'settings',
        path: '/dashboard/crm/settings',
        icon: 'material-symbols:settings',
        permission: ['SUPER_ADMIN', 'SUB_ADMIN'],
      },
    ],
  },
  {
    title: 'projects',
    path: '/dashboard/project',
    icon: getIcon('fa-solid:table'),
    permission: ['SUPER_ADMIN', 'SUB_ADMIN'],
  },
  {
    title: 'Quotation',
    path: '/dashboard/quotation',
    icon: getIcon('teenyicons:invoice-solid'),
    permission: ['SUPER_ADMIN', 'SUB_ADMIN'],
    children: [
      {
        title: 'QTO',
        path: '/dashboard/quotation/qto',
        icon: 'teenyicons:invoice-solid',
        permission: ['SUPER_ADMIN', 'SUB_ADMIN'],
      },
      {
        title: 'BOQ',
        path: '/dashboard/quotation/boq',
        icon: 'teenyicons:invoice-solid',
        permission: ['SUPER_ADMIN', 'SUB_ADMIN'],
      },
      {
        title: 'quote',
        path: '/dashboard/quotation/quote',
        icon: 'teenyicons:invoice-solid',
        permission: ['SUPER_ADMIN', 'SUB_ADMIN'],
      },
    ],
  },
  {
    title: 'accounts',
    path: '/dashboard/accounts',
    icon: getIcon('carbon:account'),
    permission: ['SUPER_ADMIN', 'SUB_ADMIN'],
    children: [
      {
        title: 'ledgers',
        path: '/dashboard/accounts/ledger',
        icon: 'carbon:money',
        permission: ['SUPER_ADMIN', 'SUB_ADMIN'],
      },
      {
        title: 'bill',
        path: '/dashboard/accounts/bill',
        icon: 'mingcute:bill-fill',
        permission: ['SUPER_ADMIN', 'SUB_ADMIN'],
      },
      {
        title: 'purchase',
        path: '/dashboard/accounts/purchase',
        icon: 'carbon:shopping-cart',
        permission: ['SUPER_ADMIN', 'SUB_ADMIN'],
      },
      {
        title: 'salary',
        path: '/dashboard/accounts/salary',
        icon: 'carbon:money',
        permission: ['SUPER_ADMIN', 'SUB_ADMIN'],
      },
      {
        title: 'transactions',
        path: '/dashboard/accounts/transaction',
        icon: 'carbon:money',
        permission: ['SUPER_ADMIN', 'SUB_ADMIN'],
      },
      {
        title: 'pending payments',
        path: '/dashboard/accounts/pending-payments',
        icon: 'carbon:money',
        permission: ['SUPER_ADMIN', 'SUB_ADMIN'],
      },
      {
        title: 'payments',
        path: '/dashboard/accounts/payments',
        icon: 'carbon:money',
        permission: ['SUPER_ADMIN', 'SUB_ADMIN'],
      },
      {
        title: 'reports',
        path: '/dashboard/accounts/reports',
        icon: 'carbon:report-data',
        permission: ['SUPER_ADMIN', 'SUB_ADMIN'],
      },
      {
        title: 'settings',
        path: '/dashboard/accounts/settings',
        icon: 'carbon:settings',
        permission: ['SUPER_ADMIN', 'SUB_ADMIN'],
      },
    ],
  },
];

export default navConfig;
