import { ArrowBack } from '@mui/icons-material';
import { Box, Card, CardHeader, CircularProgress, Container, IconButton, Stack, Typography } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AdminBasicInfo from '../components/AdminBasicInfo';
import Page from '../components/Page';

export default function ViewAdmin() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [admin, setAdmin] = useState({});
  const [roles, setRoles] = useState([]);

  const getAdmin = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/member`, {
        params: {
          id,
        },
      });
      console.log('admin', res);
      setAdmin(res.data.data);
      setRoles(res.data.data.role);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAdmin();
  }, []);

  return (
    <Page title="View Admin">
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItem: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Container maxWidth="xl">
          <>
            <Stack paddingTop={1} paddingX={2} direction="row" justifyContent="space-between" alignItems="center">
              <Typography variant="h5">Admin</Typography>
              <IconButton size='large' onClick={() => navigate(-1)}>
                                  <ArrowBack sx={{ color: 'primaryDark.main', width: '32px', height: '32px' }} />

              </IconButton>
            </Stack>
            <Box paddingX={{ xl: 2, lg: 2, md: 2, sm: 1, xs: 0.5 }} paddingY={1}>
              <Box id="basic-info" marginBottom={1}>
                <AdminBasicInfo admin={admin} getAdmin={getAdmin} />
              </Box>
            </Box>

            
          </>
        </Container>
      )}
    </Page>
  );
}
