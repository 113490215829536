import React, { useEffect, useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  Container,
  Grid,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Cancel, Close, DeleteForever } from '@mui/icons-material';
import moment from 'moment';

function PurchaseEditForm({ purchase, setEditMode }) {
  const navigate = useNavigate();
  const [sourcesLoading, setSourcesLoading] = useState(false);
  const [taxes, setTaxes] = useState([]);

  const convertToParseFloat = (value) => parseFloat(value).toFixed(2);

  const purchaseSchema = Yup.object().shape({
    invoiceDate: Yup.string().required('Invoice Date is required'),
    subTotal: Yup.string().required('Sub Total is required'),
    discount: Yup.string().required('Discount is required'),
    grandTotal: Yup.string().required('Grand Total is required'),
  });

  const formik = useFormik({
    initialValues: {
      _id: purchase._id,
      invoiceDate: moment(purchase.invoiceDate).format('YYYY-MM-DD'),
      customerId: purchase.customerId,
      subTotal: purchase.subTotal,
      discount: purchase.discount,
      grandTotal: purchase.grandTotal,
      items: purchase.items?.map((item) => ({
        _id: item._id,
        productId: item.productId,
        quantity: item.quantity,
        unitPrice: item.unitPrice,
        taxId: '',
        tax: item.tax, // <--- this is the tax percentage just to print current tax. not needed for formik
        taxArray: item.tax, //  taxArray: item.tax, // <--- this is the tax object just to print current tax. not needed for formik
        total: item.total,
        name: item.name,
      })),
    },
    validationSchema: purchaseSchema,
    onSubmit: async () => {
      try {
        if (!values.items.length) {
          return toast.error('Please add atleast one item');
        }
        const formValues = {
          ...values,
          subTotal: convertToParseFloat(values.subTotal),
          discount: convertToParseFloat(values.discount),
          grandTotal: convertToParseFloat(values.grandTotal),
        };
        formValues.items = formValues.items.map((item) => {
          const { tax, taxArray, ...rest } = item;
          return rest;
        });
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/purchase`, {
          ...formValues,
        });
        console.log(res.data.data);
        toast.success(res.data.message);
        resetForm();
        navigate('/dashboard/accounts/purchase');
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  useEffect(() => {
    let subTotal = 0;
    let grandTotal = 0;
    values.items.forEach((item) => {
      subTotal += Number(item.total);
    });
    grandTotal = subTotal - Number(values.discount || 0);
    formik.setFieldValue('subTotal', subTotal);
    formik.setFieldValue('grandTotal', grandTotal);
  }, [values.items, values.discount, values.taxId, taxes]);

  const getTaxes = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/tax/active/all`);
      console.log(res.data.data);
      setTaxes(res.data.data);
      setSourcesLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getTaxes();
  }, []);

  const totalCalculator = (quantity, price, tax = []) => {
    console.log(tax);
    let totalTax = 0;
    tax.forEach((item) => {
      const tax = (Number(price) * Number(item.percentage)) / 100;
      totalTax += tax;
    });
    return ((Number(price) + totalTax) * quantity).toFixed(2);
  };

  const taxCalculator = (price, tax) => {
    console.log(tax, price);
    let totalTax = 0;
    tax.forEach((item) => {
      const taxAmount = (Number(price) * Number(item.percentage)) / 100;
      totalTax += taxAmount;
    });
    return totalTax.toFixed(2);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack direction={'row'} px={5} py={3} sx={{ backgroundColor: '#fabb0071' }} justifyContent={'space-between'}>
          {/* <Box sx={{ flex: '1' }}>Logo</Box> */}

          <Stack justifyContent="center" spacing={2} direction={'column'}>
            <Typography variant="h5" fontWeight={'bold'}>
              {purchase?.invoiceNumber}
            </Typography>
            <Stack direction={'row'} spacing={2}>
              <TextField
                label="Invoice Date"
                size="small"
                type="date"
                {...getFieldProps('invoiceDate')}
                error={Boolean(touched.invoiceDate && errors.invoiceDate)}
                helperText={touched.invoiceDate && errors.invoiceDate}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Stack>
          </Stack>
          <IconButton
            sx={{
              height: 40,
              width: 40,
            }}
            onClick={() => {
              setEditMode(false);
              resetForm();
            }}
          >
            <Close />
          </IconButton>
        </Stack>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Stack px={5} pt={5}>
              <Typography variant="h6" fontWeight={'bold'} gutterBottom>
                From
              </Typography>
              <Stack paddingLeft={4}>
                <Typography>{purchase?.from?.name}</Typography>
                <Typography>{purchase?.from?.address}</Typography>
                <Typography>{purchase?.from?.pin}</Typography>
                <Typography>{purchase?.from?.phone}</Typography>
                <Typography>{purchase?.from?.email}</Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Stack px={5} pt={5}>
              <Typography variant="h6" fontWeight={'bold'} gutterBottom>
                To
              </Typography>
              <Stack paddingLeft={4}>
                <Typography>{purchase?.to?.fullname}</Typography>
                <Typography>{purchase?.to?.address}</Typography>
                <Typography>{purchase?.to?.phone}</Typography>
                <Typography>{purchase?.to?.email}</Typography>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
        <Grid container>
          <Grid
            item
            xs={12}
            margin={1}
            sx={{
              borderRadius: '5px',
            }}
          >
            <Stack p={5}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Quantity</TableCell>
                      <TableCell>Unit Price</TableCell>
                      <TableCell>Taxable Amount</TableCell>
                      <TableCell>Current Tax</TableCell>
                      <TableCell>Tax</TableCell>
                      <TableCell>Total</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {values.items.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <TextField
                            fullWidth
                            value={item.name || ''}
                            onChange={(e) => {
                              const newItems = [...values.items];
                              newItems[index] = { ...newItems[index], name: e.target.value };
                              formik.setFieldValue('items', newItems);
                            }}
                          />
                        </TableCell>
                        <TableCell sx={{ width: '120px' }}>
                          <TextField
                            fullWidth
                            type="number"
                            value={item.quantity || ''}
                            onChange={(e) => {
                              const newItems = [...values.items];
                              newItems[index] = {
                                ...newItems[index],
                                quantity: Number(e.target.value),
                                total: totalCalculator(Number(e.target.value), item.unitPrice, item.tax),
                              };
                              formik.setFieldValue('items', newItems);
                            }}
                          />
                        </TableCell>
                        <TableCell sx={{ width: '120px' }}>
                          <TextField
                            fullWidth
                            type="number"
                            value={item.unitPrice || ''}
                            onChange={(e) => {
                              const newItems = [...values.items];
                              newItems[index] = {
                                ...newItems[index],
                                unitPrice: Number(e.target.value),
                                total: totalCalculator(item.quantity, Number(e.target.value), item.tax),
                              };
                              formik.setFieldValue('items', newItems);
                            }}
                          />
                        </TableCell>
                        <TableCell sx={{ width: '120px' }}>
                          <Stack direction="column" spacing={1}>
                            {item.taxArray?.map((tax, index) => (
                              <Typography key={index}>{`${tax.name} (${tax.percentage}%)`}</Typography>
                            ))}
                          </Stack>
                        </TableCell>
                        <TableCell>
                          <Stack direction="row" spacing={5} alignItems="center">
                            <Autocomplete
                              multiple
                              sx={{
                                minWidth: '150px',
                                maxWidth: '200px',
                              }}
                              fullWidth
                              id="asynchronous-demo"
                              isOptionEqualToValue={(option, value) => option.name === value.name}
                              getOptionLabel={(option) => `${option.name} (${option.percentage}%)`}
                              getOptionSelected={(option, value) => option.name === value.name}
                              options={taxes}
                              onChange={(_, value) => {
                                if (value) {
                                  const newItems = [...values.items];
                                  newItems[index] = {
                                    ...newItems[index],
                                    tax: value.map((item) => item.percentage),
                                    taxIds: value.map((item) => item._id),
                                    total: totalCalculator(
                                      item.quantity,
                                      item.unitPrice,
                                      value.map((item) => item.percentage)
                                    ),
                                  };
                                  formik.setFieldValue('items', newItems);
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Tax"
                                  InputProps={{
                                    ...params.InputProps,
                                  }}
                                />
                              )}
                              renderOption={(props, option) => (
                                <li {...props}>
                                  <Stack direction={'column'}>
                                    <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1' }}>
                                      {option.name}
                                    </Typography>
                                    <Typography variant="caption" sx={{ lineHeight: '1' }}>
                                      {option.percentage} %
                                    </Typography>
                                  </Stack>
                                </li>
                              )}
                            />
                            <Typography>
                              &#8377;
                              {item.unitPrice &&
                                item.tax &&
                                item.quantity &&
                                taxCalculator(Number(item.unitPrice) * Number(item.quantity), item.tax)}
                            </Typography>
                          </Stack>
                        </TableCell>

                        <TableCell
                          sx={{
                            width: '120px',
                          }}
                        >
                          <TextField
                            fullWidth
                            type="number"
                            value={item.total || ''}
                            disabled
                            onChange={(e) => {
                              const newItems = [...values.items];
                              newItems[index] = {
                                ...newItems[index],
                                total: Number(e.target.value),
                              };
                              formik.setFieldValue('items', newItems);
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <IconButton
                            onClick={() => {
                              const newItems = [...values.items];
                              newItems.splice(index, 1);
                              formik.setFieldValue('items', newItems);
                            }}
                            variant="outlined"
                            color="error"
                          >
                            <DeleteForever />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Stack sx={{ width: '100%' }} justifyContent={'flex-end'} direction={'row'} mt={1}>
                <Button
                  variant="contained"
                  onClick={() => {
                    const newItems = [...values.items];
                    newItems.push({
                      name: '',
                      unitPrice: 0,
                      tax: [],
                      quantity: 0,
                      total: 0,
                    });
                    formik.setFieldValue('items', newItems);
                  }}
                >
                  Add Item
                </Button>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
        <Stack direction={'row'} paddingX={5} sx={{ width: '100%' }} justifyContent={'end'}>
          {/* <Box sx={{ flex: '1' }}></Box> */}
          <Stack justifyContent="center" spacing={2}>
            <Stack direction={'row'} spacing={1}>
              <TextField label="Sub Total" size="small" type="number" value={values.subTotal} />
            </Stack>
            <Stack direction={'row'} spacing={1}>
              <TextField
                label="Discount"
                size="small"
                type="number"
                {...getFieldProps('discount')}
                error={Boolean(touched.discount && errors.discount)}
                helperText={touched.discount && errors.discount}
              />
            </Stack>
            {/* <Stack direction={'row'} spacing={1}>
              <TextField label="Current Tax" size="small" sx={{ pointerEvents: 'none' }} value={currentTaxPrint} />
            </Stack> */}
            <Stack direction={'row'} spacing={1}>
              <TextField label="Grand Total" size="small" type="number" value={values.grandTotal} />
            </Stack>
          </Stack>
        </Stack>
        <Box p={5} sx={{ textAlign: 'right' }}>
          <Button type="submit" variant="contained" disabled={isSubmitting}>
            Submit
          </Button>
          <Button type="button" variant="contained" sx={{ marginLeft: 2 }} onClick={() => resetForm()}>
            Reset
          </Button>
        </Box>
      </Form>
    </FormikProvider>
  );
}

export default PurchaseEditForm;
