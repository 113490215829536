import { useState } from 'react';
// material
import { Container, Typography, Card, Tab, Box, Stack } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';

// components
import Page from '../components/Page';
import Ledgers from './Ledgers';
import TransactionCategories from '../components/TransactionCategories';
import TaxCategories from '../components/TaxCategories';
import MemberRoles from '../components/MemberRoles';
import CompanyAddress from '../components/CompanyAddress';
import NavigateBack from '../components/smallComponents/NavigateBack';

export default function GeneralsSettings() {
  const [value, setValue] = useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Page title="Generals Settings">
      <Container maxWidth="xl">
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="h4" gutterBottom>
              Generals Settings
            </Typography>
            <NavigateBack />
          </Stack>
          <Card>
            <Box sx={{ width: '100%', marginTop: '20px' }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList onChange={handleChange}>
                    <Tab label="Employee Roles" value="1" />
                    <Tab label="Company Address" value="2" />
                  </TabList>
                </Box>
                <TabPanel value="1">
                  <MemberRoles />
                </TabPanel>
                <TabPanel value="2">
                  <CompanyAddress />
                </TabPanel>
              </TabContext>
            </Box>
          </Card>
        </Container>
      </Page>
    </>
  );
}
