import { BorderColor, Close } from '@mui/icons-material';
import { Avatar, Box, Button, Chip, IconButton, Stack, TextField, Typography } from '@mui/material';
import axios from 'axios';
import moment from 'moment';
import React, { useState } from 'react';
import { toast } from 'react-toastify';

export default function LeadNoteCard({ note, getLead }) {
  const [edit, setEdit] = useState(false);
  const [editedNote, setEditedNote] = useState(note.note);

  const editNote = async () => {
    try {
      const res = await axios.put(`${process.env.REACT_APP_API_URL}/leadNote`, {
        id: note?._id,
        note: editedNote,
      });
      toast(res.data.message);
      setEdit(false);
      getLead();
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  };
  return (
    <Stack
      sx={{
        boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
        width: '100%',
        borderRadius: '5px',
        bgcolor: 'background.paper',
      }}
      direction="row"
      padding={2}
      alignItems="start"
    >
      <Box>
        <Avatar src={note?.noterData?.profilePic || 'https://www.w3schools.com/howto/img_avatar.png'} />
      </Box>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="start"
        paddingX={1}
        sx={{
          width: '100%',
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ width: '100%' }}>
          <Stack direction="column" alignItems="start" justifyContent="center" sx={{ width: '300px' }}>
            <Typography variant="subtitle1">{note?.noterData?.fullname?.toUpperCase()}</Typography>
            <Typography
              sx={{
                lineHeight: '1.2',
              }}
              variant="caption"
            >
              {note?.noterData?.accType?.replaceAll('_', ' ')}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="start" spacing={1} justifyContent="start" sx={{ width: '100%' }}>
            {note?.noterData?.role &&
              note?.noterData?.role.map((role, index) => <Chip label={role} key={index} size="small" />)}
          </Stack>
          <IconButton onClick={() => setEdit(!edit)}>{edit ? <Close /> : <BorderColor />}</IconButton>
        </Stack>
        {!edit ? (
          <Box sx={{ width: '100%', border: '5px solid #d0cfcf36', borderRadius: '5px' }} padding={1} marginY={1}>
            <Typography>{note?.note}</Typography>
          </Box>
        ) : (
          <Stack
            direction="column"
            spacing={2}
            sx={{
              width: '100%',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'end',
                alignItem: 'center',
              }}
            >
              <TextField
                sx={{ width: '100%' }}
                multiline
                placeholder="Add note here"
                variant="outlined"
                defaultChecked={note?.note}
                value={editedNote}
                onChange={(e) => setEditedNote(e.target.value)}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'end',
                alignItem: 'center',
              }}
            >
              <Button variant="contained" sx={{ mt: 1 }} onClick={editNote}>
                Update
              </Button>
            </Box>
          </Stack>
        )}

        {!edit && (
          <Stack direction="row" alignItems="center" spacing={5} justifyContent="flex-end" sx={{ width: '100%' }}>
            <Stack direction="row" alignItems="start" spacing={1} justifyContent="space-between">
              <Typography sx={{ fontWeight: 'bold', wordBreak: 'keep-all' }}>Created At:</Typography>
              <Typography>{moment(note?.createdAt).format('DD MMM YYYY')}</Typography>
            </Stack>
            <Stack direction="row" alignItems="start" spacing={1} justifyContent="space-between">
              <Typography sx={{ fontWeight: 'bold', wordBreak: 'keep-all' }}>Updated At:</Typography>
              <Typography>{moment(note?.updatedAt).format('DD MMM YYYY')}</Typography>
            </Stack>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}
