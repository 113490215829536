import { useState, useEffect } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import axios from 'axios';
import Moment from 'moment';
// material
import { styled } from '@mui/material/styles';
import {
  Card,
  Table,
  Stack,
  CircularProgress,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Icon,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  IconButton,
  OutlinedInput,
  InputAdornment,
} from '@mui/material';
import { Box } from '@mui/system';
// import CopyToClipboard from 'react-copy-to-clipboard';
import { ArrowBack, ContentCopy } from '@mui/icons-material';
import CopyToClipboard from 'react-copy-to-clipboard';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Filter from './filters/PendingBillingPayments';

// components
import Page from './Page';
import Scrollbar from './Scrollbar';
import Iconify from './Iconify';

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

export default function PendingBillingPayments() {
  const navigate = useNavigate();

  const [paymentsData, setPaymentsData] = useState();
  const [name, setName] = useState('');
  const [pages, setPages] = useState(0);
  const [paymentsCount, setPaymentsCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [size, setSize] = useState(10);
  const [options, setOptions] = useState({ size, page: 0 });

  const handlePageChange = (event, newPage) => {
    const temp = { ...options, page: newPage };
    setPages(newPage);
    setOptions(temp);
    setIsLoading(true);
    getpayments(temp);
  };

  const getpayments = (options) => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/payment/billing/pending/all`, {
        params: options,
      })
      .then((res) => {
        console.log('tele caller', res);
        if (res.data.isError === false) {
          setIsLoading(false);
          setPaymentsCount(res.data.data.maxRecords);
          setPaymentsData(res.data.data.records);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const getpaymentsWithoutLoading = (options) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/payment/billing/pending/all`, {
        params: options,
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setPaymentsCount(res.data.data.maxRecords);
          setPaymentsData(res.data.data.records);
          setIsLoading(false);
        } else {
          setPaymentsCount(0);
          setPaymentsData([]);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const onSearch = (e) => {
    e.preventDefault();
    setName(e.target.value);
    const temp = { ...options, page: 0, customerName: e.target.value };
    setOptions(temp);
    setPages(0);
    getpaymentsWithoutLoading(temp);
  };

  const applyFilters = (filter) => {
    console.log('filter', filter);
    const temp = { page: 0, size };
    if (filter.startDate) {
      temp.startDate = filter.startDate;
    }
    if (filter.endDate) {
      temp.endDate = filter.endDate;
    }
    if (filter.customerId) {
      temp.customerId = filter.customerId;
    }
    if (filter._id) {
      temp._id = filter._id;
    }
    if (filter.invoiceNumber) {
      temp.invoiceNumber = filter.invoiceNumber;
    }
    if (filter.txnId) {
      temp.txnId = filter.txnId;
    }

    setOptions(temp);
    setPages(0);
    getpayments(temp);
  };
  useEffect(() => {
    getpayments(options);
  }, []);

  useEffect(() => {
    const temp = { ...options, size, page: 0 };
    setPages(0);
    setOptions(temp);
    getpayments(temp);
  }, [size]);

  return (
    <>
      <Page title="payments">
        {isLoading ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '50vh',
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            <Container maxWidth="xl">
              <>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Stack direction="row" spacing={2} padding={2}>
                    <SearchStyle
                      value={name}
                      onChange={onSearch}
                      placeholder="Search Customer Name..."
                      startAdornment={
                        <InputAdornment position="start">
                          <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                        </InputAdornment>
                      }
                    />
                  </Stack>
                  <Stack padding={1} direction="row" justifyContent="flex-end" spacing={2} alignItems="center">
                    <Filter applyFilters={applyFilters} />
                  </Stack>
                </Stack>

                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Sl No</TableCell>
                          <TableCell>Customer ID</TableCell>
                          <TableCell sx={{ paddingLeft: 0 }}>Customer Name</TableCell>
                          <TableCell>Email</TableCell>
                          <TableCell>Phone</TableCell>
                          <TableCell>Pending Amount</TableCell>
                          <TableCell>Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {paymentsData?.map((item, id) => (
                          <TableRow key={id} sx={{ '&:hover': { backgroundColor: '#f5f5f5' } }}>
                            <TableCell>{pages * size + (id + 1)}</TableCell>

                            <TableCell style={{ cursor: 'pointer', width: '80px' }}>
                              <Stack direction="column" spacing={0.5} width="80px">
                                <CopyToClipboard text={item.customerId} onCopy={() => toast.info('Customer ID copied')}>
                                  <Stack direction="row" alignItems="center" spacing={0.5} justifyContent="flex-start">
                                    <ContentCopy color="primaryDark" />
                                    <Typography color="primaryDark.main" variant="p">
                                      Copy ID
                                    </Typography>
                                  </Stack>
                                </CopyToClipboard>
                              </Stack>
                            </TableCell>
                            <TableCell>{item.customerName}</TableCell>
                            <TableCell>{item.email}</TableCell>
                            <TableCell style={{ width: '80px' }}>{item.phone}</TableCell>
                            <TableCell>{item.pendingAmount}</TableCell>
                            <TableCell>
                              <Box>
                                <IconButton
                                  sx={{ color: 'gray' }}
                                  onClick={() =>
                                    navigate(
                                      `/dashboard/accounts/pending-payments/bill/view-payment/${item.customerId}`
                                    )
                                  }
                                >
                                  <RemoveRedEyeIcon />
                                </IconButton>
                              </Box>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Scrollbar>
                <TablePagination
                  rowsPerPageOptions={[10, 20, 30]}
                  onRowsPerPageChange={(e) => {
                    setSize(e.target.value);
                  }}
                  component={'div'}
                  count={paymentsCount}
                  showFirstButton
                  showLastButton
                  onPageChange={handlePageChange}
                  rowsPerPage={size}
                  page={pages}
                />
              </>
            </Container>
          </>
        )}
      </Page>
    </>
  );
}
