import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import axios from 'axios';
import { useFormik, Form, FormikProvider } from 'formik';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import avatar from '../../assets/avatar.png';

const AddQto = (props) => {
  const [employees, setEmployees] = useState([]);
  const [customers, setCustomers] = useState([]);
  const matches = useMediaQuery('(max-width:600px)');

  const { getQtos, open, setOpen, project } = props;
  const quotationSchema = Yup.object().shape({
    projectName: Yup.string().required('Project Name is required'),
    referenceNumber: Yup.string().optional(),
    qtoPreparedBy: Yup.string().required('QTO Prepared By is required'),
    qtoApprovedBy: Yup.string().required('QTO Approved By is required'),
    boqPreparedBy: Yup.string().required('BOQ Prepared By is required'),
    boqApprovedBy: Yup.string().required('BOQ Approved By is required'),
    quotationPreparedBy: Yup.string().required('Quotation Prepared By is required'),
    quotationApprovedBy: Yup.string().required('Quotation Approved By is required'),
  });

  const formik = useFormik({
    initialValues: {
      projectName: '',
      projectId: project?._id,
      referenceNumber: '',
      qtoPreparedBy: '',
      qtoApprovedBy: '',
      boqPreparedBy: '',
      boqApprovedBy: '',
      quotationPreparedBy: '',
      quotationApprovedBy: '',
    },
    validationSchema: quotationSchema,
    onSubmit: async () => {
      try {
        console.log(values);
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/quotation`, values);
        console.log(res.data);
        if (!res.data.isError) {
          setOpen(false);
          formik.resetForm();
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
        getQtos();
        resetForm();
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  const getEmployees = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/member/employee/active/all`);
      console.log(res.data.data);
      setEmployees(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getCustomers = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/customer/active`);
      console.log(res.data.data);
      setCustomers(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getEmployees();
    getCustomers();
  }, []);

  const handleClose = () => {
    setOpen(false);
    resetForm();
  };

  useEffect(() => {
    if (project && project?.name) {
      formik.setFieldValue('projectName', project.name);
      formik.setFieldValue('projectId', project._id);
    }
    console.log(project);
  }, [project]);

  return (
    <Dialog open={open} onClose={handleClose} fullScreen={matches}>
      <DialogTitle>{'Add QTO'}</DialogTitle>
      <Box paddingBottom={'10px'} width={{ xs: '100%', sm: '600px' }}>
        <DialogContent paddingTop="20px">
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    disabled
                    label="Project Name"
                    {...getFieldProps('projectName')}
                    error={Boolean(touched.projectName && errors.projectName)}
                    helperText={touched.projectName && errors.projectName}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Reference Number"
                    {...getFieldProps('referenceNumber')}
                    error={Boolean(touched.referenceNumber && errors.referenceNumber)}
                    helperText={touched.referenceNumber && errors.referenceNumber}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    fullWidth
                    id="asynchronous-demo"
                    isOptionEqualToValue={(option, value) => option.fullname === value.fullname}
                    getOptionLabel={(option) => option.fullname}
                    getOptionSelected={(option, value) => option.fullname === value.fullname}
                    options={employees}
                    onChange={(_, value) => {
                      if (value) {
                        formik.setFieldValue('qtoPreparedBy', value._id);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="QTO Prepared By"
                        InputProps={{
                          ...params.InputProps,
                        }}
                        error={Boolean(touched.qtoPreparedBy && errors.qtoPreparedBy)}
                        helperText={touched.qtoPreparedBy && errors.qtoPreparedBy}
                      />
                    )}
                    renderOption={(props, option) => (
                      <li {...props}>
                        <Stack direction="row" spacing={2} alignItems="center">
                          <Avatar
                            sx={{ width: 40, height: 40, cursor: 'pointer' }}
                            src={(option?.profilePic && process.env.REACT_APP_API_URL + option.profilePic) || avatar}
                          />
                          <Stack direction={'column'}>
                            <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1' }}>
                              {option.fullname.toUpperCase()}
                            </Typography>
                            <Typography variant="caption" sx={{ lineHeight: '1.1' }}>
                              {option.role[0]?.name?.replaceAll('_', ' ')}
                            </Typography>
                          </Stack>
                        </Stack>
                      </li>
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    fullWidth
                    id="asynchronous-demo"
                    isOptionEqualToValue={(option, value) => option.fullname === value.fullname}
                    getOptionLabel={(option) => option.fullname}
                    getOptionSelected={(option, value) => option.fullname === value.fullname}
                    options={employees}
                    onChange={(_, value) => {
                      if (value) {
                        formik.setFieldValue('qtoApprovedBy', value._id);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="QTO Approved By"
                        InputProps={{
                          ...params.InputProps,
                        }}
                        error={Boolean(touched.qtoApprovedBy && errors.qtoApprovedBy)}
                        helperText={touched.qtoApprovedBy && errors.qtoApprovedBy}
                      />
                    )}
                    renderOption={(props, option) => (
                      <li {...props}>
                        <Stack direction="row" spacing={2} alignItems="center">
                          <Avatar
                            sx={{ width: 40, height: 40, cursor: 'pointer' }}
                            src={(option?.profilePic && process.env.REACT_APP_API_URL + option.profilePic) || avatar}
                          />
                          <Stack direction={'column'}>
                            <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1' }}>
                              {option.fullname.toUpperCase()}
                            </Typography>
                            <Typography variant="caption" sx={{ lineHeight: '1.1' }}>
                              {option.role[0]?.name?.replaceAll('_', ' ')}
                            </Typography>
                          </Stack>
                        </Stack>
                      </li>
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    fullWidth
                    id="asynchronous-demo"
                    isOptionEqualToValue={(option, value) => option.fullname === value.fullname}
                    getOptionLabel={(option) => option.fullname}
                    getOptionSelected={(option, value) => option.fullname === value.fullname}
                    options={employees}
                    onChange={(_, value) => {
                      if (value) {
                        formik.setFieldValue('boqPreparedBy', value._id);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="BOQ Prepared By"
                        InputProps={{
                          ...params.InputProps,
                        }}
                        error={Boolean(touched.boqPreparedBy && errors.boqPreparedBy)}
                        helperText={touched.boqPreparedBy && errors.boqPreparedBy}
                      />
                    )}
                    renderOption={(props, option) => (
                      <li {...props}>
                        <Stack direction="row" spacing={2} alignItems="center">
                          <Avatar
                            sx={{ width: 40, height: 40, cursor: 'pointer' }}
                            src={(option?.profilePic && process.env.REACT_APP_API_URL + option.profilePic) || avatar}
                          />
                          <Stack direction={'column'}>
                            <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1' }}>
                              {option.fullname.toUpperCase()}
                            </Typography>
                            <Typography variant="caption" sx={{ lineHeight: '1.1' }}>
                              {option.role[0]?.name?.replaceAll('_', ' ')}
                            </Typography>
                          </Stack>
                        </Stack>
                      </li>
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    fullWidth
                    id="asynchronous-demo"
                    isOptionEqualToValue={(option, value) => option.fullname === value.fullname}
                    getOptionLabel={(option) => option.fullname}
                    getOptionSelected={(option, value) => option.fullname === value.fullname}
                    options={employees}
                    onChange={(_, value) => {
                      if (value) {
                        formik.setFieldValue('boqApprovedBy', value._id);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="BOQ Approved By"
                        InputProps={{
                          ...params.InputProps,
                        }}
                        error={Boolean(touched.boqApprovedBy && errors.boqApprovedBy)}
                        helperText={touched.boqApprovedBy && errors.boqApprovedBy}
                      />
                    )}
                    renderOption={(props, option) => (
                      <li {...props}>
                        <Stack direction="row" spacing={2} alignItems="center">
                          <Avatar
                            sx={{ width: 40, height: 40, cursor: 'pointer' }}
                            src={(option?.profilePic && process.env.REACT_APP_API_URL + option.profilePic) || avatar}
                          />
                          <Stack direction={'column'}>
                            <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1' }}>
                              {option.fullname.toUpperCase()}
                            </Typography>
                            <Typography variant="caption" sx={{ lineHeight: '1.1' }}>
                              {option.role[0]?.name?.replaceAll('_', ' ')}
                            </Typography>
                          </Stack>
                        </Stack>
                      </li>
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    fullWidth
                    id="asynchronous-demo"
                    isOptionEqualToValue={(option, value) => option.fullname === value.fullname}
                    getOptionLabel={(option) => option.fullname}
                    getOptionSelected={(option, value) => option.fullname === value.fullname}
                    options={employees}
                    onChange={(_, value) => {
                      if (value) {
                        formik.setFieldValue('quotationPreparedBy', value._id);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Quotation Prepared By"
                        InputProps={{
                          ...params.InputProps,
                        }}
                        error={Boolean(touched.quotationPreparedBy && errors.quotationPreparedBy)}
                        helperText={touched.quotationPreparedBy && errors.quotationPreparedBy}
                      />
                    )}
                    renderOption={(props, option) => (
                      <li {...props}>
                        <Stack direction="row" spacing={2} alignItems="center">
                          <Avatar
                            sx={{ width: 40, height: 40, cursor: 'pointer' }}
                            src={(option?.profilePic && process.env.REACT_APP_API_URL + option.profilePic) || avatar}
                          />
                          <Stack direction={'column'}>
                            <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1' }}>
                              {option.fullname.toUpperCase()}
                            </Typography>
                            <Typography variant="caption" sx={{ lineHeight: '1.1' }}>
                              {option.role[0]?.name?.replaceAll('_', ' ')}
                            </Typography>
                          </Stack>
                        </Stack>
                      </li>
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    fullWidth
                    id="asynchronous-demo"
                    isOptionEqualToValue={(option, value) => option.fullname === value.fullname}
                    getOptionLabel={(option) => option.fullname}
                    getOptionSelected={(option, value) => option.fullname === value.fullname}
                    options={employees}
                    onChange={(_, value) => {
                      if (value) {
                        formik.setFieldValue('quotationApprovedBy', value._id);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Quotation Approved By"
                        InputProps={{
                          ...params.InputProps,
                        }}
                        error={Boolean(touched.quotationApprovedBy && errors.quotationApprovedBy)}
                        helperText={touched.quotationApprovedBy && errors.quotationApprovedBy}
                      />
                    )}
                    renderOption={(props, option) => (
                      <li {...props}>
                        <Stack direction="row" spacing={2} alignItems="center">
                          <Avatar
                            sx={{ width: 40, height: 40, cursor: 'pointer' }}
                            src={(option?.profilePic && process.env.REACT_APP_API_URL + option.profilePic) || avatar}
                          />
                          <Stack direction={'column'}>
                            <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1' }}>
                              {option.fullname.toUpperCase()}
                            </Typography>
                            <Typography variant="caption" sx={{ lineHeight: '1.1' }}>
                              {option.role[0]?.name?.replaceAll('_', ' ')}
                            </Typography>
                          </Stack>
                        </Stack>
                      </li>
                    )}
                  />
                </Grid>
              </Grid>

              <Stack direction={'row'} justifyContent="flex-end" paddingTop="20px">
                <Button onClick={handleClose}>Cancel</Button>
                <LoadingButton color="primaryDark" type="submit" loading={isSubmitting}>
                  {'Submit'}
                </LoadingButton>
              </Stack>
            </Form>
          </FormikProvider>
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default AddQto;
