import { Box, Card, CircularProgress, Container, Grid, Stack, Typography } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Iconify from '../components/Iconify';
import InvoiceTable from '../components/InvoiceTable';
import Page from '../components/Page';

function DetailItem({ icon, title, data, background, sx, onClick }) {
  return (
    <Stack
      alignItems="center"
      spacing={1}
      direction="row"
      sx={{ height: '100%', background: '#f0f0f0', minHeight: '65px', borderRadius: '5px', ...sx }}
      onClick={onClick}
    >
      <Stack
        direction="row"
        alignItems="center"
        sx={{ height: '100%', borderRadius: '5px 0px 0px 5px' }}
        background={background}
        padding={1}
      >
        <Iconify icon={icon} style={{ height: '25px', width: '25px' }} />
      </Stack>

      <Stack direction="column" sx={{ padding: 1 }}>
        <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>{title}</Typography>
        <Typography style={{ wordBreak: 'break-all' }}>{data}</Typography>
      </Stack>
    </Stack>
  );
}

function ViewPendingBillPayment() {
  const { customerId } = useParams();
  const location = useLocation();
  const invoice = location?.state && location?.state?.invoice;
  const [pendingBill, setPendingBill] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const getPendingBill = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/payment/billing/pending`, {
        params: { customerId, invoiceNumber: invoice ? invoice.invoiceNumber : '' },
      });
      console.log(res.data.data, 'pending bill');
      if (!res.data.isError) {
        setPendingBill(res.data.data);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPendingBill();
  }, []);

  return (
    <Page title="Pending Bill">
      <Container maxWidth="xl">
        {isLoading ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItem: 'center',
              height: '50vh',
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <Stack spacing={2}>
            <h2> Pending Bill</h2>
            <Card>
              <Grid container spacing={2} padding={2}>
                <Grid item xs={12} md={6} lg={4}>
                  <DetailItem icon="mdi:account" title="Customer Name" data={pendingBill.fullname} />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <DetailItem icon="mdi:email" title="Email" data={pendingBill.email} />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <DetailItem icon="mdi:phone" title="Phone" data={pendingBill.phone} />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <DetailItem icon="mdi:currency-inr" title="Grand Total" data={pendingBill.grandTotal} />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <DetailItem icon="mdi:currency-inr" title="Paid Amount" data={pendingBill.paidAmount} />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <DetailItem icon="mdi:currency-inr" title="Balance Amount" data={pendingBill.balanceAmount} />
                </Grid>
              </Grid>
            </Card>
            <InvoiceTable bill={pendingBill} />
          </Stack>
        )}
      </Container>
    </Page>
  );
}

export default ViewPendingBillPayment;
