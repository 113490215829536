import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Grid, MenuItem, Stack, TextField, Typography, Autocomplete, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

export default function PaymentFormSalary({ invoices, salary, totalAmount, error }) {
  const navigate = useNavigate();
  const [ledgers, setLedgers] = useState([]);
  const paymentSchema = Yup.object().shape({
    employeeId: Yup.string().required('Vendor is required'),
    paymentDate: Yup.string().required('Payment Date is required'),
    paymentMode: Yup.string().required('Payment Reference is required'),
  });

  const formik = useFormik({
    initialValues: {
      employeeId: salary.employeeId,
      amount: totalAmount,
      ledgerId: '',
      paymentDate: '',
      paymentReference: '',
      paymentMode: '',
      remarks: '',
      invoices,
    },
    validationSchema: paymentSchema,
    onSubmit: async () => {
      try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/payment/salary`, values);
        toast(res.data.message);
        navigate(-1);
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  const getLedgers = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/ledger/all/active`);
      console.log(res.data.data, 'ledgers');
      if (!res.data.isError) {
        setLedgers(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    formik.setFieldValue('amount', totalAmount);
    formik.setFieldValue('invoices', invoices);
  }, [salary, invoices, totalAmount]);

  useEffect(() => {
    getLedgers();
  }, []);

  return (
    <Box sx={{ width: '100%' }} paddingY={2}>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                label="Amount Payable"
                disabled
                fullWidth
                InputLabelProps={{ shrink: true }}
                sx={{ width: '100%' }}
                {...getFieldProps('amount')}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Autocomplete
                fullWidth
                id="asynchronous-demo"
                isOptionEqualToValue={(option, value) => option.name === value.name}
                getOptionLabel={(option) => option.name}
                getOptionSelected={(option, value) => option.name === value.name}
                options={ledgers}
                onChange={(_, value) => {
                  if (value) {
                    formik.setFieldValue('ledgerId', value._id);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Ledgers"
                    InputProps={{
                      ...params.InputProps,
                    }}
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props}>
                    <Stack direction={'column'}>
                      <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1' }}>
                        {option.name}
                      </Typography>
                      <Typography variant="caption" sx={{ lineHeight: '1' }}>
                        {option.accountHolderName}
                      </Typography>
                    </Stack>
                  </li>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                select
                label="Payment Mode"
                fullWidth
                sx={{ width: '100%' }}
                {...getFieldProps('paymentMode')}
                error={Boolean(touched.paymentMode && errors.paymentMode)}
                helperText={touched.paymentMode && errors.paymentMode}
              >
                <MenuItem value="UPI">UPI</MenuItem>
                <MenuItem value="NEFT">NEFT</MenuItem>
                <MenuItem value="RTGS">RTGS</MenuItem>
                <MenuItem value="IMPS">IMPS</MenuItem>
                <MenuItem value="CASH">CASH</MenuItem>
                <MenuItem value="CHEQUE">CHEQUE</MenuItem>
                <MenuItem value="DD">DD</MenuItem>
                <MenuItem value="CARD">CARD</MenuItem>
                <MenuItem value="OTHER">OTHER</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                type="date"
                label="Payment Date"
                fullWidth
                InputLabelProps={{ shrink: true }}
                sx={{ width: '100%' }}
                {...getFieldProps('paymentDate')}
                error={Boolean(touched.paymentDate && errors.paymentDate)}
                helperText={touched.paymentDate && errors.paymentDate}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <TextField
                label="Payment Reference"
                fullWidth
                sx={{ width: '100%' }}
                {...getFieldProps('paymentReference')}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <TextField label="Remarks" fullWidth sx={{ width: '100%' }} {...getFieldProps('Remarks')} />
            </Grid>

            <Grid item xs={12}>
              <Stack direction="row" sx={{ width: '100%' }} justifyContent="flex-end" spacing={2}>
                {!!totalAmount && values.ledgerId && (
                  <Typography>
                    {`₹ ${totalAmount} will be Debited from ${
                      ledgers.find((ledger) => ledger._id === values.ledgerId)?.name
                    }`}
                  </Typography>
                )}
                <LoadingButton variant="contained" type="submit" loading={isSubmitting} disabled={error}>
                  Submit
                </LoadingButton>
              </Stack>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>
    </Box>
  );
}
