import { ArrowBack, Delete } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardHeader,
  CircularProgress,
  Container,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import LeadNotes from '../components/LeadNotes';
import LeadBasicInfo from '../components/LeadBasicInfo';
import Page from '../components/Page';
import Popup1 from '../components/popups/LeadDelete';

export default function ViewLead() {
  const { accType, role } = useSelector((state) => state.user.userInfo);
  const admin = accType === 'SUPER_ADMIN' || accType === 'SUB_ADMIN';
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [lead, setLead] = useState({});
  const [selectedIndexDelete, setSelectedIndexDelete] = useState(false);

  const handleDeleteLead = () => {
    setSelectedIndexDelete(true);
  };

  const handleCloseDelete = (refresh = false, message = '') => {
    setSelectedIndexDelete(false);
    if (refresh) {
      navigate('/dashboard/crm/lead');
    }
    if (message) {
      toast(message);
    }
  };

  const getLead = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/lead`, {
        params: {
          id,
        },
      });
      console.log('lead', res);
      setLead(res.data.data.lead);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getLead();
  }, []);

  return (
    <Page title="View Lead">
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItem: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Container maxWidth="xl">
          <>
            <Stack paddingTop={1} paddingX={2} direction="row" justifyContent="space-between" alignItems="center">
              <Typography variant="h5">Lead</Typography>
              <Stack direction="row" spacing={1} alignItems="center">
                {admin && (
                  <Button
                    size="small"
                    sx={{ height: '32px' }}
                    color="error"
                    variant="contained"
                    onClick={() => handleDeleteLead()}
                    startIcon={<Delete />}
                  >
                    Delete
                  </Button>
                )}
                {selectedIndexDelete && <Popup1 item={lead} handleCloseDelete={handleCloseDelete} />}
                <IconButton size="large" onClick={() => navigate(-1)}>
                  <ArrowBack sx={{ color: 'primaryDark.main', width: '32px', height: '32px' }} />
                </IconButton>
              </Stack>
            </Stack>
            <Box paddingX={{ xl: 2, lg: 2, md: 2, sm: 1, xs: 0.5 }} paddingY={1}>
              <Box id="basic-info" marginBottom={1}>
                <LeadBasicInfo lead={lead} getLead={getLead} />
              </Box>
            </Box>
            <Box paddingX={{ xl: 2, lg: 2, md: 2, sm: 1, xs: 0.5 }} paddingY={1}>
              <Box id="lead-notes" marginBottom={1}>
                <LeadNotes getLead={getLead} />
              </Box>
            </Box>
          </>
        </Container>
      )}
    </Page>
  );
}
