import React, { useState } from 'react';
import { Box, Card, Container, Stack, Tab, Typography } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Page from '../components/Page';
import TrialBalance from '../components/TrialBalance';
import TradingProfitLoss from '../components/TradingProfitLoss';
import BalanceSheet from '../components/BalanceSheet';

export default function Reports() {
  const [value, setValue] = useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Page title="Reports">
      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h4" gutterBottom>
            Reports
          </Typography>
        </Stack>
        <Card>
          <Box sx={{ width: '100%', marginTop: '20px' }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChange}>
                  <Tab label="Trial Balance" value="1" />
                  <Tab label="Trading And Profit & Loss" value="2" />
                  <Tab label="Balance Sheet" value="3" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <TrialBalance />
              </TabPanel>
              <TabPanel value="2">
                <TradingProfitLoss />
              </TabPanel>
              <TabPanel value="3">
                <BalanceSheet />
              </TabPanel>
            </TabContext>
          </Box>
        </Card>
      </Container>
    </Page>
  );
}
