import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  TextField,
  useMediaQuery,
} from '@mui/material';
import axios from 'axios';
import { useFormik, Form, FormikProvider } from 'formik';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

const AddorEditSalaryModal = (props) => {
  const [employees, setEmployees] = useState([]);
  const [open3, setOpen3] = useState(false);
  const [sourcesLoading, setSourcesLoading] = useState(true);
  const matches = useMediaQuery('(max-width:600px)');

  const { open, setOpen, mode, setMode, selectedSalary, setSelectedSalary, getSalaries } = props;
  const salarySchema = Yup.object().shape({
    employeeId: Yup.string().required('Employee is required'),
    salaryDate: Yup.string().required('Salary date is required'),
    amount: Yup.string().required('Amount is required'),
  });

  const formik = useFormik({
    initialValues: {
      employeeId: mode === 'edit' ? selectedSalary?.employeeId : '',
      salaryDate: mode === 'edit' ? moment(selectedSalary?.salaryDate).format('YYYY-MM-DD') : '',
      amount: mode === 'edit' ? selectedSalary?.amount : '',
      notes: mode === 'edit' ? selectedSalary?.notes : '',
    },
    validationSchema: salarySchema,
    onSubmit: async () => {
      try {
        let res;
        if (mode === 'edit') {
          res = await axios.put(`${process.env.REACT_APP_API_URL}/salary`, {
            ...values,
            _id: selectedSalary._id,
          });
        } else {
          res = await axios.post(`${process.env.REACT_APP_API_URL}/salary`, values);
        }
        if (!res.data.isError) {
          setOpen(false);
          formik.resetForm();
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
        setSelectedSalary(null);
        setMode('add');
        getSalaries();
        resetForm();
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  const getEmployees = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/member/employee/active/all`);
      console.log(res.data.data);
      setEmployees(res.data.data);
      setSourcesLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getEmployees();
  }, []);

  const handleClose = () => {
    setOpen(false);
    setMode('add');
    setSelectedSalary(null);
    resetForm();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullScreen={matches}>
      <DialogTitle>{mode === 'add' ? 'Add Salary' : 'Edit Salary'}</DialogTitle>
      <Box paddingBottom={'10px'} width={{ xs: '100%', sm: '600px' }}>
        <DialogContent paddingTop="20px">
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                {mode === 'add' && (
                  <Grid item xs={12}>
                    <Autocomplete
                      id="asynchronous-demo"
                      fullWidth
                      open={open3}
                      onOpen={() => {
                        setOpen3(true);
                      }}
                      onClose={() => {
                        setOpen3(false);
                      }}
                      isOptionEqualToValue={(option, value) => option.fullname === value.fullname}
                      getOptionLabel={(option) => option.fullname}
                      options={employees}
                      loading={sourcesLoading}
                      onChange={(_, value) => {
                        if (value) {
                          formik.setFieldValue('employeeId', value._id);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Employee"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {sourcesLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  </Grid>
                )}
                {mode === 'edit' && (
                  <Grid item xs={12}>
                    <TextField fullWidth disabled label="Employee" value={selectedSalary?.fullname} />
                  </Grid>
                )}
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    label="Salary Date"
                    {...getFieldProps('salaryDate')}
                    error={Boolean(touched.salaryDate && errors.salaryDate)}
                    helperText={touched.salaryDate && errors.salaryDate}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Amount"
                    {...getFieldProps('amount')}
                    error={Boolean(touched.amount && errors.amount)}
                    helperText={touched.amount && errors.amount}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField fullWidth multiline label="Notes" {...getFieldProps('notes')} />
                </Grid>
              </Grid>

              <Stack direction={'row'} justifyContent="flex-end" paddingTop="20px">
                <Button onClick={handleClose}>Cancel</Button>
                <LoadingButton color="primaryDark" type="submit" loading={isSubmitting}>
                  {mode === 'add' ? 'Add' : 'Update'}
                </LoadingButton>
              </Stack>
            </Form>
          </FormikProvider>
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default AddorEditSalaryModal;
