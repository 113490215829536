import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import axios from 'axios';
import moment from 'moment';
// material
import { styled } from '@mui/material/styles';
import {
  Card,
  Table,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  CircularProgress,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  FormControl,
  Select,
  Icon,
  Container,
  Typography,
  MenuItem,
  TableContainer,
  TablePagination,
  TextField,
  Grid,
  IconButton,
  Toolbar,
  OutlinedInput,
  InputAdornment,
  useMediaQuery,
  Avatar,
  chip,
  Paper,
  CardContent,
} from '@mui/material';
import { Box } from '@mui/system';
import { ContentCopy } from '@mui/icons-material';
import CopyToClipboard from 'react-copy-to-clipboard';
import { LoadingButton } from '@mui/lab';
import { useFormik, Form, FormikProvider } from 'formik';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import AddIcon from '@mui/icons-material/Add';
import ModeEditSharpIcon from '@mui/icons-material/ModeEditSharp';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import Filter from '../components/filters/CompanyAddress';

// components

function AddCompanyAddress(props) {
  const matches = useMediaQuery('(max-width:600px)');
  const { companyAddress } = props;
  const formik = useFormik({
    initialValues: {
      companyName: companyAddress.companyName,
      address: companyAddress.address,
      pin: companyAddress.pin,
      phone: companyAddress.phone,
      email: companyAddress.email,
    },
    onSubmit: async () => {
      try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/utils/companyAddress`, values);
        if (!res.data.isError) {
          props.closeAddCompanyAddressModal(res.data.message);
          formik.resetForm();
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
        props.getCompanyAddress();
        resetForm();
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  return (
    <Dialog open={props.open} onClose={props.closeAddCompanyAddressModal} fullScreen={matches}>
      <DialogTitle>Add Company Address</DialogTitle>
      <Box paddingBottom={'10px'} width={{ xs: '100%', sm: '600px' }}>
        <DialogContent paddingTop="20px">
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField fullWidth label="Company Name" {...getFieldProps('companyName')} />
                </Grid>
                <Grid item xs={12}>
                  <TextField fullWidth multiline label="Address" {...getFieldProps('address')} />
                </Grid>

                <Grid item xs={6} md={4}>
                  <TextField fullWidth multiline label="Pin" {...getFieldProps('pin')} />
                </Grid>
                <Grid item xs={12} md={8}>
                  <TextField fullWidth multiline label="Phone" {...getFieldProps('phone')} />
                </Grid>
                <Grid item xs={12}>
                  <TextField fullWidth multiline label="Email" {...getFieldProps('email')} />
                </Grid>
              </Grid>
              <Stack direction={'row'} justifyContent="flex-end" paddingTop="20px">
                <Button onClick={props.closeAddCompanyAddressModal}>Cancel</Button>
                <LoadingButton type="submit" loading={isSubmitting}>
                  Add Company Address
                </LoadingButton>
              </Stack>
            </Form>
          </FormikProvider>
        </DialogContent>
      </Box>
    </Dialog>
  );
}

function CompanyAddress() {
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [companyAddress, setCompanyAddress] = useState({});
  const keyOrder = ['companyName', 'address', 'pin', 'phone', 'email'];
  const keyLabels = {
    companyName: 'Company Name',
    address: 'Address',
    pin: 'PIN',
    phone: 'Phone',
    email: 'Email',
  };

  const [addressExist, setAddressExist] = useState(false);

  const openAddCompanyAddressModal = () => {
    setOpen(true);
  };
  const closeAddCompanyAddressModal = () => {
    setOpen(false);
  };

  const getCompanyAddress = () => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/utils/companyAddress`)
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setIsLoading(false);
          setCompanyAddress(res.data.data.company);
          if (res.data.data.company) {
            setAddressExist(true);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getCompanyAddress();
  }, []);

  return (
    <>
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <AddCompanyAddress
            open={open}
            closeAddCompanyAddressModal={closeAddCompanyAddressModal}
            getCompanyAddress={getCompanyAddress}
            companyAddress={companyAddress}
          />
          <Container maxWidth="xl">
            <Stack direction="row" alignItems="center" justifyContent="end" sx={{ marginBottom: 1 }}>
              <Button
                startIcon={addressExist ? <ModeEditSharpIcon /> : <AddIcon />}
                variant="contained"
                onClick={openAddCompanyAddressModal}
              >
                {addressExist ? 'Edit Company Address' : 'Add Company Address'}
              </Button>
            </Stack>
            <Card>
              <CardContent>
                <Grid container spacing={2} component={Paper} sx={{ p: 2 }}>
                  {keyOrder.map((key) => (
                    <Grid key={key} item xs={6}>
                      <Typography variant="body1" fontWeight="bold">
                        {keyLabels[key]}:
                      </Typography>
                      <Typography variant="body1" color="text.secondary">
                        {companyAddress[key]}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              </CardContent>
            </Card>
          </Container>
        </>
      )}
    </>
  );
}

export default CompanyAddress;
