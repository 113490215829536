import * as React from 'react';
import Menu from '@mui/material/Menu';
import { Formik, Form } from 'formik';
import { IconButton, TextField, Typography, Button, Stack, Box, MenuItem } from '@mui/material';
import Iconify from '../Iconify';

const Filter = ({ toggleFilter, applyFilters, employeeId }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div>
        <Box style={{ display: 'flex', justifyContent: 'end' }}>
          <IconButton onClick={handleClick}>
            <Iconify icon="ic:round-filter-list" />
            <Typography variant="h6"> Filter</Typography>
          </IconButton>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <Formik
              initialValues={{
                _id: '',
                employeeId: '',
                txnId: '',
                phone: '',
                email: '',
                startDate: '',
                endDate: '',
              }}
              onSubmit={async (values) => {
                applyFilters(values);
              }}
            >
              {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                <Form style={{ padding: '10px' }}>
                  <Stack direction="column" spacing={1} sx={{ width: '350px' }}>
                    <Stack direction="row" spacing={1}>
                      <TextField
                        fullWidth
                        id="_id"
                        name="_id"
                        label="Salary ID"
                        value={values._id}
                        onChange={handleChange}
                      />
                      {!employeeId && (
                        <TextField
                          id="employeeId"
                          name="employeeId"
                          fullWidth
                          label="Employee ID"
                          value={values.employeeId}
                          onChange={handleChange}
                        />
                      )}
                    </Stack>
                    <Stack direction="row" spacing={1}>
                      <TextField
                        id="txnId"
                        name="Transaction ID"
                        fullWidth
                        label="Transaction ID"
                        value={values.txnId}
                        onChange={handleChange}
                      />
                    </Stack>
                    <Stack direction="row" spacing={1}>
                      <TextField
                        id="phone"
                        name="phone"
                        fullWidth
                        label="Phone"
                        value={values.phone}
                        onChange={handleChange}
                      />
                      <TextField
                        id="email"
                        name="email"
                        fullWidth
                        label="Email"
                        value={values.email}
                        onChange={handleChange}
                      />
                    </Stack>
                    <Stack direction="row" spacing={1}>
                      <TextField
                        type="date"
                        id="startDate"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        name="startDate"
                        fullWidth
                        label="Start Date"
                        value={values.startDate}
                        onChange={handleChange}
                      />
                      <TextField
                        type="date"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        id="endDate"
                        name="endDate"
                        fullWidth
                        label="Start Date"
                        value={values.endDate}
                        onChange={handleChange}
                      />
                    </Stack>
                    <Button variant="contained" type="submit" style={{ marginTop: '10px' }}>
                      Filter
                    </Button>
                  </Stack>
                </Form>
              )}
            </Formik>
          </Menu>
        </Box>
      </div>
    </>
  );
};

export default Filter;
