import { useState, useEffect } from 'react';
import * as Yup from 'yup';
import axios from 'axios';
// material
import { styled } from '@mui/material/styles';
import {
  Card,
  Table,
  Stack,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  CircularProgress,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  FormControl,
  Select,
  MenuItem,
  TableContainer,
  TablePagination,
  TextField,
  Grid,
  IconButton,
  OutlinedInput,
  InputAdornment,
  useMediaQuery,
} from '@mui/material';
import { Box } from '@mui/system';
import { LoadingButton } from '@mui/lab';
import { useFormik, Form, FormikProvider } from 'formik';
import AddIcon from '@mui/icons-material/Add';
import { Edit } from '@mui/icons-material';
import { toast } from 'react-toastify';
// import Filter from '../components/filters/LeadCategories';

// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import Popup from '../components/popups/LeadCategoryCategoryChange';

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

export default function LeadCategories() {
  const matches = useMediaQuery('(max-width:600px)');
  const [leadCategoryData, setAdminData] = useState();
  const [name, setName] = useState('');
  const [pages, setPages] = useState(0);
  const [leadCategoryCount, setAdminCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState();
  const [size, setSize] = useState(10);
  const [options, setOptions] = useState({ size, page: 0 });
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [input, setInput] = useState(1);
  const [selecedLeadCategory, setSelecedLeadCategory] = useState({});

  const leadCategorySchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema: leadCategorySchema,
    onSubmit: async () => {
      try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/leadCategory`, values);
        if (!res.data.isError) {
          closeAddCategoryModal(res.data.message);
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
        getLeadCategory(options);
        resetForm();
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  const editLeadCategory = async () => {
    try {
      const res = await axios.put(`${process.env.REACT_APP_API_URL}/leadCategory`, {
        id: selecedLeadCategory._id,
        name: selecedLeadCategory.name,
      });
      if (!res.data.isError) {
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
      closeEditCategoryModal();
      setSelecedLeadCategory({});
      getLeadCategory(options);
    } catch (error) {
      console.log(error);
      closeEditCategoryModal(error.response.data.message);
    }
  };

  const handleCategoryChange = (event, item, index) => {
    console.log('item', item);
    if (`${item.isBlocked}` === event.target.value) {
      return;
    }
    console.log(index);
    setSelectedIndex(index);
  };

  const handleClose = (refresh = false, message = '') => {
    setSelectedIndex();
    if (refresh) {
      getLeadCategory(options);
    }
    if (message) {
      toast(message);
    }
  };
  const openAddCategoryModal = () => {
    setOpen(true);
  };
  const closeAddCategoryModal = () => {
    setOpen(false);
    formik.resetForm();
  };

  const handlePageChange = (event, newPage) => {
    const temp = { ...options, page: newPage };
    setPages(newPage);
    setOptions(temp);
    setIsLoading(true);
    getLeadCategory(temp);
  };

  const handleUpdateLeadCategory = (e, item) => {
    setSelecedLeadCategory(item);
    setOpen1(true);
  };

  const closeEditCategoryModal = () => {
    setOpen1(false);
    setSelecedLeadCategory({});
  };

  const getLeadCategory = (options) => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/leadCategory/all`, {
        params: options,
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setIsLoading(false);
          setAdminCount(res.data.data.maxRecords);
          setAdminData(res.data.data.records);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const getLeadCategoryWithoutLoading = (options) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/leadCategory/leadCategory/all`, {
        params: options,
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setAdminCount(res.data.data.maxRecords);
          setAdminData(res.data.data.records);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const onSearch = (e) => {
    e.preventDefault();
    setName(e.target.value);
    const temp = { ...options, page: 0, name: e.target.value };
    setOptions(temp);
    setPages(0);
    getLeadCategoryWithoutLoading(temp);
  };

  const applyFilters = (filter) => {
    console.log('filter', filter);
    const temp = { page: 0, size };
    // if (filter.name.length) {
    //   temp.name = filter.name;
    // }
    if (filter.isBlocked.length) {
      temp.isBlocked = filter.isBlocked.trim();
    }
    if (filter.isPopular.length) {
      temp.isPopular = filter.isPopular;
    }

    setOptions(temp);
    setPages(0);
    getLeadCategory(temp);
  };
  useEffect(() => {
    getLeadCategory(options);
  }, []);

  useEffect(() => {
    const temp = { ...options, size, page: 0 };
    setPages(0);
    setOptions(temp);
    getLeadCategory(temp);
  }, [size]);

  return (
    <>
      <Page title="Lead Categories">
        {isLoading ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '50vh',
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            {/* Add leadCategory Dialog */}
            <Dialog open={open} onClose={closeAddCategoryModal} fullScreen={matches}>
              <DialogTitle>Add Lead Category</DialogTitle>
              <Box paddingBottom={'10px'} width={{ xs: '100%', sm: '600px' }}>
                <DialogContent paddingTop="20px">
                  <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            label="Lead Category Name"
                            {...getFieldProps('name')}
                            error={Boolean(touched.name && errors.name)}
                            helperText={touched.name && errors.name}
                          />
                        </Grid>
                      </Grid>
                      <Stack direction={'row'} justifyContent="flex-end" paddingTop="20px">
                        <Button onClick={closeAddCategoryModal}>Cancel</Button>
                        <LoadingButton type="submit" loading={isSubmitting}>
                          Add Lead Category
                        </LoadingButton>
                      </Stack>
                    </Form>
                  </FormikProvider>
                </DialogContent>
              </Box>
            </Dialog>
            {/* Add leadCategory dialog end */}

            {/* Edit leadCategory Dialog  */}
            <Dialog open={open1} onClose={closeEditCategoryModal} fullScreen={matches}>
              <DialogTitle>Edit Lead Category</DialogTitle>
              <Box paddingBottom={'10px'} width={{ xs: '100%', sm: '600px' }}>
                <DialogContent paddingTop="20px">
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Lead Category Name"
                        value={selecedLeadCategory.name}
                        onChange={(e) => setSelecedLeadCategory({ ...selecedLeadCategory, name: e.target.value })}
                      />
                    </Grid>
                  </Grid>
                  <Stack direction={'row'} justifyContent="flex-end" paddingTop="20px">
                    <Button color="primaryDark" onClick={closeEditCategoryModal}>
                      Cancel
                    </Button>
                    <LoadingButton color="primaryDark" onClick={editLeadCategory}>
                      Edit Lead Category
                    </LoadingButton>
                  </Stack>
                </DialogContent>
              </Box>
            </Dialog>
            {/* Edit leadCategory dialog end */}
            <>
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Stack direction="row" spacing={2} padding={2}>
                  {/* <SearchStyle
                    value={name}
                    onChange={onSearch}
                    placeholder="Search leadCategory..."
                    startAdornment={
                      <InputAdornment position="start">
                        <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                      </InputAdornment>
                    }
                  /> */}
                </Stack>
                <Stack padding={1} direction="row" justifyContent="flex-end" spacing={2} alignItems="center">
                  <Button startIcon={<AddIcon />} variant="contained" onClick={openAddCategoryModal}>
                    Add Lead Category
                  </Button>

                  {/* <Filter applyFilters={applyFilters} /> */}
                </Stack>
              </Stack>

              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Sl No</TableCell>
                        <TableCell>Category Name</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {leadCategoryData?.map((item, id) => (
                        <TableRow key={id} sx={{ '&:hover': { backgroundColor: '#f5f5f5' } }}>
                          <TableCell>{pages * size + (id + 1)}</TableCell>
                          <TableCell>{item?.name}</TableCell>
                          <TableCell>
                            <FormControl sx={{ minWidth: 100 }}>
                              <Select
                                size="small"
                                value={item.isBlocked}
                                onChange={(e) => handleCategoryChange(e, item, id)}
                              >
                                <MenuItem value={'false'}>🟢 Active</MenuItem>
                                <MenuItem value={'true'}>🔴 Blocked</MenuItem>
                              </Select>
                              {selectedIndex === id && <Popup item={item} handleClose={handleClose} />}
                            </FormControl>
                          </TableCell>
                          <TableCell>
                            <Box>
                              <IconButton onClick={(e) => handleUpdateLeadCategory(e, item)}>
                                <Edit />
                              </IconButton>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>
              <TablePagination
                rowsPerPageOptions={[10, 20, 30]}
                onRowsPerPageChange={(e) => {
                  setSize(e.target.value);
                }}
                component={'div'}
                count={leadCategoryCount}
                showFirstButton
                showLastButton
                onPageChange={handlePageChange}
                rowsPerPage={size}
                page={pages}
              />
            </>
          </>
        )}
      </Page>
    </>
  );
}
