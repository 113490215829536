import * as React from 'react';
import Menu from '@mui/material/Menu';
import { Formik, Form } from 'formik';
import {
  IconButton,
  TextField,
  Typography,
  Button,
  Stack,
  Box,
  MenuItem,
  Autocomplete,
  CircularProgress,
} from '@mui/material';
import { useState, useEffect } from 'react';
import axios from 'axios';
import Iconify from '../Iconify';

const Filter = ({ toggleFilter, applyFilters }) => {
  const [transactionTypes, setTransactionTypes] = useState([]);
  const [sourcesLoading, setSourcesLoading] = useState(true);
  const [open3, setOpen3] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [employees, setEmployees] = useState([]);
  const [ledgers, setLedgers] = useState([]);
  const [vendors, setVendors] = useState([]);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getTransactionTypes = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/transactionType/active`);
      console.log(res.data.data, 'transaction types');
      setTransactionTypes(res.data.data);
      setSourcesLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getEmployees = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/member/employee/active/all`);
      console.log(res.data.data);
      setEmployees(res.data.data);
      setSourcesLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getLedgers = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/ledger/all/active`);
      console.log(res.data.data, 'ledgers');
      if (!res.data.isError) {
        setLedgers(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getVendors = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/vendor/active`);
      console.log(res.data.data, 'vendors');
      setVendors(res.data.data);
      setSourcesLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getTransactionTypes();
    getEmployees();
    getLedgers();
    getVendors();
  }, []);

  return (
    <>
      <div>
        <Box style={{ display: 'flex', justifyContent: 'end' }}>
          <IconButton onClick={handleClick}>
            <Iconify icon="ic:round-filter-list" />
            <Typography variant="h6"> Filter</Typography>
          </IconButton>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <Formik
              initialValues={{
                _id: '',
                transactionName: '',
                transactionType: '',
                ledgerId: '',
                salaryId: '',
                paymentReference: '',
                remarks: '',
                paymentStartDate: '',
                paymentEndDate: '',
                txnStartDate: '',
                txnEndDate: '',
                paymentStatus: '',
                paymentMode: '',
              }}
              onSubmit={async (values) => {
                applyFilters(values);
              }}
            >
              {({ getFieldProps }) => (
                <Form style={{ padding: '10px' }}>
                  <Stack direction="column" spacing={1} sx={{ width: '400px' }}>
                    <Stack direction="row" spacing={1}>
                      <TextField
                        fullWidth
                        id="transactionName"
                        name="transactionName"
                        label="Transaction Name"
                        {...getFieldProps('transactionName')}
                      />
                      <TextField
                        fullWidth
                        select
                        id="transactionType"
                        name="transactionType"
                        label="Transaction Type"
                        {...getFieldProps('transactionType')}
                      >
                        <MenuItem value="">All</MenuItem>
                        <MenuItem value="CREDIT">Credit</MenuItem>
                        <MenuItem value="DEBIT">Debit</MenuItem>
                      </TextField>
                    </Stack>
                    <Stack direction="row" spacing={1}>
                      <TextField
                        fullWidth
                        id="paymentReference"
                        name="paymentReference"
                        label="Payment Reference"
                        {...getFieldProps('paymentReference')}
                      />
                      <TextField fullWidth id="remarks" name="remarks" label="Remarks" {...getFieldProps('remarks')} />
                    </Stack>
                    <Stack direction="row" spacing={1}>
                      <TextField
                        select
                        label="Payment Mode"
                        fullWidth
                        sx={{ width: '100%' }}
                        {...getFieldProps('paymentMode')}
                      >
                        <MenuItem value="UPI">UPI</MenuItem>
                        <MenuItem value="NEFT">NEFT</MenuItem>
                        <MenuItem value="RTGS">RTGS</MenuItem>
                        <MenuItem value="IMPS">IMPS</MenuItem>
                        <MenuItem value="CASH">CASH</MenuItem>
                        <MenuItem value="CHEQUE">CHEQUE</MenuItem>
                        <MenuItem value="DD">DD</MenuItem>
                        <MenuItem value="CARD">CARD</MenuItem>
                        <MenuItem value="OTHER">OTHER</MenuItem>
                      </TextField>
                      <TextField
                        select
                        fullWidth
                        id="paymentStatus"
                        name="paymentStatus"
                        label="Payment Status"
                        {...getFieldProps('paymentStatus')}
                      >
                        <MenuItem value="">All</MenuItem>
                        <MenuItem value="PENDING">Pending</MenuItem>
                        <MenuItem value="COMPLETED">Completed</MenuItem>
                      </TextField>
                    </Stack>

                    <Stack direction="row" spacing={1}>
                      <TextField
                        fullWidth
                        id="salaryId"
                        name="salaryId"
                        label="Salary ID"
                        {...getFieldProps('salaryId')}
                      />
                      <TextField
                        fullWidth
                        id="ledgerId"
                        name="ledgerId"
                        label="Ledger ID"
                        {...getFieldProps('ledgerId')}
                      />
                    </Stack>
                    <Stack direction="row" spacing={1}>
                      <TextField fullWidth id="_id" name="_id" label="Transaction ID" {...getFieldProps('_id')} />
                    </Stack>
                    <Stack direction="row" spacing={1}>
                      <TextField
                        type="date"
                        id="txnStartDate"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        name="txnStartDate"
                        fullWidth
                        label="Txn Start Date"
                        {...getFieldProps('txnStartDate')}
                      />
                      <TextField
                        type="date"
                        id="txnEndDate"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        name="txnEndDate"
                        fullWidth
                        label="Txn End Date"
                        {...getFieldProps('txnEndDate')}
                      />
                    </Stack>
                    <Stack direction="row" spacing={1}>
                      <TextField
                        type="date"
                        id="paymentStartDate"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        name="paymentStartDate"
                        fullWidth
                        label="Payment Start Date"
                        {...getFieldProps('paymentStartDate')}
                      />
                      <TextField
                        type="date"
                        id="paymentEndDate"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        name="paymentEndDate"
                        fullWidth
                        label="Payment End Date"
                        {...getFieldProps('paymentEndDate')}
                      />
                    </Stack>

                    <Button variant="contained" type="submit" style={{ marginTop: '10px' }}>
                      Filter
                    </Button>
                  </Stack>
                </Form>
              )}
            </Formik>
          </Menu>
        </Box>
      </div>
    </>
  );
};

export default Filter;
