import { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import axios from 'axios';
import Moment from 'moment';
// material
import { styled } from '@mui/material/styles';
import {
  Card,
  Table,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  CircularProgress,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  FormControl,
  Select,
  Icon,
  Container,
  Typography,
  MenuItem,
  TableContainer,
  TablePagination,
  TextField,
  Grid,
  IconButton,
  Toolbar,
  OutlinedInput,
  InputAdornment,
  useMediaQuery,
  Autocomplete,
  Box,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik, Form, FormikProvider } from 'formik';
import { toast } from 'react-toastify';

export default function CustomerEditForm({ customer, getCustomer, setEditMode, editMode }) {
  const [customerManagers, setCustomerManagers] = useState([]);
  const [telecallers, setTelecallers] = useState([]);
  const [sources, setSources] = useState([]);
  const [categories, setCategories] = useState([]);
  const [customerManagersLoading, setCustomerManagersLoading] = useState(true);
  const [telecallersLoading, setTelecallersLoading] = useState(false);
  const [sourcesLoading, setSourcesLoading] = useState(true);
  const [categoriesLoading, setCategoriesLoading] = useState(true);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState({});
  const [status, setStatus] = useState([]);
  const [clearTelecaller, setClearTelecaller] = useState(false);

  const formik = useFormik({
    initialValues: {
      id: customer?._id,
      fullname: customer?.fullname,
      email: customer?.email,
      phone: customer?.phone,
      address: customer?.address,
    },
    onSubmit: async () => {
      try {
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/customer`, values);
        if (!res.data.isError) {
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
        setEditMode(false);
        getCustomer();
        resetForm();
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm, setFieldValue } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Fullname"
              {...getFieldProps('fullname')}
              error={Boolean(touched.fullname && errors.fullname)}
              helperText={touched.fullname && errors.fullname}
              disabled={!editMode}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Email"
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
              disabled={!editMode}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Phone"
              {...getFieldProps('phone')}
              error={Boolean(touched.phone && errors.phone)}
              helperText={touched.phone && errors.phone}
              disabled={!editMode}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              label="Address"
              {...getFieldProps('address')}
              error={Boolean(touched.address && errors.address)}
              helperText={touched.address && errors.address}
              disabled={!editMode}
            />
          </Grid>
        </Grid>
        {editMode && (
          <Stack direction={'row'} justifyContent="flex-end" spacing={1} paddingTop="20px">
            <Button variant="contained" onClick={() => setEditMode(false)}>
              Cancel
            </Button>
            <LoadingButton variant="contained" type="submit" loading={isSubmitting}>
              Update
            </LoadingButton>
          </Stack>
        )}
      </Form>
    </FormikProvider>
  );
}
