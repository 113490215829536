import moment from 'moment';
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  Container,
  Grid,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import axios from 'axios';
import { Attachment, BorderColor, DeleteForever, Edit } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Page from '../components/Page';
import PurchaseEditForm from '../components/PurchaseEditForm';
import PaymentsTable from '../components/PaymentsTable';

const ViewPurchase = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [editMode, setEditMode] = useState(false);
  const [purchase, setPurchase] = useState({});

  const getPurchase = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/purchase`, { params: { invoiceNumber: id } });
      console.log(res.data.data, 'purchase');
      setPurchase(res.data.data.invoice);
    } catch (error) {
      console.log(error);
    }
  };

  const downloadDoc = async (e, name) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/purchase/attachment-download`, {
        params: {
          file: name,
        },
      });
      window.open(res.data.data.url);
      console.log(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPurchase();
  }, []);

  return (
    <Page title="View Purchase">
      <Container maxWidth="xl">
        <Stack spacing={2}>
          <Card>
            <Box sx={{ padding: '10px' }}>
              {editMode && (
                <>
                  <PurchaseEditForm purchase={purchase} setEditMode={setEditMode} />
                </>
              )}
              {!editMode && (
                <>
                  <Stack
                    direction={'row'}
                    px={5}
                    py={3}
                    sx={{ backgroundColor: '#fabb0071' }}
                    justifyContent={'space-between'}
                  >
                    {/* <Box sx={{ flex: '1' }}>Logo</Box> */}

                    <Stack direction={'row'} spacing={1} alignItems={'center'}>
                      <Stack>
                        <Typography variant="h5" fontWeight={'bold'}>
                          {purchase?.invoiceNumber}
                        </Typography>
                        {purchase?.billNumber && (
                          <Stack direction="row" spacing={1}>
                            <Typography variant="body2" fontWeight={'bold'}>
                              Bill Number:
                            </Typography>
                            <Typography variant="body2">{purchase?.billNumber}</Typography>
                          </Stack>
                        )}
                        <Stack direction={'row'} spacing={1}>
                          <Typography variant="body2" fontWeight={'bold'}>
                            Date:
                          </Typography>
                          <Typography variant="body2">{moment(purchase?.invoiceDate).format('DD/MM/YYYY')}</Typography>
                        </Stack>
                      </Stack>
                    </Stack>

                    <IconButton
                      onClick={() => setEditMode(true)}
                      sx={{
                        height: 40,
                        width: 40,
                      }}
                    >
                      <BorderColor />
                    </IconButton>
                  </Stack>
                  <Grid container>
                    <Grid item xs={12} sm={6}>
                      <Stack px={5} pt={5}>
                        <Typography variant="h6" fontWeight={'bold'} gutterBottom>
                          From
                        </Typography>
                        <Stack paddingLeft={4}>
                          <Typography>{purchase?.to?.fullname}</Typography>
                          <Typography>{purchase?.to?.address}</Typography>
                          <Typography>{purchase?.to?.phone}</Typography>
                          <Typography>{purchase?.to?.email}</Typography>
                        </Stack>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack px={5} pt={5}>
                        <Typography variant="h6" fontWeight={'bold'} gutterBottom>
                          To
                        </Typography>
                        <Stack paddingLeft={4}>
                          <Typography>{purchase?.from?.name}</Typography>
                          <Typography>{purchase?.from?.address}</Typography>
                          <Typography>{purchase?.from?.pin}</Typography>
                          <Typography>{purchase?.from?.phone}</Typography>
                          <Typography>{purchase?.from?.email}</Typography>
                        </Stack>
                      </Stack>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      margin={1}
                      sx={{
                        borderRadius: '5px',
                      }}
                    >
                      <Stack p={5}>
                        <TableContainer component={Paper}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>Account Description</TableCell>
                                <TableCell>Account</TableCell>
                                <TableCell>Unit Price</TableCell>
                                <TableCell>Quantity</TableCell>
                                <TableCell>Taxable Amount</TableCell>
                                <TableCell>Tax</TableCell>
                                <TableCell>Total</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {purchase?.items?.map((item, index) => (
                                <TableRow key={index}>
                                  <TableCell>{item.accountDescription}</TableCell>
                                  <TableCell>{item.account?.name}</TableCell>
                                  <TableCell>{item.unitPrice}</TableCell>
                                  <TableCell>{item.quantity}</TableCell>
                                  <TableCell>{Number(item.unitPrice) * Number(item.quantity)}</TableCell>
                                  <TableCell>
                                    <Stack direction={'column'} spacing={1}>
                                      {item.tax.map((tax, key) => (
                                        <Typography variant="body2" key={key}>
                                          {tax.name} ({tax.percentage}%) : ₹{Number(tax.amount) * Number(item.quantity)}
                                        </Typography>
                                      ))}
                                    </Stack>
                                  </TableCell>
                                  <TableCell>{item.total}</TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Stack>
                    </Grid>
                  </Grid>
                  <Stack direction={'row'} pb={5} justifyContent={'end'}>
                    <Stack justifyContent="center" spacing={2} sx={{ width: '400px', paddingRight: '200px' }}>
                      <Stack direction={'row'} spacing={1} sx={{ width: '100%' }} justifyContent={'space-between'}>
                        <Typography variant="body2" fontWeight={'bold'}>
                          Sub Total:
                        </Typography>
                        <Typography variant="body2">{purchase?.subTotal}</Typography>
                      </Stack>
                      <Stack direction={'row'} spacing={1} sx={{ width: '100%' }} justifyContent={'space-between'}>
                        <Typography variant="body2" fontWeight={'bold'}>
                          Grand Total:
                        </Typography>
                        <Typography variant="body2">{purchase?.grandTotal}</Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                  {/* terms and  conditions */}
                  <Stack justifyContent="center" alignItems={'center'} spacing={2} sx={{ width: '100%' }}>
                    {purchase.termsAndConditions && (
                      <Typography variant="body2" sx={{ width: '80%' }} textAlign="center" fontWeight={'bold'}>
                        Terms and Conditions
                      </Typography>
                    )}
                    <Typography sx={{ width: '80%' }} variant="body2">
                      {purchase?.termsAndConditions}
                    </Typography>
                  </Stack>
                  <Stack spacing={1} sx={{ width: '100%' }} paddingX={2}>
                    <Stack direction={'row'} alignItems="center">
                      <Attachment />
                      <Typography variant="h6" fontWeight={'bold'}>
                        Attachments
                      </Typography>
                    </Stack>
                    <Stack direction={'row'} spacing={1}>
                      {console.log(purchase?.attachments)}
                      {purchase?.attachments?.map((attachment, index) => (
                        <Button
                          key={index}
                          variant="contained"
                          color="primary"
                          onClick={(e) => downloadDoc(e, attachment.fileUrl)}
                        >
                          {attachment.originalName}
                        </Button>
                      ))}
                    </Stack>
                  </Stack>
                  {!purchase.isPaymentCompleted && (
                    <Stack direction={'row'} p={2} justifyContent={'end'} sx={{ width: '100%' }}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() =>
                          navigate(`/dashboard/accounts/pending-payments/purchase/view-payment/${purchase.vendorId}?`, {
                            state: { invoice: purchase },
                          })
                        } 
                      >
                        Make Payment
                      </Button>
                    </Stack>
                  )}
                </>
              )}
            </Box>
          </Card>
          {purchase?.transactions && !!purchase.transactions.length && (
            <PaymentsTable transactions={purchase?.transactions} />
          )}
        </Stack>
      </Container>
    </Page>
  );
};

export default ViewPurchase;
