import { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import axios from 'axios';
import Moment from 'moment';
// material
import { styled } from '@mui/material/styles';
import {
  Card,
  Table,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  CircularProgress,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  FormControl,
  Select,
  Icon,
  Container,
  Typography,
  MenuItem,
  TableContainer,
  TablePagination,
  TextField,
  Grid,
  IconButton,
  Toolbar,
  OutlinedInput,
  InputAdornment,
  useMediaQuery,
  Autocomplete,
  Box,
  Avatar,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik, Form, FormikProvider } from 'formik';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import avatar from '../assets/avatar.png';

export default function ProjectEditForm({ project, getProject, setEditMode, editMode }) {
  const { accType, role } = useSelector((state) => state.user.userInfo);
  const admin = accType === 'SUPER_ADMIN' || accType === 'SUB_ADMIN';
  const isProjectManager = role && role.length && role.some((r) => r.name === 'LEAD_MANAGER');
  const isTelecaller = role && role.length && role.some((r) => r.name === 'TELE_CALLER');
  const [projectManagers, setProjectManagers] = useState([]);
  const [engineers, setEngineers] = useState([]);
  const [managers, setManagers] = useState([]);

  const formik = useFormik({
    initialValues: {
      id: project?._id,
      name: project?.name,
      location: project?.location,
      manager: project?.manager?._id,
      engineers: project?.engineers?.map((e) => e._id),
    },
    onSubmit: async () => {
      try {
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/project`, values);
        if (!res.data.isError) {
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
        setEditMode(false);
        getProject();
        resetForm();
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm, setFieldValue } = formik;

  const getEngineers = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/member/employee/active/all`, {
        params: {
          role: 'PROJECT_ENGINEER',
        },
      });
      console.log(res.data.data);
      setEngineers(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getManagers = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/member/employee/active/all`, {
        params: {
          role: 'PROJECT_MANAGER',
        },
      });
      console.log(res.data.data);
      setManagers(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getManagers();
    getEngineers();
  }, []);

  return ( 
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Project Name"
              {...getFieldProps('name')}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
              disabled={!editMode}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Location"
              {...getFieldProps('location')}
              error={Boolean(touched.location && errors.location)}
              helperText={touched.location && errors.location}
              disabled={!editMode}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            {editMode && (
              <Grid item xs={12}>
                <Autocomplete
                  fullWidth
                  id="asynchronous-demo"
                  defaultValue={managers.find((m) => m._id === project.manager._id)}
                  isOptionEqualToValue={(option, value) => option.fullname === value.fullname}
                  getOptionLabel={(option) => option.fullname}
                  getOptionSelected={(option, value) => option.fullname === value.fullname}
                  options={managers}
                  onChange={(_, value) => {
                    if (value) {
                      formik.setFieldValue('manager', value._id);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Project Manager"
                      InputProps={{
                        ...params.InputProps,
                      }}
                      error={Boolean(touched.manager && errors.manager)}
                      helperText={touched.manager && errors.manager}
                    />
                  )}
                  renderOption={(props, option) => (
                    <li {...props}>
                      <Stack direction="row" spacing={2} alignItems="center">
                        <Avatar
                          sx={{ width: 40, height: 40, cursor: 'pointer' }}
                          src={(option?.profilePic && process.env.REACT_APP_API_URL + option.profilePic) || avatar}
                        />
                        <Stack direction={'column'}>
                          <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1' }}>
                            {option.fullname.toUpperCase()}
                          </Typography>
                          <Typography variant="caption" sx={{ lineHeight: '1.1' }}>
                            {option.role[0]?.name?.replaceAll('_', ' ')}
                          </Typography>
                        </Stack>
                      </Stack>
                    </li>
                  )}
                />
              </Grid>
            )}
            {!editMode && <TextField fullWidth label="Project Manager" value={project.manager.fullname} disabled />}
          </Grid>
          <Grid item xs={12} md={6}>
            {editMode && (
              <Autocomplete
                multiple
                fullWidth
                id="asynchronous-demo"
                defaultValue={engineers.filter((e) => project.engineers.some((p) => p._id === e._id))}
                isOptionEqualToValue={(option, value) => option.fullname === value.fullname}
                getOptionLabel={(option) => option.fullname}
                getOptionSelected={(option, value) => option.fullname === value.fullname}
                options={engineers}
                onChange={(_, value) => {
                  if (value) {
                    formik.setFieldValue(
                      'engineers',
                      value.map((v) => v._id)
                    );
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Engineers"
                    InputProps={{
                      ...params.InputProps,
                    }}
                    error={Boolean(touched.engineers && errors.engineers)}
                    helperText={touched.engineers && errors.engineers}
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props}>
                    <Stack direction="row" spacing={2} alignItems="center">
                      <Avatar
                        sx={{ width: 40, height: 40, cursor: 'pointer' }}
                        src={(option?.profilePic && process.env.REACT_APP_API_URL + option.profilePic) || avatar}
                      />
                      <Stack direction={'column'}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1' }}>
                          {option.fullname.toUpperCase()}
                        </Typography>
                        <Typography variant="caption" sx={{ lineHeight: '1.1' }}>
                          {option.role[0]?.name?.replaceAll('_', ' ')}
                        </Typography>
                      </Stack>
                    </Stack>
                  </li>
                )}
              />
            )}
            {!editMode && (
              <TextField
                fullWidth
                label="Engineers"
                value={project.engineers.map((e) => e.fullname).join(', ')}
                disabled
              />
            )}
          </Grid>
        </Grid>
        {editMode && (
          <Stack direction={'row'} justifyContent="flex-end" spacing={1} paddingTop="20px">
            <Button variant="contained" onClick={() => setEditMode(false)}>
              Cancel
            </Button>
            <LoadingButton variant="contained" type="submit" loading={isSubmitting}>
              Update
            </LoadingButton>
          </Stack>
        )}
      </Form>
    </FormikProvider>
  );
}
