import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Grid, IconButton, Stack, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';

import { toast } from 'react-toastify';
import { Close } from '@mui/icons-material';

function EditSalary({ salary, setEditMode, getSalary }) {
  const salarySchema = Yup.object().shape({
    salaryDate: Yup.date().required('Salary Date is required'),
    amount: Yup.number().required('Amount is required'),
  });

  const formik = useFormik({
    initialValues: {
      _id: salary._id,
      salaryDate: moment(salary.salaryDate).format('YYYY-MM-DD'),
      amount: salary.amount,
      notes: salary.notes,
    },
    validationSchema: salarySchema,
    onSubmit: async () => {
      try {
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/salary`, values);
        toast(res.data.message);
        resetForm();
        setEditMode(false);
        getSalary();
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;
  return (
    <>
      <Stack direction={'row'} px={2} py={2} sx={{ backgroundColor: '#fabb0071' }} justifyContent={'space-between'} alignItems={"center"}>
        <Typography variant="h5" fontWeight={'bold'}>
          Salary
        </Typography>
        <IconButton onClick={() => setEditMode(!true)}>
          <Close />
        </IconButton>
      </Stack>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Grid container spacing={2} paddingX={2} paddingY={2}>
            <Grid item xs={6} sm={6} md={4} lg={3}>
              <TextField
                type="date"
                label="Salary Date"
                InputLabelProps={{ shrink: true }}
                fullWidth
                sx={{ width: '100%' }}
                {...getFieldProps('salaryDate')}
                error={Boolean(touched.salaryDate && errors.salaryDate)}
                helperText={touched.salaryDate && errors.salaryDate}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={3}>
              <TextField
                type="text"
                label="Amount"
                fullWidth
                sx={{ width: '100%' }}
                {...getFieldProps('amount')}
                error={Boolean(touched.amount && errors.amount)}
                helperText={touched.amount && errors.amount}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                label="Notes"
                multiline
                fullWidth
                sx={{ width: '100%' }}
                {...getFieldProps('notes')}
                error={Boolean(touched.notes && errors.notes)}
                helperText={touched.notes && errors.notes}
              />
            </Grid>
            <Grid item xs={12}>
              <Stack direction="row" sx={{ width: '100%' }} justifyContent="flex-end">
                <LoadingButton variant="contained" type="submit" loading={isSubmitting}>
                  Submit
                </LoadingButton>
              </Stack>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>
    </>
  );
}

export default EditSalary;
