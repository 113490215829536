import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Card,
  Container,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Page from '../components/Page';
import Iconify from '../components/Iconify';

function DetailItem({ icon, title, data, background, sx, onClick, profilePic }) {
  return (
    <Stack
      alignItems="center"
      spacing={1}
      direction="row"
      sx={{ height: '100%', background: '#f0f0f0', minHeight: '65px', borderRadius: '5px', ...sx }}
      onClick={onClick}
    >
      <Stack
        direction="row"
        alignItems="center"
        sx={{ height: '100%', borderRadius: '5px 0px 0px 5px' }}
        background={background}
        padding={profilePic ? 0 : 1}
      >
        {profilePic && <Avatar src={profilePic} alt={title} style={{ height: '40px', width: '40px' }} />}
        {!profilePic && <Iconify icon={icon} style={{ height: '25px', width: '25px' }} />}
      </Stack>

      <Stack direction="column" sx={{ padding: 1 }}>
        <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>{title}</Typography>
        <Typography style={{ wordBreak: 'break-all' }}>{data}</Typography>
      </Stack>
    </Stack>
  );
}

function ViewTransaction() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [transaction, setTransaction] = useState();

  const getTransaction = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/transaction`, { params: { _id: id } });
      console.log(res);
      if (res.data.isError === false) {
        setTransaction(res.data.data.record);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getTransaction();
  }, []);

  return (
    <Page title="View Transaction">
      <Container maxWidth="xl">
        <Typography variant="h4">View Transaction</Typography>
        <Stack spacing={2}>
          <Card>
            <Box padding={'10px'}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4} lg={3}>
                  <DetailItem title="Transaction ID" icon="eva:calendar-fill" data={transaction?._id} />
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <DetailItem
                    title="Transaction Date"
                    icon="eva:calendar-fill"
                    data={moment(transaction?.createdAt).format('DD/MM/YYYY')}
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <DetailItem title="Amount" icon="mdi:currency-inr" data={transaction?.amount} />
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <DetailItem
                    icon="eva:calendar-fill"
                    title="Transaction Name"
                    data={transaction?.transactionName?.replaceAll('_', ' ')}
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <DetailItem
                    title="Invoice Number"
                    icon="eva:person-fill"
                    data={transaction?.customer?.invoiceNumber}
                    onClick={() =>
                      navigate(`/dashboard/accounts/bill/view-bill/${transaction?.customer?.invoiceNumber}`)
                    }
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <DetailItem title="Transaction Type" icon="eva:book-fill" data={transaction?.transactionType} />
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <DetailItem title="Payment Mode" icon="eva:book-fill" data={transaction?.paymentMode} />
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <DetailItem
                    title="Payment Date"
                    icon="eva:calendar-fill"
                    data={moment(transaction?.paymentDate).format('DD/MM/YYYY')}
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <DetailItem title="Payment Reference" icon="eva:book-fill" data={transaction?.paymentReference} />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <DetailItem title="Remarks" icon="eva:book-fill" data={transaction?.remarks} />
                </Grid>
              </Grid>
            </Box>
          </Card>
          <>
            <Box padding={'10px'}>
              <Grid container spacing={2}>
                {transaction?.ledgerId && (
                  <Grid item xs={12} md={12} lg={6}>
                    <Card>
                      <Accordion defaultExpanded>
                        <AccordionSummary expandIcon={<ExpandMore />} sx={{ background: '#f0f0f0' }}>
                          <Typography variant="subtitle1">Ledger Details</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={12} lg={6}>
                              <DetailItem
                                title="Name "
                                // icon="eva:person-fill"
                                data={transaction?.ledgerId?.name}
                                sx={{ background: '#fff', cursor: 'pointer' }}
                                onClick={() =>
                                  navigate(`/dashboard/accounts/payments?ledgerId=${transaction?.ledgerId?._id}`)
                                }
                              />
                            </Grid>
                            <Grid item xs={12} md={12} lg={6}>
                              <DetailItem
                                title="Account Holder Name"
                                // icon="eva:email-fill"
                                data={transaction?.ledgerId?.accountHolderName}
                                sx={{ background: '#fff' }}
                              />
                            </Grid>
                            <Grid item xs={12} md={12} lg={6}>
                              <DetailItem
                                title="Account Number"
                                // icon="eva:email-fill"
                                data={transaction?.ledgerId?.accountNumber}
                                sx={{ background: '#fff' }}
                              />
                            </Grid>
                            <Grid item xs={12} md={12} lg={6}>
                              <DetailItem
                                title="IFSC Code"
                                // icon="eva:phone-fill"
                                data={transaction?.ledgerId?.ifsc}
                                sx={{ background: '#fff' }}
                              />
                            </Grid>
                            <Grid item xs={12} md={12} lg={6}>
                              <DetailItem
                                title="Ledger ID"
                                // icon="eva:id-card-fill"
                                data={transaction?.ledgerId?._id}
                                sx={{ background: '#fff', cursor: 'pointer' }}
                                onClick={() =>
                                  navigate(`/dashboard/accounts/payments?ledgerId=${transaction?.ledgerId?._id}`)
                                }
                              />
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Card>
                  </Grid>
                )}
                {transaction?.employee && (
                  <Grid item xs={12} md={12} lg={6}>
                    <Card>
                      <Accordion defaultExpanded>
                        <AccordionSummary expandIcon={<ExpandMore />} sx={{ background: '#f0f0f0' }}>
                          <Typography variant="subtitle1">Employee Details</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={12} lg={6}>
                              <DetailItem
                                title="Name"
                                // icon="eva:person-fill"
                                profilePic={
                                  (transaction?.employee?.id?.profilePic &&
                                    process.env.REACT_APP_API_URL + transaction?.employee?.id?.profilePic) ||
                                  transaction?.employee?.id?.fullname
                                }
                                data={transaction?.employee?.id?.fullname}
                                sx={{ background: '#fff', cursor: 'pointer' }}
                                onClick={() =>
                                  navigate(`/dashboard/general/employees/view/${transaction?.employee?.id?._id}`)
                                }
                              />
                            </Grid>
                            <Grid item xs={12} md={12} lg={6}>
                              <DetailItem
                                title="Email"
                                // icon="eva:email-fill"
                                data={transaction?.employee?.id?.email}
                                sx={{ background: '#fff' }}
                              />
                            </Grid>
                            <Grid item xs={12} md={12} lg={6}>
                              <DetailItem
                                title="Phone"
                                // icon="eva:phone-fill"
                                data={transaction?.employee?.id?.phone}
                                sx={{ background: '#fff' }}
                              />
                            </Grid>
                            <Grid item xs={12} md={12} lg={6}>
                              <DetailItem
                                title="Role"
                                // icon="eva:person-fill"
                                data={transaction?.employee?.id?.role[0]?.name?.replaceAll('_', ' ')}
                                sx={{ background: '#fff' }}
                              />
                            </Grid>
                            <Grid item xs={12} md={12} lg={6}>
                              <DetailItem
                                title="Employee ID"
                                // icon="eva:id-card-fill"
                                data={transaction?.employee?.id?._id}
                                sx={{ background: '#fff' }}
                              />
                            </Grid>
                            <Grid item xs={12} md={12} lg={6}>
                              <DetailItem
                                title="Salary ID"
                                // icon="eva:id-card-fill"
                                data={transaction?.employee?.salaryId}
                                sx={{ background: '#fff', cursor: 'pointer' }}
                                onClick={() =>
                                  navigate(`/dashboard/accounts/salary/view-salary/${transaction?.employee?.salaryId}`)
                                }
                              />
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Card>
                  </Grid>
                )}
                {transaction?.customer && (
                  <Grid item xs={12} md={12} lg={6}>
                    <Card>
                      {' '}
                      <Accordion defaultExpanded>
                        <AccordionSummary expandIcon={<ExpandMore />} sx={{ background: '#f0f0f0' }}>
                          <Typography variant="subtitle1">Customer Details</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={12} lg={6}>
                              <DetailItem
                                title="Full Name"
                                profilePic={transaction?.customer?.id?.profilePic}
                                // icon="eva:person-fill"
                                data={transaction?.customer?.id?.fullname}
                                sx={{ background: '#fff', cursor: 'pointer' }}
                                onClick={() =>
                                  navigate(`/dashboard/general/customers/view/${transaction?.customer?.id?._id}`)
                                }
                              />
                            </Grid>
                            <Grid item xs={12} md={12} lg={6}>
                              <DetailItem
                                title="Email"
                                // icon="eva:email-fill"
                                data={transaction?.customer?.id?.email}
                                sx={{ background: '#fff' }}
                              />
                            </Grid>
                            <Grid item xs={12} md={12} lg={6}>
                              <DetailItem
                                title="Phone"
                                // icon="eva:phone-fill"
                                data={transaction?.customer?.id?.phone}
                                sx={{ background: '#fff' }}
                              />
                            </Grid>

                            <Grid item xs={12} md={12} lg={6}>
                              <DetailItem
                                title="Billing ID"
                                // icon="eva:person-fill"
                                data={transaction?.customer?.billingId}
                                sx={{ background: '#fff' }}
                              />
                            </Grid>
                            <Grid item xs={12} md={12} lg={6}>
                              <DetailItem
                                title="Customer ID"
                                // icon="eva:id-card-fill"
                                data={transaction?.customer?.id?._id}
                                sx={{ background: '#fff' }}
                              />
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Card>
                  </Grid>
                )}
                {transaction?.vendor && (
                  <Grid item xs={12} md={12} lg={6}>
                    <Card>
                      <Accordion defaultExpanded>
                        <AccordionSummary expandIcon={<ExpandMore />} sx={{ background: '#f0f0f0' }}>
                          <Typography variant="subtitle1">Vendor Details</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={12} lg={6}>
                              <DetailItem
                                title="Company "
                                profilePic={
                                  transaction?.vendor?.id?.profilePic &&
                                  process.env.REACT_APP_API_URL + transaction?.vendor?.id?.profilePic
                                }
                                // icon="eva:person-fill"
                                data={transaction?.vendor?.id?.companyName}
                                sx={{ background: '#fff', cursor: 'pointer' }}
                                onClick={() =>
                                  navigate(`/dashboard/general/vendors/view-vendor/${transaction?.vendor?.id?._id}`)
                                }
                              />
                            </Grid>
                            <Grid item xs={12} md={12} lg={6}>
                              <DetailItem
                                title="Contact Person"
                                // icon="eva:email-fill"
                                data={transaction?.vendor?.id?.contactPerson}
                                sx={{ background: '#fff' }}
                              />
                            </Grid>
                            <Grid item xs={12} md={12} lg={6}>
                              <DetailItem
                                title="Email"
                                // icon="eva:email-fill"
                                data={transaction?.vendor?.id?.email}
                                sx={{ background: '#fff' }}
                              />
                            </Grid>
                            <Grid item xs={12} md={12} lg={6}>
                              <DetailItem
                                title="Phone"
                                // icon="eva:phone-fill"
                                data={transaction?.vendor?.id?.phone1}
                                sx={{ background: '#fff' }}
                              />
                            </Grid>
                            <Grid item xs={12} md={12} lg={6}>
                              <DetailItem
                                title="Invoice Number"
                                // icon="eva:person-fill"
                                data={transaction?.vendor?.invoiceNumber}
                                sx={{ background: '#fff', cursor: 'pointer' }}
                                onClick={() =>
                                  navigate(
                                    `/dashboard/accounts/purchase/view-purchase/${transaction?.vendor?.invoiceNumber}`
                                  )
                                }
                              />
                            </Grid>
                            <Grid item xs={12} md={12} lg={6}>
                              <DetailItem
                                title="Vendor ID"
                                // icon="eva:id-card-fill"
                                data={transaction?.vendor?.id?._id}
                                sx={{ background: '#fff', cursor: 'pointer' }}
                                onClick={() =>
                                  navigate(`/dashboard/general/vendors/view-vendor/${transaction?.vendor?.id?._id}`)
                                }
                              />
                            </Grid>
                            <Grid item xs={12} md={12} lg={6}>
                              <DetailItem
                                title="Purchase ID"
                                // icon="eva:id-card-fill"
                                data={transaction?.vendor?.purchaseId}
                                sx={{ background: '#fff' }}
                              />
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Card>
                  </Grid>
                )}
              </Grid>
            </Box>
          </>
        </Stack>
      </Container>
    </Page>
  );
}

export default ViewTransaction;
