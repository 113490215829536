import { useState } from 'react';
// material
import { Container, Typography, Card, Tab, Box, Stack } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Qto from './quotationTables/Qto';
import Boq from './quotationTables/Boq';
import Quote from './quotationTables/Quote';

// components

export default function CustomerQuotationTabs({ customer }) {
  const [value, setValue] = useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange}>
            <Tab label="QTO" value="1" />
            <Tab label="BOQ" value="2" />
            <Tab label="Quotation" value="3" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <Qto customer={customer} />
        </TabPanel>
        <TabPanel value="2">
          <Boq customer={customer} />
        </TabPanel>
        <TabPanel value="3">
          <Quote customer={customer} />
        </TabPanel>
      </TabContext>
    </Box>
  );
}
