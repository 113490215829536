import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  Grid,
  MenuItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  styled,
  tableCellClasses,
} from '@mui/material';
import axios from 'axios';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { Add, AddCircle, Remove, RemoveCircle, RemoveRounded } from '@mui/icons-material';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import ViewQtoBasicDetails from '../components/ViewQtoBasicDetails';
import NavigateBack from '../components/smallComponents/NavigateBack';

const StyledTableCell = styled(TableCell)(({ theme, cellBorderLeft, cellBorderRight, fontWeight, bgcolor }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.primary,
    fontWeight: 'bold',
    textAlign: 'center',
    border: '0.5px solid #d49292',
    borderLeft: cellBorderLeft || '0.5px solid #d49292',
    borderRight: cellBorderRight || '0.5px solid #d49292',
    fontSize: 14,
  },

  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    textAlign: 'left',
    border: '0.5px solid #d49292',
    borderLeft: cellBorderLeft || '0.5px solid #d49292',
    borderRight: cellBorderRight || '0.5px solid #d49292',
    fontWeight: fontWeight || 'normal',
    backgroundColor: bgcolor || '#fff',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  // '&:last-child td, &:last-child th': {
  //   border: 0,
  // },
}));

function ViewQto() {
  const { id } = useParams();
  const [qto, setQto] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const qtoSchema = Yup.object().shape({
    items: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required('Required'),
      })
    ),
  });
  const formik = useFormik({
    initialValues: {
      items: [],
    },
    validationSchema: qtoSchema,
    onSubmit: async () => {
      console.log(values);
      try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/quotation/qto`, { ...values, id });
        console.log(res.data);
        toast.success(res.data.message);
        getQto();
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });

  const createSubItem = (key) => {
    const items = [...values.items];
    const subItem = {
      name: '',
      length: '',
      width: '',
      height: '',
      procurementUnit: 'Nos',
      procurementQuantity: '0',
      procurementUnitPrice: '0',
      procurementTotalPrice: '0',
      workType: '',
      workHour: '0',
      workPricePerHour: '0',
      totalWorkPrice: '0',
      grandTotal: '0',
      cum: '0',
      remarks: '',
    };
    items[key].subItems.push(subItem);
    formik.setFieldValue('items', items);
  };

  const onBlurMainItem = (e, key, field) => {
    const items = [...values.items];
    items[key][field] = e.target.innerText;
    formik.setFieldValue('items', items);
  };

  const onBlurMainQuantity = (e, key) => {
    const items = [...values.items];
    items[key].quantity = e.target.innerText;
    items[key].unitPrice = checkIfNumber(values.items[key].totalPrice) / parseFloat(items[key].quantity);
    formik.setFieldValue('items', items);
  };

  const onBlurSubItem = (e, key, key1, field) => {
    const items = [...values.items];
    items[key].subItems[key1][field] = e.target.innerText;
    formik.setFieldValue('items', items);
  };

  /// //// total cum of indvidual subitem ////
  const calculateCum = () => {
    const items = [...values.items];
    let cum = 0;
    for (let i = 0; i < items.length; i += 1) {
      for (let j = 0; j < items[i].subItems.length; j += 1) {
        cum += parseFloat(items[i].subItems[j].grandTotal);
        items[i].subItems[j].cum = cum;
      }
    }
  };

  const calculateCumInitially = (items) => {
    let cum = 0;
    for (let i = 0; i < items.length; i += 1) {
      for (let j = 0; j < items[i].subItems.length; j += 1) {
        cum += parseFloat(items[i].subItems[j].grandTotal);
        items[i].subItems[j].cum = cum;
      }
    }
    return cum;
  };

  /// //// total of main item ////
  const calculateTotal = (key) => {
    const items = [...values.items];
    let total = 0;
    for (let i = 0; i < items[key].subItems.length; i += 1) {
      total += parseFloat(items[key].subItems[i].grandTotal);
    }
    items[key].total = total;
    return total;
  };

  const calculateTotalInitially = (items, key) => {
    let total = 0;
    for (let i = 0; i < items[key].subItems.length; i += 1) {
      total += parseFloat(items[key].subItems[i].grandTotal);
    }
    return total;
  };

  /// //// total cum of main item ////
  const calculateTotalCum = () => {
    const items = [...values.items];
    let totalCum = 0;
    for (let i = 0; i < items.length; i += 1) {
      totalCum += parseFloat(items[i].totalPrice);
      items[i].totalCum = totalCum.toFixed(2);
    }
  };

  const calculateTotalCumInitially = (items) => {
    let totalCum = 0;
    for (let i = 0; i < items.length; i += 1) {
      totalCum += parseFloat(items[i].totalPrice);
      items[i].totalCum = totalCum;
    }
    return totalCum.toFixed(2);
  };

  const checkIfNumber = (value) => {
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(value)) {
      return 0;
    }
    const newValue = parseFloat(value).toFixed(2);
    return newValue;
  };

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  const setFormValues = (data) => {
    const items = [...data.items];
    for (let i = 0; i < items.length; i += 1) {
      items[i].total = calculateTotalInitially(items, i);
      items[i].totalCum = calculateTotalCumInitially(items);
      for (let j = 0; j < items[i].subItems.length; j += 1) {
        items[i].subItems[j].cum = calculateCumInitially(items);
      }
    }
    formik.setFieldValue('items', items);
  };

  const getQto = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/quotation/qto`, {
        params: {
          id,
        },
      });
      console.log(res.data.data);
      setFormValues(res.data.data);
      setQto(res.data.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    calculateCum();
    calculateTotalCum();
  }, [values.items]);

  useEffect(() => {
    getQto();
  }, []);

  return (
    <Page title="QTO">
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} mt={2}>
            <Typography variant="h4" mt={1}>
              Quantity Take Off
            </Typography>
            <NavigateBack />
          </Stack>
          <Box paddingBottom={10}>
            <ViewQtoBasicDetails data={qto} getQto={getQto} />
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Scrollbar sx={{ marginTop: '5px', paddingBottom: '100px' }}>
                  <TableContainer component={Paper} sx={{ padding: '3px', minWidth: '2300px', marginBottom: '20px' }}>
                    <Table
                      sx={{
                        '& .MuiTableCell-root': {
                          padding: '3px',
                        },
                        border: '3px solid #000',
                      }}
                    >
                      <TableHead>
                        <TableRow>
                          <StyledTableCell rowSpan={2} sx={{ width: '70px' }}>
                            Sl No.
                          </StyledTableCell>
                          <StyledTableCell rowSpan={2} sx={{ minWidth: '400px' }}>
                            Description
                          </StyledTableCell>
                          <StyledTableCell colSpan={3} cellBorderLeft="3px solid #000" cellBorderRight="3px solid #000">
                            Dimensions
                          </StyledTableCell>
                          <StyledTableCell colSpan={2} sx={{ minWidth: '200px' }} cellBorderLeft="3px solid #000">
                            Procure
                          </StyledTableCell>
                          <StyledTableCell> Rate</StyledTableCell>
                          <StyledTableCell cellBorderRight="3px solid #000"> Amount</StyledTableCell>
                          <StyledTableCell colSpan={2}>Work Force</StyledTableCell>
                          <StyledTableCell> Rate</StyledTableCell>
                          <StyledTableCell cellBorderRight="3px solid #000"> Amount</StyledTableCell>
                          <StyledTableCell colSpan={2} cellBorderRight="3px solid #000">
                            Total(AED)
                          </StyledTableCell>
                          <StyledTableCell rowSpan={2} sx={{ minWidth: '400px' }}>
                            Notes
                          </StyledTableCell>
                        </TableRow>
                        <TableRow>
                          <StyledTableCell cellBorderLeft="3px solid #000"> Length</StyledTableCell>
                          <StyledTableCell> Width</StyledTableCell>
                          <StyledTableCell cellBorderRight="3px solid #000"> Height</StyledTableCell>
                          <StyledTableCell sx={{ minWidth: '100px' }}> Unit</StyledTableCell>
                          <StyledTableCell sx={{ minWidth: '100px' }}> Quantity</StyledTableCell>
                          <StyledTableCell> AED/Unit</StyledTableCell>
                          <StyledTableCell cellBorderRight="3px solid #000"> AED</StyledTableCell>
                          <StyledTableCell> Type</StyledTableCell>
                          <StyledTableCell> #Hrs</StyledTableCell>
                          <StyledTableCell> AED/Hr</StyledTableCell>
                          <StyledTableCell cellBorderRight="3px solid #000"> AED</StyledTableCell>
                          <StyledTableCell> LINE</StyledTableCell>
                          <StyledTableCell cellBorderRight="3px solid #000"> CUM</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {values.items.map((item, key) => (
                          <React.Fragment key={key}>
                            <TableRow key={key}>
                              <StyledTableCell fontWeight="bold" bgcolor="#cbeeff">
                                <Stack direction="row" spacing={0.5} alignItems={'center'}>
                                  <AddCircle
                                    sx={{ cursor: 'pointer' }}
                                    onClick={() => {
                                      createSubItem(key);
                                    }}
                                  />
                                  <Typography>{key + 1}</Typography>
                                </Stack>
                              </StyledTableCell>
                              <StyledTableCell fontWeight="bold" bgcolor="#cbeeff">
                                <Stack direction="row" spacing={0.5} alignItems={'center'}>
                                  <TextField
                                    fullWidth
                                    variant="standard"
                                    size="small"
                                    multiline
                                    sx={{
                                      '& .MuiInputBase-root': {
                                        fontSize: '14px',
                                        fontWeight: 'bold',
                                      },
                                      //  remove underline
                                      '& .MuiInput-underline:before': {
                                        borderBottom: 'none',
                                      },
                                    }}
                                    {...getFieldProps(`items.${key}.name`)}
                                  />
                                  <RemoveCircle
                                    sx={{ cursor: 'pointer' }}
                                    color="error"
                                    onClick={() => {
                                      const items = [...values.items];
                                      items.splice(key, 1);
                                      formik.setFieldValue('items', items);
                                    }}
                                  />
                                </Stack>
                              </StyledTableCell>
                              <StyledTableCell cellBorderLeft="3px solid #000" fontWeight="bold" bgcolor="#cbeeff">
                                {''}
                              </StyledTableCell>
                              <StyledTableCell fontWeight="bold" bgcolor="#cbeeff">
                                {''}
                              </StyledTableCell>
                              <StyledTableCell cellBorderRight="3px solid #000" fontWeight="bold" bgcolor="#cbeeff">
                                {''}
                              </StyledTableCell>
                              <StyledTableCell fontWeight="bold" bgcolor="#cbeeff">
                                <TextField
                                  fullWidth
                                  variant="standard"
                                  size="small"
                                  select
                                  sx={{
                                    '& .MuiInputBase-root': {
                                      fontSize: '14px',
                                      fontWeight: 'bold',
                                    },
                                    //  remove underline
                                    '& .MuiInput-underline:before': {
                                      borderBottom: 'none',
                                    },
                                  }}
                                  {...getFieldProps(`items.${key}.unit`)}
                                >
                                  <MenuItem value="Nos">Nos</MenuItem>
                                  <MenuItem value="LS">LS</MenuItem>
                                  <MenuItem value="LM">LM</MenuItem>
                                  <MenuItem value="Cartn">Cartn</MenuItem>
                                  <MenuItem value="Trips">Trips</MenuItem>
                                  <MenuItem value="Lth">Lth</MenuItem>
                                  <MenuItem value="KW">KW</MenuItem>
                                </TextField>
                              </StyledTableCell>
                              <StyledTableCell
                                fontWeight="bold"
                                contentEditable
                                suppressContentEditableWarning
                                onBlur={(e) => {
                                  onBlurMainQuantity(e, key);
                                }}
                                bgcolor="#cbeeff"
                              >
                                {item.quantity}
                              </StyledTableCell>
                              <StyledTableCell fontWeight="bold" bgcolor="#cbeeff">
                                {checkIfNumber(item.unitPrice)}
                              </StyledTableCell>
                              <StyledTableCell cellBorderRight="3px solid #000" fontWeight="bold" bgcolor="#cbeeff">
                                {checkIfNumber(item.total)}
                              </StyledTableCell>
                              <StyledTableCell fontWeight="bold" bgcolor="#cbeeff">
                                {''}
                              </StyledTableCell>
                              <StyledTableCell fontWeight="bold" bgcolor="#cbeeff">
                                {''}
                              </StyledTableCell>
                              <StyledTableCell fontWeight="bold" bgcolor="#cbeeff">
                                {''}
                              </StyledTableCell>
                              <StyledTableCell cellBorderRight="3px solid #000" fontWeight="bold" bgcolor="#cbeeff">
                                {''}
                              </StyledTableCell>
                              <StyledTableCell fontWeight="bold" bgcolor="#cbeeff">
                                {''}
                              </StyledTableCell>
                              <StyledTableCell cellBorderRight="3px solid #000" fontWeight="bold" bgcolor="#cbeeff">
                                {!!values.items[key - 1] && checkIfNumber(values.items[key - 1].totalCum)}
                              </StyledTableCell>
                              <StyledTableCell
                                contentEditable
                                suppressContentEditableWarning
                                onBlur={(e) => {
                                  onBlurMainItem(e, key, 'remarks');
                                }}
                                fontWeight="bold"
                              >
                                {''}
                              </StyledTableCell>
                            </TableRow>
                            {item.subItems.map((item1, key1) => (
                              <>
                                <StyledTableRow key={key1}>
                                  <StyledTableCell>
                                    <Stack direction="row" spacing={0.5} alignItems={'center'} paddingLeft={2}>
                                      <RemoveCircle
                                        sx={{ cursor: 'pointer' }}
                                        onClick={() => {
                                          const items = [...values.items];
                                          items[key].subItems.splice(key1, 1);
                                          formik.setFieldValue('items', items);
                                        }}
                                        color="error"
                                      />
                                      {`${key + 1}.${key1 + 1}`}
                                    </Stack>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    padding="0px 0px 0px 10px"
                                    contentEditable
                                    suppressContentEditableWarning
                                    onBlur={(e) => {
                                      onBlurSubItem(e, key, key1, 'name');
                                    }}
                                  >
                                    {item1.name}
                                  </StyledTableCell>
                                  <StyledTableCell
                                    cellBorderLeft="3px solid #000"
                                    contentEditable
                                    suppressContentEditableWarning
                                    onBlur={(e) => {
                                      onBlurSubItem(e, key, key1, 'length');
                                    }}
                                  >
                                    {item1.length}
                                  </StyledTableCell>
                                  <StyledTableCell
                                    contentEditable
                                    suppressContentEditableWarning
                                    onBlur={(e) => {
                                      onBlurSubItem(e, key, key1, 'width');
                                    }}
                                  >
                                    {item1.width}
                                  </StyledTableCell>
                                  <StyledTableCell
                                    cellBorderRight="3px solid #000"
                                    contentEditable
                                    suppressContentEditableWarning
                                    onBlur={(e) => {
                                      onBlurSubItem(e, key, key1, 'height');
                                    }}
                                  >
                                    {item1.height}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    <TextField
                                      fullWidth
                                      variant="standard"
                                      size="small"
                                      select
                                      sx={{
                                        '& .MuiInputBase-root': {
                                          fontSize: '14px',
                                        },
                                        //  remove underline
                                        '& .MuiInput-underline:before': {
                                          borderBottom: 'none',
                                        },
                                      }}
                                      {...getFieldProps(`items.${key}.subItems.${key1}.procurementUnit`)}
                                    >
                                      <MenuItem value="Nos">Nos</MenuItem>
                                      <MenuItem value="LS">LS</MenuItem>
                                      <MenuItem value="LM">LM</MenuItem>
                                      <MenuItem value="Cartn">Cartn</MenuItem>
                                      <MenuItem value="Trips">Trips</MenuItem>
                                      <MenuItem value="Lth">Lth</MenuItem>
                                      <MenuItem value="KW">KW</MenuItem>
                                    </TextField>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    contentEditable
                                    suppressContentEditableWarning
                                    onBlur={(e) => {
                                      const items = [...values.items];
                                      items[key].subItems[key1].procurementQuantity = e.target.innerText;
                                      items[key].subItems[key1].procurementTotalPrice =
                                        parseFloat(items[key].subItems[key1].procurementQuantity) *
                                        parseFloat(items[key].subItems[key1].procurementUnitPrice);
                                      items[key].subItems[key1].grandTotal =
                                        parseFloat(items[key].subItems[key1].procurementTotalPrice) +
                                        parseFloat(items[key].subItems[key1].totalWorkPrice);
                                      const totalPrice = calculateTotal(key);
                                      items[key].totalPrice = totalPrice;
                                      formik.setFieldValue('items', items);
                                    }}
                                  >
                                    {item1.procurementQuantity}
                                  </StyledTableCell>
                                  <StyledTableCell
                                    name="procurementUnitPrice"
                                    contentEditable
                                    suppressContentEditableWarning
                                    onBlur={(e) => {
                                      const items = [...values.items];
                                      items[key].subItems[key1].procurementUnitPrice = e.target.innerText;
                                      items[key].subItems[key1].procurementTotalPrice =
                                        parseFloat(items[key].subItems[key1].procurementQuantity) *
                                        parseFloat(items[key].subItems[key1].procurementUnitPrice);
                                      items[key].subItems[key1].grandTotal =
                                        parseFloat(items[key].subItems[key1].procurementTotalPrice) +
                                        parseFloat(items[key].subItems[key1].totalWorkPrice);
                                      const totalPrice = calculateTotal(key);
                                      items[key].totalPrice = totalPrice;
                                      formik.setFieldValue('items', items);
                                    }}
                                  >
                                    {item1.procurementUnitPrice}
                                  </StyledTableCell>
                                  <StyledTableCell cellBorderRight="3px solid #000" name="procurementTotalPrice">
                                    {item1.procurementTotalPrice}
                                  </StyledTableCell>
                                  <StyledTableCell
                                    contentEditable
                                    suppressContentEditableWarning
                                    onBlur={(e) => {
                                      onBlurSubItem(e, key, key1, 'workType');
                                    }}
                                  >
                                    {item1.workType}
                                  </StyledTableCell>
                                  <StyledTableCell
                                    name="workHour"
                                    contentEditable
                                    suppressContentEditableWarning
                                    onBlur={(e) => {
                                      const items = [...values.items];
                                      items[key].subItems[key1].workHour = e.target.innerText;
                                      items[key].subItems[key1].totalWorkPrice =
                                        parseFloat(items[key].subItems[key1].workHour) *
                                        parseFloat(items[key].subItems[key1].workPricePerHour);
                                      items[key].subItems[key1].grandTotal =
                                        parseFloat(items[key].subItems[key1].procurementTotalPrice) +
                                        parseFloat(items[key].subItems[key1].totalWorkPrice);
                                      const totalPrice = calculateTotal(key);
                                      items[key].totalPrice = totalPrice;
                                      formik.setFieldValue('items', items);
                                    }}
                                  >
                                    {item1.workHour}
                                  </StyledTableCell>
                                  <StyledTableCell
                                    name="workPricePerHour"
                                    contentEditable
                                    suppressContentEditableWarning
                                    onBlur={(e) => {
                                      const items = [...values.items];
                                      items[key].subItems[key1].workPricePerHour = e.target.innerText;
                                      items[key].subItems[key1].totalWorkPrice =
                                        parseFloat(items[key].subItems[key1].workHour) *
                                        parseFloat(items[key].subItems[key1].workPricePerHour);
                                      items[key].subItems[key1].grandTotal =
                                        parseFloat(items[key].subItems[key1].procurementTotalPrice) +
                                        parseFloat(items[key].subItems[key1].totalWorkPrice);
                                      const totalPrice = calculateTotal(key);
                                      items[key].totalPrice = totalPrice;
                                      formik.setFieldValue('items', items);
                                    }}
                                  >
                                    {item1.workPricePerHour}
                                  </StyledTableCell>
                                  <StyledTableCell name="totalWorkPrice" cellBorderRight="3px solid #000">
                                    {item1.totalWorkPrice}
                                  </StyledTableCell>
                                  <StyledTableCell>{item1.grandTotal}</StyledTableCell>
                                  <StyledTableCell cellBorderRight="3px solid #000">{item1.cum}</StyledTableCell>
                                  <StyledTableCell
                                    contentEditable
                                    suppressContentEditableWarning
                                    onBlur={(e) => {
                                      onBlurSubItem(e, key, key1, 'remarks');
                                    }}
                                  >
                                    {item1.remarks}
                                  </StyledTableCell>
                                </StyledTableRow>
                              </>
                            ))}
                            {
                              <TableRow>
                                <StyledTableCell>{''}</StyledTableCell>
                                <StyledTableCell fontWeight="bold">{`Total (Sl. No${key + 1})`}</StyledTableCell>
                                <StyledTableCell>{''}</StyledTableCell>
                                <StyledTableCell>{''}</StyledTableCell>
                                <StyledTableCell>{''}</StyledTableCell>
                                <StyledTableCell>{''}</StyledTableCell>
                                <StyledTableCell>{''}</StyledTableCell>
                                <StyledTableCell>{''}</StyledTableCell>
                                <StyledTableCell>{''}</StyledTableCell>
                                <StyledTableCell>{''}</StyledTableCell>
                                <StyledTableCell>{''}</StyledTableCell>
                                <StyledTableCell>{''}</StyledTableCell>
                                <StyledTableCell>{''}</StyledTableCell>
                                <StyledTableCell fontWeight="bold">
                                  {checkIfNumber(values.items[key].totalPrice)}
                                </StyledTableCell>
                                <StyledTableCell fontWeight="bold">
                                  {checkIfNumber(values.items[key].totalCum)}
                                </StyledTableCell>
                              </TableRow>
                            }
                          </React.Fragment>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Scrollbar>
                {values.items.length < 1 ? (
                  <Button
                    variant="contained"
                    onClick={() => {
                      const items = [
                        {
                          name: '',
                          unit: '',
                          quantity: '',
                          rate: '',
                          totalPrice: '',
                          totalCum: '',
                          subItems: [],
                        },
                      ];
                      formik.setFieldValue('items', items);
                    }}
                  >
                    Start Adding Items
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    startIcon={<Add />}
                    onClick={() => {
                      const items = [
                        ...values.items,
                        {
                          name: '',
                          unit: '',
                          quantity: '',
                          rate: '',
                          totalPrice: '',
                          totalCum: '',
                          subItems: [],
                        },
                      ];
                      formik.setFieldValue('items', items);
                    }}
                  >
                    Add More Items
                  </Button>
                )}
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  spacing={1}
                  sx={{ padding: '10px' }}
                >
                  <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                    Submit
                  </LoadingButton>
                </Stack>
              </Form>
            </FormikProvider>
          </Box>
        </>
      )}
    </Page>
  );
}

export default ViewQto;
