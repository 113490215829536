import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  Container,
  FormControlLabel,
  Grid,
  MenuItem,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { ExpandMore } from '@mui/icons-material';
import avatar from '../assets/avatar.png';

export default function ViewQtoBasicDetails({ data, getQto }) {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isEditing, setIsEditing] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [employees, setEmployees] = useState([]);
  const [selectedQtoPreparedBy, setSelectedQtoPreparedBy] = useState({});
  const [selectedQtoApprovedBy, setSelectedQtoApprovedBy] = useState({});

  const formik = useFormik({
    initialValues: {
      customer: data.customerId?._id,
      qtoApprovedBy: data.qtoApprovedBy?._id,
      qtoPreparedBy: data.qtoPreparedBy._id,
      referenceNumber: data.referenceNumber,
      qtoStatus: data.qtoStatus,
      quotationId: data.quotationId,
      quotationStatus: data.quotationStatus,
      isFinalised: data.isFinalised,
      boqStatus: data.boqStatus,
      version: data.version,
      id,
    },
    onSubmit: async () => {
      try {
        const removeEmptyValues = (obj) => {
          Object.keys(obj).forEach((key) => (obj[key] === undefined || obj[key] === '') && delete obj[key]);
          return obj;
        };
        const newValues = removeEmptyValues(formik.values);
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/quotation/qto/details/`, newValues);
        toast(res.data.message);
        getQto();
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });

  const getCustomers = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/customer/active`);
      console.log(res.data.data);
      setCustomers(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getEmployees = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/member/employee/active/all`);
      console.log(res.data.data);
      setEmployees(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  useEffect(() => {
    formik.setValues({
      customer: data.customerId?._id, 
      qtoApprovedBy: data.qtoApprovedBy?._id,
      qtoPreparedBy: data.qtoPreparedBy._id, 
      referenceNumber: data.referenceNumber,
      qtoStatus: data.qtoStatus,
      quotationId: data.quotationId,
      quotationStatus: data.quotationStatus,
      id,
    });
  }, [data]);

  useEffect(() => {
    setSelectedCustomer(customers.find((customer) => customer._id === values.customer));
  }, [customers]);

  useEffect(() => {
    setSelectedQtoPreparedBy(employees.find((employee) => employee._id === values.qtoPreparedBy));
    setSelectedQtoApprovedBy(employees.find((employee) => employee._id === values.qtoApprovedBy));
  }, [employees]);

  useEffect(() => {
    getCustomers();
    getEmployees();
  }, []);

  return (
    <Box my={2}>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography variant="h6" sx={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
            Project Name: <span style={{ fontWeight: 'normal' }}>{data.projectName}</span>
          </Typography>
        </AccordionSummary>{' '}
        <AccordionDetails>
          {isEditing ? (
            <>
              <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={4}>
                      <Autocomplete
                        fullWidth
                        id="asynchronous-demo"
                        isOptionEqualToValue={(option, value) => option.fullname === value.fullname}
                        getOptionLabel={(option) => option.fullname}
                        getOptionSelected={(option, value) => option.fullname === value.fullname}
                        value={selectedCustomer}
                        options={customers}
                        onChange={(_, value) => {
                          if (value) {
                            formik.setFieldValue('customerId', value._id);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Customer"
                            InputProps={{
                              ...params.InputProps,
                            }}
                            error={Boolean(touched.customerId && errors.customerId)}
                            helperText={touched.customerId && errors.customerId}
                          />
                        )}
                        renderOption={(props, option) => (
                          <li {...props}>
                            <Stack direction="row" spacing={2} alignItems="center">
                              <Avatar
                                sx={{ width: 40, height: 40, cursor: 'pointer' }}
                                src={
                                  (option?.profilePic && process.env.REACT_APP_API_URL + option.profilePic) || avatar
                                }
                              />
                              <Stack direction={'column'}>
                                <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1' }}>
                                  {option.fullname.toUpperCase()}
                                </Typography>
                                <Typography variant="caption" sx={{ lineHeight: '1.1' }}>
                                  {option.email}
                                </Typography>
                              </Stack>
                            </Stack>
                          </li>
                        )}
                      />
                    </Grid>
                 
                    <Grid item xs={12} md={6} lg={4}>
                      <Autocomplete
                        fullWidth
                        id="asynchronous-demo"
                        isOptionEqualToValue={(option, value) => option.fullname === value.fullname}
                        getOptionLabel={(option) => option.fullname}
                        getOptionSelected={(option, value) => option.fullname === value.fullname}
                        value={selectedQtoPreparedBy}
                        options={employees}
                        onChange={(_, value) => {
                          if (value) {
                            formik.setFieldValue('qtoPreparedBy', value._id);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="QTO Prepared By"
                            InputProps={{
                              ...params.InputProps,
                            }}
                            error={Boolean(touched.qtoPreparedBy && errors.qtoPreparedBy)}
                            helperText={touched.qtoPreparedBy && errors.qtoPreparedBy}
                          />
                        )}
                        renderOption={(props, option) => (
                          <li {...props}>
                            <Stack direction="row" spacing={2} alignItems="center">
                              <Avatar
                                sx={{ width: 40, height: 40, cursor: 'pointer' }}
                                src={
                                  (option?.profilePic && process.env.REACT_APP_API_URL + option.profilePic) || avatar
                                }
                              />
                              <Stack direction={'column'}>
                                <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1' }}>
                                  {option.fullname.toUpperCase()}
                                </Typography>
                                <Typography variant="caption" sx={{ lineHeight: '1.1' }}>
                                  {option.email}
                                </Typography>
                              </Stack>
                            </Stack>
                          </li>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <Autocomplete
                        fullWidth
                        id="asynchronous-demo"
                        isOptionEqualToValue={(option, value) => option.fullname === value.fullname}
                        getOptionLabel={(option) => option.fullname}
                        getOptionSelected={(option, value) => option.fullname === value.fullname}
                        value={selectedQtoApprovedBy}
                        options={employees}
                        onChange={(_, value) => {
                          if (value) {
                            formik.setFieldValue('qtoApprovedBy', value._id);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="QTO Approved By"
                            InputProps={{
                              ...params.InputProps,
                            }}
                            error={Boolean(touched.qtoApprovedBy && errors.qtoApprovedBy)}
                            helperText={touched.qtoApprovedBy && errors.qtoApprovedBy}
                          />
                        )}
                        renderOption={(props, option) => (
                          <li {...props}>
                            <Stack direction="row" spacing={2} alignItems="center">
                              <Avatar
                                sx={{ width: 40, height: 40, cursor: 'pointer' }}
                                src={
                                  (option?.profilePic && process.env.REACT_APP_API_URL + option.profilePic) || avatar
                                }
                              />
                              <Stack direction={'column'}>
                                <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1' }}>
                                  {option.fullname.toUpperCase()}
                                </Typography>
                                <Typography variant="caption" sx={{ lineHeight: '1.1' }}>
                                  {option.email}
                                </Typography>
                              </Stack>
                            </Stack>
                          </li>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        type="text"
                        label="Reference Number"
                        fullWidth
                        sx={{ width: '100%' }}
                        {...getFieldProps('referenceNumber')}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        select
                        label="QTO Status"
                        fullWidth
                        sx={{ width: '100%' }}
                        {...getFieldProps('qtoStatus')}
                      >
                        <MenuItem value="NA">NA</MenuItem>
                        <MenuItem value="PENDING">Pending</MenuItem>
                        <MenuItem value="APPROVED">Approved</MenuItem>
                        <MenuItem value="REJECTED">Rejected</MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item xs={12}>
                      <Stack direction="row" sx={{ width: '100%' }} justifyContent="flex-end" spacing={2}>
                        <Button variant="contained" onClick={() => setIsEditing(false)}>
                          Cancel
                        </Button>
                        <LoadingButton variant="contained" type="submit" loading={isSubmitting}>
                          Submit
                        </LoadingButton>
                      </Stack>
                    </Grid>
                  </Grid>
                </Form>
              </FormikProvider>
            </>
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={4}>
                <Typography variant="h6" sx={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                  Customer: <span style={{ fontWeight: 'normal' }}>{data.customerId?.fullname}</span>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Typography variant="h6" sx={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                  Location: <span style={{ fontWeight: 'normal' }}>{data.location}</span>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Typography variant="h6" sx={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                  Quotation ID: <span style={{ fontWeight: 'normal' }}>{data.quotationId}</span>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Typography variant="h6" sx={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                  QTO Status: <span style={{ fontWeight: 'normal' }}>{data.qtoStatus}</span>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Typography variant="h6" sx={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                  QTO Prepared By: <span style={{ fontWeight: 'normal' }}>{data.qtoPreparedBy.fullname}</span>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Typography variant="h6" sx={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                  QTO Approved By: <span style={{ fontWeight: 'normal' }}>{data.qtoApprovedBy.fullname}</span>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Typography variant="h6" sx={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                  BOQ Status: <span style={{ fontWeight: 'normal' }}>{data.boqStatus}</span>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Typography variant="h6" sx={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                  Quotation Status: <span style={{ fontWeight: 'normal' }}>{data.quotationStatus}</span>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Typography variant="h6" sx={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                  Version: <span style={{ fontWeight: 'normal' }}>{data.version}</span>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Typography variant="h6" sx={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                  Reference Number: <span style={{ fontWeight: 'normal' }}>{data.referenceNumber}</span>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Typography variant="h6" sx={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                  Is Finalised: <span style={{ fontWeight: 'normal' }}>{data.isFinalised ? 'Yes' : 'No'}</span>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Stack direction="row" sx={{ width: '100%' }} justifyContent="flex-end">
                  <Button variant="contained" onClick={() => setIsEditing(true)}>
                    Edit{' '}
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          )}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
