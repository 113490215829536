import { useState } from 'react';
// material
import { Container, Typography, Card, Tab, Box, Stack } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';

// components
import BillTable from './BillTable';

export default function Bills({ project }) {
  const [value, setValue] = useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange}>
            <Tab label="Pending" value="1" />
            <Tab label="Completed" value="2" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <BillTable status={'pending'} customerId={project?.customerId} />
        </TabPanel>
        <TabPanel value="2">
          <BillTable status={'completed'} customerId={project?.customerId} />
        </TabPanel>
      </TabContext>
    </Box>
  );
}
