import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import axios from 'axios';
import Moment from 'moment';
// material
import {
  Card,
  Table,
  Stack,
  CircularProgress,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Container,
  TableContainer,
  Typography,
  Box,
} from '@mui/material';
// import Filter from './filters/TradingProfitLoss';

// components
import Scrollbar from './Scrollbar';

const CustomTableCell = ({ children }) => <TableCell sx={{ height: '60px', paddingY: 0 }}>{children}</TableCell>;

export default function TradingProfitLoss() {
  const navigate = useNavigate();

  const [transactionData, setTransactionData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [options, setOptions] = useState({});
  const [directExpenseLength, setDirectExpenseLength] = useState(0);
  const [directIncomeLength, setDirectIncomeLength] = useState(0);
  const [indirectExpenseLength, setIndirectExpenseLength] = useState(0);
  const [indirectIncomeLength, setIndirectIncomeLength] = useState(0);

  const getTradingProfitLoss = (options) => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/ledger/trading-profit-loss`, {
        params: options,
      })
      .then((res) => {
        console.log('Payments', res);
        if (res.data.isError === false) {
          setIsLoading(false);
          setTransactionData(res.data.data);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (transactionData) {
      let directExpenseLength = 0;
      let directIncomeLength = 0;
      let indirectExpenseLength = 0;
      let indirectIncomeLength = 0;

      transactionData.records.forEach((item) => {
        if (item.accountType === 'Direct Expense') {
          directExpenseLength += item.docs.length;
        }
        if (item.accountType === 'Direct Income') {
          directIncomeLength += item.docs.length;
        }
        if (item.accountType === 'Indirect Expense') {
          indirectExpenseLength += item.docs.length;
        }
        if (item.accountType === 'Indirect Income') {
          indirectIncomeLength += item.docs.length;
        }
      });
      setDirectExpenseLength(directExpenseLength);
      setDirectIncomeLength(directIncomeLength);
      setIndirectExpenseLength(indirectExpenseLength);
      setIndirectIncomeLength(indirectIncomeLength);
    }
  }, [transactionData]);

  const applyFilters = (filter) => {
    console.log('filter', filter);
    const temp = {};

    if (filter.transactionType) {
      temp.transactionType = filter.transactionType.trim();
    }
    if (filter.ledgerId) {
      temp.ledgerId = filter.ledgerId;
    }

    if (filter.paymentStartDate) {
      temp.paymentStartDate = filter.paymentStartDate;
    }
    if (filter.paymentEndDate) {
      temp.paymentEndDate = filter.paymentEndDate;
    }
    if (filter.txnStartDate) {
      temp.txnStartDate = filter.txnStartDate;
    }
    if (filter.txnEndDate) {
      temp.txnEndDate = filter.txnEndDate;
    }
    if (filter.paymentMode) {
      temp.paymentMode = filter.paymentMode;
    }

    setOptions(temp);
    getTradingProfitLoss(temp);
  };

  useEffect(() => {
    getTradingProfitLoss(options);
  }, []);

  return (
    <>
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <Container maxWidth="xl">
            <Card>
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Stack padding={1} direction="row" justifyContent="flex-end" spacing={2} alignItems="center">
                  {/* <Filter applyFilters={applyFilters} /> */}
                </Stack>
              </Stack>

              <Stack direction="row" justifyContent="space-between">
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <CustomTableCell>Particulars</CustomTableCell>
                        <CustomTableCell align="center">Amount</CustomTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {transactionData.records
                        .filter((item) => item.accountType === 'Direct Expense')
                        .map((item1) => (
                          <>
                            {item1.docs.map((doc, key) => (
                              <React.Fragment key={key}>
                                <TableRow>
                                  <CustomTableCell>{doc.name}</CustomTableCell>
                                  <CustomTableCell align="center">₹{Math.abs(doc.balance)}</CustomTableCell>
                                </TableRow>
                              </React.Fragment>
                            ))}
                          </>
                        ))}
                      {directExpenseLength - directIncomeLength < 0 &&
                        Array(Math.abs(directExpenseLength - directIncomeLength))
                          .fill()
                          .map((item, key) => (
                            <TableRow key={key}>
                              <CustomTableCell>{''}</CustomTableCell>
                              <CustomTableCell align="center">{''}</CustomTableCell>
                            </TableRow>
                          ))}
                      <TableRow>
                        <CustomTableCell>
                          <Typography sx={{ fontWeight: 'bold' }}>Gross Profit</Typography>
                        </CustomTableCell>
                        <CustomTableCell align="center">
                          <Typography sx={{ fontWeight: 'bold' }}>
                            {transactionData.isProfit && `₹${Math.abs(transactionData.grossProfit).toFixed(2)}`}
                          </Typography>
                        </CustomTableCell>
                      </TableRow>
                      <TableRow>
                        <CustomTableCell>{''}</CustomTableCell>
                        <CustomTableCell align="center">
                          <Box padding={1} border={1} borderColor={'#000000'}>
                            <Typography sx={{ fontWeight: 'bold' }}>₹{transactionData.profitOrLoss}</Typography>
                          </Box>
                        </CustomTableCell>
                      </TableRow>
                      <TableRow>
                        <CustomTableCell>
                          <Typography sx={{ fontWeight: 'bold' }}>{'Gross Loss c/d'}</Typography>
                        </CustomTableCell>
                        <CustomTableCell>
                          <Typography sx={{ fontWeight: 'bold' }}>₹ {transactionData.grossLossCD}</Typography>
                        </CustomTableCell>
                      </TableRow>
                      <TableRow>
                        <CustomTableCell>{''}</CustomTableCell>
                        <CustomTableCell>{''}</CustomTableCell>
                      </TableRow>

                      {transactionData.records
                        .filter((item) => item.accountType === 'Indirect Expense')
                        .map((item1) => (
                          <>
                            {item1.docs.map((doc, key) => (
                              <React.Fragment key={key}>
                                <TableRow>
                                  <CustomTableCell>{doc.name}</CustomTableCell>
                                  <CustomTableCell align="center">₹{doc.balance}</CustomTableCell>
                                </TableRow>
                              </React.Fragment>
                            ))}
                          </>
                        ))}
                      {indirectExpenseLength - indirectIncomeLength < 0 &&
                        Array(Math.abs(indirectExpenseLength - indirectIncomeLength))
                          .fill()
                          .map((item, key) => (
                            <TableRow key={key}>
                              <CustomTableCell>{''}</CustomTableCell>
                              <CustomTableCell align="center">{''}</CustomTableCell>
                            </TableRow>
                          ))}
                      {
                        <TableRow>
                          <CustomTableCell>
                            <Typography sx={{ fontWeight: 'bold' }}>Net Profit</Typography>
                          </CustomTableCell>
                          <CustomTableCell>
                            <Typography sx={{ fontWeight: 'bold' }}>
                              ₹{transactionData.isNetProfit && transactionData.netProfit}
                            </Typography>
                          </CustomTableCell>
                        </TableRow>
                      }
                      {
                        <TableRow>
                          <CustomTableCell>{''}</CustomTableCell>
                          <CustomTableCell>
                            <Box padding={1} border={1} borderColor={'#000000'}>
                              <Typography sx={{ fontWeight: 'bold' }}>₹{transactionData.netProfitOrLoss}</Typography>
                            </Box>
                          </CustomTableCell>
                        </TableRow>
                      }
                    </TableBody>
                  </Table>
                </TableContainer>

                {/* t */}

                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <CustomTableCell>Particulars</CustomTableCell>
                        <CustomTableCell align="center">Amount</CustomTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {transactionData.records
                        .filter((item) => item.accountType === 'Direct Income')
                        .map((item1) => (
                          <>
                            {item1.docs.map((doc, key) => (
                              <React.Fragment key={key}>
                                <TableRow>
                                  <CustomTableCell>{doc.name}</CustomTableCell>
                                  <CustomTableCell align="center">₹{doc.balance}</CustomTableCell>
                                </TableRow>
                              </React.Fragment>
                            ))}
                          </>
                        ))}

                      {directIncomeLength - directExpenseLength < 0 &&
                        Array(Math.abs(directIncomeLength - directExpenseLength))
                          .fill()
                          .map((item, key) => (
                            <TableRow key={key}>
                              <CustomTableCell>{''}</CustomTableCell>
                              <CustomTableCell align="center">{''}</CustomTableCell>
                            </TableRow>
                          ))}
                      <TableRow>
                        <CustomTableCell>
                          <Typography sx={{ fontWeight: 'bold' }}>{'Gross Loss'} </Typography>
                        </CustomTableCell>
                        <CustomTableCell>
                          <Typography sx={{ fontWeight: 'bold' }}>
                            {!transactionData.isProfit && transactionData.grossLoss}
                          </Typography>
                        </CustomTableCell>
                      </TableRow>

                      <TableRow>
                        {/* empty cell */}
                        <CustomTableCell>{''}</CustomTableCell>
                        <CustomTableCell align="center">
                          <Box padding={1} border={1} borderColor={'#000000'}>
                            <Typography sx={{ fontWeight: 'bold' }}>₹{transactionData.profitOrLoss}</Typography>
                          </Box>
                        </CustomTableCell>
                      </TableRow>

                      <TableRow>
                        <CustomTableCell>
                          <Typography sx={{ fontWeight: 'bold' }}>
                            {transactionData.isProfit && 'Gross Profit b/d'}
                          </Typography>
                        </CustomTableCell>
                        <CustomTableCell align="center">
                          <Typography sx={{ fontWeight: 'bold' }}>₹{transactionData.grossProfitBD}</Typography>
                        </CustomTableCell>
                      </TableRow>
                      <TableRow>
                        <CustomTableCell>{''}</CustomTableCell>
                        <CustomTableCell>{''}</CustomTableCell>
                      </TableRow>

                      {transactionData.records
                        .filter((item) => item.accountType === 'Indirect Income')
                        .map((item1) => (
                          <>
                            {item1.docs.map((doc, key) => (
                              <React.Fragment key={key}>
                                <TableRow>
                                  <CustomTableCell>{doc.name}</CustomTableCell>
                                  <CustomTableCell>₹{doc.balance}</CustomTableCell>
                                </TableRow>
                              </React.Fragment>
                            ))}
                          </>
                        ))}
                      {indirectIncomeLength - indirectExpenseLength < 0 &&
                        Array(Math.abs(indirectIncomeLength - indirectExpenseLength))
                          .fill()
                          .map((item, key) => (
                            <TableRow key={key}>
                              <CustomTableCell>{''}</CustomTableCell>
                              <CustomTableCell align="center">{''}</CustomTableCell>
                            </TableRow>
                          ))}

                      {
                        <TableRow>
                          <CustomTableCell>
                            <Typography sx={{ fontWeight: 'bold' }}>Net Loss</Typography>
                          </CustomTableCell>
                          <CustomTableCell>
                            <Typography sx={{ fontWeight: 'bold' }}>
                              {!transactionData.isNetProfit && `₹${transactionData.netLoss}`}
                            </Typography>
                          </CustomTableCell>
                        </TableRow>
                      }
                      {
                        <TableRow>
                          <CustomTableCell>{''}</CustomTableCell>
                          <CustomTableCell>
                            <Box padding={1} border={1} borderColor={'#000000'}>
                              <Typography sx={{ fontWeight: 'bold' }}>₹{transactionData.netProfitOrLoss}</Typography>
                            </Box>
                          </CustomTableCell>
                        </TableRow>
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </Stack>
            </Card>
          </Container>
        </>
      )}
    </>
  );
}
