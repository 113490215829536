import { Add, BorderColor, Close, Edit, RemoveRedEye } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { toast } from 'react-toastify';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Iconify from './Iconify';
import CustomerEditForm from './CustomerEditForm';
import Popup from './popups/CustomerStatusChange';
import Page from './Page';
import AddProjectAtCustomer from './popups/AddProjectAtCustomer';

function DetailItem({ icon, title, data, background, sx, onClick, profilePic }) {
  return (
    <Stack
      alignItems="center"
      spacing={1}
      direction="row"
      sx={{ height: '100%', background: '#f0f0f0', minHeight: '65px', borderRadius: '5px', ...sx }}
      onClick={onClick}
    >
      <Stack
        direction="row"
        alignItems="center"
        sx={{ height: '100%', borderRadius: '5px 0px 0px 5px' }}
        background={background}
        padding={profilePic ? 0 : 1}
      >
        {profilePic && <Avatar src={profilePic} alt={title} style={{ height: '40px', width: '40px' }} />}
        {!profilePic && <Iconify icon={icon} style={{ height: '25px', width: '25px' }} />}
      </Stack>

      <Stack direction="column" sx={{ padding: 1 }}>
        <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>{title}</Typography>
        <Typography style={{ wordBreak: 'break-all' }}>{data}</Typography>
      </Stack>
    </Stack>
  );
}

export default function CustomerBasicInfo({ customer, getCustomer }) {
  const { accType, role } = useSelector((state) => state.user.userInfo);
  const admin = accType === 'SUPER_ADMIN' || accType === 'SUB_ADMIN';
  const isCustomerManager = role && role.length && role.some((r) => r.name === 'LEAD_MANAGER');
  const isTelecaller = role && role.length && role.some((r) => r.name === 'TELE_CALLER');
  const [popup, setPopup] = useState(false);

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [paymentData, setPaymentData] = useState({});
  const [qtoData, setQtoData] = useState([]);
  const [qtoCount, setQtoCount] = useState(0);
  const [open, setOpen] = useState(false);

  const handleStatusChange = (event, item) => {
    console.log('item', item);
    if (`${item.isBlocked}` === event.target.value) {
      return;
    }
    setPopup(true);
  };

  const handleClose = (refresh = false, message = '') => {
    setPopup(false);
    if (refresh) {
      getCustomer();
    }
    if (message) {
      toast(message);
    }
  };

  const getPaymentData = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/payment/billing/all`, {
        params: { customerId: customer?._id },
      });
      setIsLoading(false);
      setPaymentData(res.data.data.records);
      console.log('res', res);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (customer && customer?.fullname) {
      getPaymentData();
    }
  }, [customer]);
  return (
    <Page title="customer">
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItem: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <Box
            sx={{ boxShadow: '#d0cfcf 0px 1px 4px', borderRadius: '10px', bgcolor: 'background.paper' }}
            paddingX={{ xl: 2, lg: 2, md: 2, sm: 1, xs: 0.5 }}
            paddingTop={1}
            paddingBottom={2}
          >
            <Stack direction="row" alignItems="center" justifyContent="end" mb={2} spacing={3}>
             

              <Button
                variant="contained"
                endIcon={<RemoveRedEye />}
                onClick={() => navigate(`/dashboard/crm/lead`, { state: { customerId: customer?._id } })}
              >
                View Leads
              </Button>
              <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={3}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <FormControl sx={{ minWidth: 100 }}>
                    <Select size="small" value={customer?.isBlocked} onChange={(e) => handleStatusChange(e, customer)}>
                      <MenuItem value={'false'}>🟢 Active</MenuItem>
                      <MenuItem value={'true'}>🔴 Blocked</MenuItem>
                    </Select>
                    {popup && <Popup item={customer} handleClose={handleClose} />}
                  </FormControl>
                </Stack>
                <IconButton onClick={() => setEditMode(!editMode)}>
                  {!editMode ? <BorderColor /> : <Close />}
                </IconButton>
              </Stack>
            </Stack>
            {!editMode && (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4}>
                  <DetailItem icon="raphael:customer" title="Name" data={customer?.fullname} />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <DetailItem icon="mdi:email-outline" title="Email" data={customer?.email} />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <DetailItem icon="ri:phone-line" title="Phone" data={customer?.phone} />
                </Grid>
                <Grid item xs={12}>
                  <DetailItem title="Address" icon="la:address-card" data={customer?.address} />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <DetailItem title="Grand Total" icon="mdi:currency-inr" data={paymentData?.grandTotal || 0} />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <DetailItem title="Paid Amount" icon="mdi:currency-inr" data={paymentData?.paidAmount || 0} />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <DetailItem title="Balance Amount" icon="mdi:currency-inr" data={paymentData?.balanceAmount || 0} />
                </Grid>
              </Grid>
            )}
            {editMode && (
              <CustomerEditForm
                customer={customer}
                getCustomer={getCustomer}
                setEditMode={setEditMode}
                editMode={editMode}
              />
            )}
          </Box>
        </>
      )}
    </Page>
  );
}
