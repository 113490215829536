import moment from 'moment';
import {
  Box,
  Card,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Stack,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  FormControlLabel,
  Checkbox,
  IconButton,
  TextField,
} from '@mui/material';
import { toast } from 'react-toastify';
import { ExpandMore } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import Scrollbar from './Scrollbar';
import PaymentFormSalary from './PaymentFormSalary';

function InvoiceTableSalary({ salary }) {
  const [markToPay, setMarkToPay] = useState([]);
  const [markToPayInvoiceNumber, setMarkToPayInvoiceNumber] = useState([]);
  const [expand, setExpand] = useState([...salary?.invoices?.map((invoice) => false)]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [error, setError] = useState(true);

  const handleMarkToPay = (e, item) => {
    if (e.target.checked) {
      setMarkToPayInvoiceNumber([...markToPayInvoiceNumber, item._id]);
      setMarkToPay([...markToPay, { amount: 0, _id: item._id }]);
    } else {
      setMarkToPayInvoiceNumber(markToPayInvoiceNumber.filter((_id) => _id !== item._id));
      setMarkToPay(markToPay.filter((invoice) => invoice._id !== item._id));
    }
  };

  const handleAmountChange = (e, item) => {
    if (e.target.value < 0) {
      toast.error('Amount cannot be negative');
      return;
    }

    const updatedMarkToPay = markToPay.map((invoice) => {
      if (invoice._id === item._id) {
        console.log(e.target.value, 'e.target.value');
        return { ...invoice, amount: e.target.value };
      }
      return invoice;
    });
    console.log(updatedMarkToPay, 'updatedMarkToPay');
    setMarkToPay(updatedMarkToPay);
    const selectedInvoice = salary?.invoices?.find((invoice) => invoice._id === item._id);
    if (parseFloat(Number(selectedInvoice?.amount) - Number(selectedInvoice?.paidAmount)) < e.target.value) {
      setError(true);
      return;
    }
    setError(false);
  };

  useEffect(() => {
    const total = markToPay.reduce((acc, item) => acc + Number(item.amount), 0);
    setTotalAmount(total);
  }, [markToPay]);

  return (
    <Stack spacing={2}>
      <Box padding={'10px'}>
        <Stack spacing={2}>
          <Scrollbar>
            {' '}
            <Typography gutterBottom variant="h5">
              Invoices
            </Typography>
            {salary?.invoices?.map((invoice, index) => (
              <Stack
                key={index}
                direction={'row'}
                px={5}
                py={3}
                justifyContent={'space-between'}
                sx={{
                  width: '100%',
                  backgroundColor: '#fabb0071',
                }}
              >
                {' '}
                <Stack direction={'row'} spacing={1}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={!!markToPayInvoiceNumber.find((item) => item === invoice._id)}
                        onChange={(e) => handleMarkToPay(e, invoice)}
                      />
                    }
                  />
                  <Stack justifyContent="center" direction={'column'}>
                    <Typography variant="h5" fontWeight={'bold'}>
                      {salary?.fullname}
                    </Typography>
                    <Stack direction={'row'} spacing={2}>
                      <Typography variant="body2" fontWeight={'bold'}>
                        Salary Date:
                      </Typography>
                      <Typography variant="body2">{moment(invoice?.salaryDate).format('DD/MM/YYYY')}</Typography>
                    </Stack>
                  </Stack>
                </Stack>
                <Stack direction={'row'} spacing={2} alignItems={'center'}>
                  <Stack direction={'column'} spacing={1}>
                    <TextField
                      label="Balance Amount"
                      size="small"
                      variant="outlined"
                      value={parseFloat(Number(invoice?.amount) - Number(invoice?.paidAmount)).toFixed(2)}
                    />
                    <TextField
                      label="Amount (&#8377;)"
                      size="small"
                      variant="outlined"
                      disabled={!markToPayInvoiceNumber.find((item) => item === invoice._id)}
                      value={markToPay.find((item) => item._id === invoice._id)?.amount || ''}
                      onChange={(e) => handleAmountChange(e, invoice)}
                      error={
                        Number(markToPay.find((item) => item._id === invoice._id)?.amount) >
                        Number(invoice?.amount) - Number(invoice?.paidAmount)
                      }
                      helperText={
                        Number(markToPay.find((item) => item._id === invoice._id)?.amount) >
                        Number(invoice?.amount) - Number(invoice?.paidAmount)
                          ? 'Amount cannot be greater than balance amount'
                          : ''
                      }
                    />
                  </Stack>
                </Stack>
              </Stack>
            ))}
          </Scrollbar>
        </Stack>
      </Box>
      <Card>
        <Box padding={'10px'}>
          <Typography gutterBottom variant="h5" mb={1}>
            Payment Details
          </Typography>
          <PaymentFormSalary invoices={markToPay} salary={salary} totalAmount={totalAmount} error={error} />
        </Box>
      </Card>
    </Stack>
  );
}

export default InvoiceTableSalary;
