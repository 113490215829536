import * as React from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Button } from '@mui/material';

const AddToCustomer = ({ customer, lead, callApi }) => {
  const [loading, setLoading] = React.useState(false);
  const submitForm = async () => {
    setLoading(true);
    try {
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/customer/lead`, {
        fullname: lead?.fullname,
        email: lead?.email,
        phone: lead?.phone,
        address: lead?.address,
        leadId: lead?._id,
        customerId: customer?._id,
      });
      toast.success(response.data.message);
      callApi();
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
      setLoading(false);
    }
  };

  return (
    <>
      <Button variant="contained" onClick={submitForm} disabled={loading}>
        Add to Customer
      </Button>
    </>
  );
};

export default AddToCustomer;
