import { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouterLink, matchPath, useLocation } from 'react-router-dom';
// material
import { alpha, useTheme, styled } from '@mui/material/styles';
import { Box, List, Collapse, ListItemText, ListItemIcon, ListItemButton, Stack, Tooltip } from '@mui/material';
import { useSelector } from 'react-redux';
//
import Iconify from './Iconify';
import StateContext from './Context/Context';

// ----------------------------------------------------------------------

const ListItemStyle = styled((props) => <ListItemButton disableGutters {...props} />)(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: 'relative',
  textTransform: 'capitalize',
  color: theme.palette.primary.secondary,
  borderRadius: theme.shape.borderRadius,
  fontSize: '17px',
}));
const ListItemStyleCenter = styled((props) => <ListItemButton disableGutters {...props} />)(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: 'relative',
  textTransform: 'capitalize',
  color: theme.palette.text.secondary,
  borderRadius: theme.shape.borderRadius,
  justifyContent: 'center',
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  color: 'inherit',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const ListItemStyle1 = styled((props) => <ListItemButton disableGutters {...props} />)(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: 'relative',
  color: theme.palette.text.secondary,
  borderRadius: theme.shape.borderRadius,
  justifyContent: 'center',
}));

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.func,
};

function NavItem({ item, active }) {
  const theme = useTheme();
  const { accType, role } = useSelector((state) => state.user.userInfo);
  const { dispatch, state } = useContext(StateContext);

  const isActiveRoot = active(item.path);

  const { title, path, icon, info, children } = item;

  const [open, setOpen] = useState(isActiveRoot);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const activeRootStyle = {
    color: 'primaryDark.main',
    fontWeight: 'fontWeightMedium',
    bgcolor: alpha(theme.palette.primaryDark.main, theme.palette.action.selectedOpacity),
  };

  const activeSubStyle = {
    color: 'primaryDark.main',
    fontWeight: 'fontWeightMedium',
    bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
  };

  if (children) {
    return (
      <>
        <Tooltip title={!state ? title : ''} placement="right" arrow>
          <ListItemStyle
            onClick={handleOpen}
            sx={{
              ...(isActiveRoot && activeRootStyle),
            }}
          >
            <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
            <ListItemText
              disableTypography
              primary={title}
              sx={{
                visibility: state ? 'visible' : 'hidden',
                opacity: state ? 1 : 0,
                transition: state ? 'all 0.5s ease-in-out' : 'all 0s ease-in-out',
              }}
            />
            {info && info}
            <Iconify
              icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
              sx={{ width: 16, height: 16, mr: 1 }}
            />
          </ListItemStyle>
        </Tooltip>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children
              .filter((item) => {
                const hasPermission = item.permission.includes(accType);
                const hasRole = role.some((r) => item.permission.includes(r.name));
                return hasPermission || hasRole;
              })
              .map((item) => {
                const { title, path, icon } = item;
                const isActiveSub = active(path);

                return (
                  <Tooltip  key={title} title={!state ? title : ''} placement="right" arrow>
                    <ListItemStyle
                      component={RouterLink}
                      to={path}
                      sx={{
                        ...(isActiveSub && activeSubStyle),
                        pl: !state ? 0 : 2,
                      }}
                    >
                      <ListItemIconStyle>
                        <Iconify
                          icon={icon}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: 'text.secondary',
                            width: 24,
                            height: 24,
                            transition: (theme) => theme.transitions.create('transform'),
                            ...(isActiveSub && {
                              color: 'primaryDark.main',
                            }),
                          }}
                        />
                      </ListItemIconStyle>
                      <ListItemText
                        disableTypography
                        primary={title}
                        sx={{
                          visibility: state ? 'visible' : 'hidden',
                          opacity: state ? 1 : 0,
                        }}
                      />
                    </ListItemStyle>
                  </Tooltip>
                );
              })}
          </List>
        </Collapse>
      </>
    );
  }

  return (
    <Tooltip title={!state ? title : ''} placement="right" arrow>
      <ListItemStyle
        component={RouterLink}
        to={path}
        sx={{
          ...(isActiveRoot && activeRootStyle),
        }}
      >
        <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
        <ListItemText
          sx={{
            visibility: state ? 'visible' : 'hidden',
            opacity: state ? 1 : 0,
            transition: state ? 'all 0.5s ease-in-out' : 'all 0s ease-in-out',
          }}
          disableTypography
          primary={title}
        />
        {info && info}
      </ListItemStyle>
    </Tooltip>
  );
}

NavSection.propTypes = {
  navConfig: PropTypes.array,
};

export default function NavSection({ navConfig, ...other }) {
  const { pathname } = useLocation();
  const { accType, role } = JSON.parse(localStorage.getItem('profile'));

  const match = (path) => (path ? !!matchPath({ path, end: false }, pathname) : false);

  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {navConfig
          .filter((item) => {
            const hasAccType = item.permission.includes(accType);
            const hasRole = role?.some((r) => item.permission.includes(r.name));
            return hasAccType || hasRole;
          })
          .map((item) => (
            <NavItem key={item.title} item={item} active={match} />
          ))}
      </List>
    </Box>
  );
}
