import * as React from 'react';
import axios from 'axios';
import { Formik, Form } from 'formik';
import { toast } from 'react-toastify';
import {
  TextField,
  Button,
  Stack,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import Iconify from '../Iconify';

const LeadToCustomer = ({ lead, name, type, callApi }) => {
  const [open, setOpen] = React.useState(false);
  const [phoneAvailable, setPhoneAvailable] = React.useState(true);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const checkPhone = async (phone) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/customer/phone/available`, {
        params: {
          phone,
        },
      });
      console.log(response.data.data, 'phone available');
      return response.data.data;
    } catch (error) {
      console.log(error);
    }
  };



  return (
    <>
      <div>
        <Box style={{ display: 'flex', justifyContent: 'end' }}>
          <Button variant="contained" onClick={handleClickOpen}>
            {name}
          </Button>
          <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
              {type === 'convert' ? 'Convert Lead to Customer' : 'Add Customer'}
            </DialogTitle>
            <DialogContent>
              <DialogContentText sx={{ mb: 2 }}>
                {type === 'convert' ? 'Enter customer details to convert lead' : 'Add details of the customer'}.
              </DialogContentText>
              <Formik
                initialValues={{
                  fullname: lead?.fullname || '',
                  email: lead?.email || '',
                  phone: lead?.phone || '',
                  address: lead?.address || '',
                  leadId: lead?._id || '',
                }}
                onSubmit={async (values, actions) => {
                  const phoneAvailable = await checkPhone(values.phone);
                  if (!phoneAvailable) {
                    actions.setFieldError('phone', 'Phone number is not available');
                    actions.setSubmitting(false);
                    return;
                  }
                  try {
                    const response = await axios.post(
                      `${process.env.REACT_APP_API_URL}/customer${type === 'convert' ? '/lead' : ''}`,
                      values
                    );
                    actions.setSubmitting(false);
                    toast.success(response.data.message);
                    handleClose();
                    callApi();
                  } catch (error) {
                    console.log(error);
                    actions.setSubmitting(false);
                    toast.error(error.response.data.message);
                  }
                }}
              >
                {(formik) => (
                  <Form>
                    <Stack spacing={2} sx={{ minWidth: '300px' }}>
                      <TextField
                        fullWidth
                        id="fullname"
                        name="fullname"
                        label="Name"
                        value={formik.values.fullname}
                        onChange={formik.handleChange}
                      />
                      <TextField
                        fullWidth
                        id="email"
                        name="email"
                        label="Email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                      />
                      <TextField
                        fullWidth
                        id="phone"
                        name="phone"
                        label="Phone"
                        value={formik.values.phone}
                        onChange={formik.handleChange}
                        error={Boolean(formik.errors.phone)}
                        helperText={formik.errors.phone}
                      />
                      <TextField
                        fullWidth
                        id="address"
                        name="address"
                        multiline
                        label="Address"
                        value={formik.values.address}
                        onChange={formik.handleChange}
                      />
                      <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button type="submit" variant="contained" disabled={formik.isSubmitting}>
                          {type === 'convert' ? 'Convert' : 'Create'}
                        </Button>
                      </DialogActions>
                    </Stack>
                  </Form>
                )}
              </Formik>
            </DialogContent>
          </Dialog>
        </Box>
      </div>
    </>
  );
};

export default LeadToCustomer;
