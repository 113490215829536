import { useState } from 'react';
// material
import { Container, Typography, Card, Tab, Box, Stack } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';

// components
import Page from '../components/Page';
import BillTable from '../components/BillTable';

export default function Bills() {
  const [value, setValue] = useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Page title="Bills">
        <Container maxWidth="xl">
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="h4" gutterBottom>
              Bills
            </Typography>
          </Stack>
          <Card>
            <Box sx={{ width: '100%', marginTop: '20px' }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList onChange={handleChange}>
                    <Tab label="Pending" value="1" />
                    <Tab label="Completed" value="2" />
                  </TabList>
                </Box>
                <TabPanel value="1">
                  <BillTable status={'pending'} />
                </TabPanel>
                <TabPanel value="2">
                  <BillTable status={'completed'} />
                </TabPanel>
              </TabContext>
            </Box>
          </Card>
        </Container>
      </Page>
    </>
  );
}
