import { useState, useEffect } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import axios from 'axios';
import Moment from 'moment';
// material
import { styled } from '@mui/material/styles';
import {
  Card,
  Table,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  CircularProgress,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  FormControl,
  Select,
  Icon,
  Container,
  Typography,
  MenuItem,
  TableContainer,
  TablePagination,
  TextField,
  Grid,
  IconButton,
  OutlinedInput,
  InputAdornment,
  useMediaQuery,
  Avatar,
} from '@mui/material';
import { Box } from '@mui/system';
// import CopyToClipboard from 'react-copy-to-clipboard';
import { Add, ArrowBack, ContentCopy } from '@mui/icons-material';
import CopyToClipboard from 'react-copy-to-clipboard';
import { LoadingButton } from '@mui/lab';
import { useFormik, Form, FormikProvider } from 'formik';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import AddIcon from '@mui/icons-material/Add';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
// import Filter from '../components/filters/ProjectsTable';

// components
import Scrollbar from './Scrollbar';
import Iconify from './Iconify';
import Popup from './popups/TeleCallerStatusChange ';
import AddProjectAtCustomer from './popups/AddProjectAtCustomer';

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

export default function ProjectsTable({ customer }) {
  const navigate = useNavigate();
  const { accType, role: accountRole } = useSelector((state) => state.user.userInfo);
  // const [searchParams, setSearchParams] = useSearchParams();
  // const managerId = searchParams.get('managerId') || null;

  const matches = useMediaQuery('(max-width:600px)');
  const [projectsData, setProjectsData] = useState();
  const [name, setName] = useState('');
  const [pages, setPages] = useState(0);
  const [projectsCount, setProjectsCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState();
  const [size, setSize] = useState(10);
  const [options, setOptions] = useState({ size, page: 0 });
  const [open, setOpen] = useState(false);

  const handleStatusChange = (event, item, index) => {
    console.log('item', item);
    if (`${item.isBlocked}` === event.target.value) {
      return;
    }
    console.log(index);
    setSelectedIndex(index);
  };

  const handleClose = (refresh = false, message = '') => {
    setSelectedIndex();
    if (refresh) {
      getProjects(options);
    }
    if (message) {
      toast(message);
    }
  };

  const handlePageChange = (event, newPage) => {
    const temp = { ...options, page: newPage };
    setPages(newPage);
    setOptions(temp);
    setIsLoading(true);
    getProjects(temp);
  };

  const getProjects = (options) => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/project/all`, {
        params: {
          ...options,
          customerId: customer ? customer._id : null,
        },
      })
      .then((res) => {
        console.log('tele caller', res);
        if (res.data.isError === false) {
          setIsLoading(false);
          setProjectsCount(res.data.data.maxRecords);
          setProjectsData(res.data.data.records);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const getProjectsWithoutLoading = (options) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/project/all`, {
        params: {
          ...options,
          customerId: customer ? customer._id : null,
        },
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setProjectsCount(res.data.data.maxRecords);
          setProjectsData(res.data.data.records);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const onSearch = (e) => {
    e.preventDefault();
    setName(e.target.value);
    const temp = { ...options, page: 0, name: e.target.value };
    setOptions(temp);
    setPages(0);
    getProjectsWithoutLoading(temp);
  };

  const callApi = () => {
    getProjects(options);
  };

  const applyFilters = (filter) => {
    console.log('filter', filter);
    const temp = { page: 0, size };
    if (filter.fullname.length) {
      temp.fullname = filter.fullname;
    }
    if (filter.isBlocked.length) {
      temp.isBlocked = filter.isBlocked.trim();
    }
    if (filter.email.length) {
      temp.email = filter.email;
    }
    if (filter.id.length) {
      temp.id = filter.id;
    }

    setOptions(temp);
    setPages(0);
    getProjects(temp);
  };
  useEffect(() => {
    getProjects(options);
  }, []);

  useEffect(() => {
    const temp = { ...options, size, page: 0 };
    setPages(0);
    setOptions(temp);
    getProjects(temp);
  }, [size]);

  return (
    <>
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <Container maxWidth="xl">
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Stack direction="row" spacing={2} padding={2}>
                <SearchStyle
                  value={name}
                  onChange={onSearch}
                  placeholder="Search Project Name..."
                  startAdornment={
                    <InputAdornment position="start">
                      <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                    </InputAdornment>
                  }
                />
              </Stack>
              <Stack padding={1} direction="row" justifyContent="flex-end" spacing={2} alignItems="center">
                <Button startIcon={<Add />} variant="contained" onClick={() => setOpen(true)}>
                  PROJECT
                </Button>
                <AddProjectAtCustomer customerId={customer?._id} open={open} setOpen={setOpen} callApi={callApi} />
                {/* <Filter applyFilters={applyFilters} /> */}
              </Stack>
            </Stack>

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Sl No</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Location</TableCell>
                      <TableCell>Manager</TableCell>
                      <TableCell>ID</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {projectsData?.map((item, id) => (
                      <TableRow key={id} sx={{ '&:hover': { backgroundColor: '#f5f5f5' } }}>
                        <TableCell>{pages * size + (id + 1)}</TableCell>
                        <TableCell>{item?.name}</TableCell>
                        <TableCell>{item.location}</TableCell>
                        <TableCell>{item.manager}</TableCell>
                        <TableCell style={{ cursor: 'pointer', width: '80px' }}>
                          <Stack direction="column" spacing={0.5} width="80px">
                            <CopyToClipboard text={item._id} onCopy={() => toast.info('Telecaller ID copied')}>
                              <Stack direction="row" alignItems="center" spacing={0.5} justifyContent="flex-start">
                                <ContentCopy color="primaryDark" />
                                <Typography color="primaryDark.main" variant="p">
                                  Copy ID
                                </Typography>
                              </Stack>
                            </CopyToClipboard>
                          </Stack>
                        </TableCell>
                        <TableCell>
                          <FormControl sx={{ minWidth: 100 }}>
                            <Select
                              size="small"
                              value={item.isBlocked}
                              onChange={(e) => handleStatusChange(e, item, id)}
                            >
                              <MenuItem value={'false'}>🟢 Active</MenuItem>
                              <MenuItem value={'true'}>🔴 Blocked</MenuItem>
                            </Select>
                            {selectedIndex === id && <Popup item={item} handleClose={handleClose} />}
                          </FormControl>
                        </TableCell>
                        <TableCell>
                          <Box>
                            <Link to={`/dashboard/project/view/${item._id}`}>
                              <Icon sx={{ color: 'gray' }}>
                                <RemoveRedEyeIcon />
                              </Icon>
                            </Link>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
            <TablePagination
              rowsPerPageOptions={[10, 20, 30]}
              onRowsPerPageChange={(e) => {
                setSize(e.target.value);
              }}
              component={'div'}
              count={projectsCount}
              showFirstButton
              showLastButton
              onPageChange={handlePageChange}
              rowsPerPage={size}
              page={pages}
            />
          </Container>
        </>
      )}
    </>
  );
}
