import { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import axios from 'axios';
import Moment from 'moment';
// material
import { styled } from '@mui/material/styles';
import {
  Card,
  Table,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  CircularProgress,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  FormControl,
  Select,
  Icon,
  Container,
  Typography,
  MenuItem,
  TableContainer,
  TablePagination,
  TextField,
  Grid,
  IconButton,
  Toolbar,
  OutlinedInput,
  InputAdornment,
  useMediaQuery,
  Autocomplete,
  Box,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik, Form, FormikProvider } from 'formik';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

export default function LeadEditForm({ lead, getLead, setEditMode, editMode }) {
  const { accType, role } = useSelector((state) => state.user.userInfo);
  const admin = accType === 'SUPER_ADMIN' || accType === 'SUB_ADMIN';
  const isLeadManager = role && role.length && role.some((r) => r.name === 'LEAD_MANAGER');
  const isTelecaller = role && role.length && role.some((r) => r.name === 'TELE_CALLER');

  const [leadManagers, setLeadManagers] = useState([]);
  const [telecallers, setTelecallers] = useState([]);
  const [sources, setSources] = useState([]);
  const [categories, setCategories] = useState([]);
  const [leadManagersLoading, setLeadManagersLoading] = useState(true);
  const [telecallersLoading, setTelecallersLoading] = useState(false);
  const [sourcesLoading, setSourcesLoading] = useState(true);
  const [categoriesLoading, setCategoriesLoading] = useState(true);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState({});
  const [status, setStatus] = useState([]);
  const [clearTelecaller, setClearTelecaller] = useState(false);

  const formik = useFormik({
    initialValues: {
      id: lead?._id,
      fullname: lead?.fullname,
      email: lead?.email,
      phone: lead?.phone,
      address: lead?.address,
      source: lead?.source?._id,
      leadManager: '',
      teleCaller: '',
      category: '',
      status: lead?.status?._id,
    },
    onSubmit: async () => {
      try {
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/lead`, values);
        if (!res.data.isError) {
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
        setEditMode(false);
        getLead();
        resetForm();
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm, setFieldValue } = formik;

  const getLeadManager = async () => {
    try {
      setLeadManagersLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/leadTeam/leadManager/list`);
      console.log('lead managers list', res.data.data.records);
      if (!res.data.isError) {
        if (res.data.data.records) {
          setLeadManagers(res.data.data.records);
        }
      }
      setLeadManagersLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getTelecallers = async (managerId) => {
    try {
      setTelecallersLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/leadTeam/leadManager/telecallers/all`, {
        params: {
          managerId,
        },
      });
      console.log('telecallers list', res.data.data);
      if (!res.data.isError) {
        if (res.data.data.telecallers) {
          setTelecallers(res.data.data.telecallers);
        }
      }
      setTelecallersLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getSources = async () => {
    try {
      setSourcesLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/leadSource/all/active`);
      console.log('sources list', res.data.data.status);
      if (!res.data.isError) {
        if (res.data.data.status) {
          setSources(res.data.data.status);
        }
      }
      setSourcesLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getCategories = async () => {
    try {
      setCategoriesLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/leadCategory/all/active`);
      console.log('categories list', res.data.data.status);
      if (!res.data.isError) {
        if (res.data.data.status) {
          setCategories(res.data.data.status);
        }
      }
      setCategoriesLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getStatus = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/leadStatus/all/active`);
      console.log('status list', res.data.data.status);
      if (!res.data.isError) {
        if (res.data.data.status) {
          setStatus(res.data.data.status);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getStatusColor = (statusName) => {
    const statusMapping = {
      Pending: '#fff100',
      Ongoing: '#fff100',
      'Decision Pending': '#fff100',
      Interested: '#16c60c',
      'Not Interested': '#e81224',
    };
    return statusMapping[statusName] || 'white';
  };

  useEffect(() => {
    if (admin) {
      getLeadManager();
    }
    getSources();
    getCategories();
    getStatus();
  }, [accType, role]);

  useEffect(() => {
    if (admin) {
      if (values.leadManager) {
        getTelecallers(values.leadManager);
      } else {
        getTelecallers(lead?.leadManager?._id);
      }
    }
  }, [values.leadManager.accType]);

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Fullname"
              {...getFieldProps('fullname')}
              error={Boolean(touched.fullname && errors.fullname)}
              helperText={touched.fullname && errors.fullname}
              disabled={!editMode}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Email"
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
              disabled={!editMode}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Phone"
              {...getFieldProps('phone')}
              error={Boolean(touched.phone && errors.phone)}
              helperText={touched.phone && errors.phone}
              disabled={!editMode}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              select
              label="Status"
              {...getFieldProps('status')}
              error={Boolean(touched.status && errors.status)}
              helperText={touched.status && errors.status}
              disabled={!editMode}
            >
              {status.map((item, key) => (
                <MenuItem key={key} value={item._id}>
                  <Stack direction="row" spacing={0.5} alignItems="center">
                    <Box
                      sx={{
                        width: 14,
                        height: 14,
                        backgroundColor: getStatusColor(item.name),
                        borderRadius: '50%',
                      }}
                    />
                    <Box>{item.name}</Box>
                  </Stack>
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              label="Address"
              {...getFieldProps('address')}
              error={Boolean(touched.address && errors.address)}
              helperText={touched.address && errors.address}
              disabled={!editMode}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            {editMode && (
              <Autocomplete
                id="asynchronous-demo"
                fullWidth
                open={open3}
                onOpen={() => {
                  setOpen3(true);
                }}
                onClose={() => {
                  setOpen3(false);
                }}
                defaultValue={lead?.source ? lead.source : null}
                isOptionEqualToValue={(option, value) => option.name === value.name}
                getOptionLabel={(option) => option.name}
                options={sources}
                loading={sourcesLoading}
                onChange={(_, value) => {
                  if (value) {
                    formik.setFieldValue('source', value._id);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    // error={Boolean(touched.source && errors.source)}
                    // helperText={touched.source && errors.source}
                    label="Source"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {sourcesLoading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            )}
            {!editMode && lead?.source && <TextField fullWidth label="Source" value={lead.source.name} disabled />}
          </Grid>
          <Grid item xs={12} md={6}>
            {editMode && (
              <Autocomplete
                id="asynchronous-demo"
                fullWidth
                open={open4}
                onOpen={() => {
                  setOpen4(true);
                }}
                onClose={() => {
                  setOpen4(false);
                }}
                defaultValue={lead?.category ? lead.category : null}
                isOptionEqualToValue={(option, value) => option.name === value.name}
                getOptionLabel={(option) => option.name}
                options={categories}
                loading={categoriesLoading}
                onChange={(_, value) => {
                  if (value) {
                    formik.setFieldValue('category', value._id);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    // error={Boolean(touched.category && errors.category)}
                    // helperText={touched.category && errors.category}
                    label="Category"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {categoriesLoading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            )}
            {!editMode && lead.category && <TextField fullWidth label="Category" value={lead.category.name} disabled />}
          </Grid>

          <Grid item xs={12} md={6}>
            {editMode && admin && (
              <Autocomplete
                id="asynchronous-demo"
                fullWidth
                open={open1}
                onOpen={() => {
                  setOpen1(true);
                }}
                onClose={() => {
                  setOpen1(false);
                }}
                defaultValue={lead.leadManager ? lead.leadManager : null}
                isOptionEqualToValue={(option, value) => option.fullname === value.fullname}
                getOptionLabel={(option) => option.fullname}
                options={leadManagers}
                loading={leadManagersLoading}
                onChange={(_, value) => {
                  if (value) {
                    setTelecallers([]);
                    setClearTelecaller(true);
                    formik.setFieldValue('teleCaller', null);
                    formik.setFieldValue('leadManager', value._id);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Lead Manager"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {leadManagersLoading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            )}
            {((!editMode && lead.leadManager) || !admin) && (
              <TextField fullWidth label="Lead Manager" value={lead.leadManager.fullname} disabled />
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            {editMode && admin && (
              <Autocomplete
                id="asynchronous-demo"
                fullWidth
                open={open2}
                onOpen={() => {
                  setOpen2(true);
                }}
                onClose={() => {
                  setOpen2(false);
                }}
                defaultValue={lead.teleCaller ? lead.teleCaller : null}
                isOptionEqualToValue={(option, value) => option.fullname === value.fullname}
                getOptionLabel={(option) => option.fullname}
                options={telecallers}
                loading={telecallersLoading}
                onChange={(_, value) => {
                  if (value) {
                    formik.setFieldValue('teleCaller', value._id);
                  }
                }}
                renderInput={(params) => {
                  console.log(params, 'params');
                  return (
                    <TextField
                      {...params}
                      label="Telecaller"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {telecallersLoading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  );
                }}
              />
            )}
            {((!editMode && lead.leadManager) || !admin) && (
              <TextField fullWidth label="Telecaller" value={lead.teleCaller.fullname} disabled />
            )}
          </Grid>
        </Grid>
        {editMode && (
          <Stack direction={'row'} justifyContent="flex-end" spacing={1} paddingTop="20px">
            <Button variant="contained" onClick={() => setEditMode(false)}>
              Cancel
            </Button>
            <LoadingButton variant="contained" type="submit" loading={isSubmitting}>
              Update
            </LoadingButton>
          </Stack>
        )}
      </Form>
    </FormikProvider>
  );
}
