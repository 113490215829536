import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import axios from 'axios';
import moment from 'moment';
// material
import { styled } from '@mui/material/styles';
import {
  Card,
  Table,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  CircularProgress,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  FormControl,
  Select,
  Icon,
  Container,
  Typography,
  MenuItem,
  TableContainer,
  TablePagination,
  TextField,
  Grid,
  IconButton,
  Toolbar,
  OutlinedInput,
  InputAdornment,
  useMediaQuery,
  Avatar,
  chip,
} from '@mui/material';
import { Box } from '@mui/system';
import { ContentCopy } from '@mui/icons-material';
import CopyToClipboard from 'react-copy-to-clipboard';
import { LoadingButton } from '@mui/lab';
import { useFormik, Form, FormikProvider } from 'formik';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import AddIcon from '@mui/icons-material/Add';
import ModeEditSharpIcon from '@mui/icons-material/ModeEditSharp';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import Filter from '../components/filters/Ledgers';

// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import Popup from '../components/popups/LedgerStatusChange';

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

const ledgerAccountTypes = [
  {
    name: 'Assets',
    children: ['Current Asset', 'Bank', 'Cash', 'Fixed Asset', 'Stock'],
    _id: 1,
  },
  {
    name: 'Liability',
    children: ['Current Liability', 'Long-term Liability', 'Capital'],
    _id: 2,
  },
  {
    name: 'Income',
    children: ['Direct Income', 'Indirect Income'],
    _id: 3,
  },
  {
    name: 'Expense',
    children: ['Direct Expense', 'Indirect Expense'],
    _id: 4,
  },
  {
    name: 'Capital',
    children: ['Capital'],
    _id: 5,
  },
];

export default function Ledgers() {
  const matches = useMediaQuery('(max-width:600px)');
  const [ledgerData, setLedgerData] = useState([]);
  const [name, setName] = useState('');
  const [pages, setPages] = useState(0);
  const [ledgerCount, setLedgerCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState();
  const [selectedIndex1, setSelectedIndex1] = useState();
  const [size, setSize] = useState(10);
  const [options, setOptions] = useState({ size, page: 0 });
  const [open, setOpen] = useState(false);
  const [input, setInput] = useState(1);
  const mobileRegExp = '^[6789][0-9]{9}';

  const ledgerSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    accountNumber: Yup.string().optional('Account Number is required'),
    accountType: Yup.string().required('Account Type is required'),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      accountNumber: '',
      ifsc: '',
      accountHolderName: '',
      notes: '',
      isBlocked: 'false',
      accountType: '',
      accountCategory: '',
    },
    validationSchema: ledgerSchema,
    onSubmit: async () => {
      try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/ledger`, values);
        if (!res.data.isError) {
          closeAddLedgerModal(res.data.message);
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
        getLedgers(options);
        resetForm();
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  const handleStatusChange = (event, item, index) => {
    console.log('item', item);
    if (`${item.isBlocked}` === event.target.value) {
      return;
    }
    console.log(index);
    setSelectedIndex(index);
  };

  const handleClose = (refresh = false, message = '') => {
    setSelectedIndex();
    setSelectedIndex1();
    if (refresh) {
      getLedgers(options);
    }
    if (message) {
      toast(message);
    }
  };
  const openAddLedgerModal = () => {
    setOpen(true);
  };
  const closeAddLedgerModal = () => {
    setOpen(false);
    formik.resetForm();
  };

  const getLedgers = (options) => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/ledger/all`, {
        params: options,
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setLedgerData(res.data.data.records);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const getLedgersWithoutLoading = (options) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/ledger/all`, {
        params: options,
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setLedgerCount(res.data.data.maxRecords);
          setLedgerData(res.data.data.records);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const onSearch = (e) => {
    e.preventDefault();
    setName(e.target.value);
    const temp = { ...options, page: 0, name: e.target.value };
    setOptions(temp);
    setPages(0);
    getLedgersWithoutLoading(temp);
  };

  const applyFilters = (filter) => {
    console.log('filter', filter);
    const temp = { page: 0, size };
    // if (filter.name.length) {
    //   temp.name = filter.name;
    // }
    if (filter.isBlocked.length) {
      temp.isBlocked = filter.isBlocked.trim();
    }
    if (filter.isPopular.length) {
      temp.isPopular = filter.isPopular;
    }

    setOptions(temp);
    getLedgers(temp);
  };
  useEffect(() => {
    getLedgers(options);
  }, []);

  useEffect(() => {
    const temp = { ...options };
    setOptions(temp);
    getLedgers(temp);
  }, [size]);

  return (
    <Page title="Ledgers">
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          {/* Add ledger Dialog */}
          <Dialog open={open} onClose={closeAddLedgerModal} fullScreen={matches}>
            <DialogTitle>Add Ledger</DialogTitle>
            <Box paddingBottom={'10px'} width={{ xs: '100%', sm: '600px' }}>
              <DialogContent paddingTop="20px">
                <FormikProvider value={formik}>
                  <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label="Name"
                          {...getFieldProps('name')}
                          error={Boolean(touched.name && errors.name)}
                          helperText={touched.name && errors.name}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="subtitle2" sx={{ mb: 1.5 }}>
                          Account Type
                        </Typography>
                        <Stack direction="row" spacing={2}>
                          <TextField
                            fullWidth
                            select
                            onChange={(e) => {
                              formik.setFieldValue('accountCategory', e.target.value);
                              formik.setFieldValue('accountType', '');
                            }}
                          >
                            {ledgerAccountTypes.map((option) => (
                              <MenuItem key={option._id} value={option.name}>
                                {option.name}
                              </MenuItem>
                            ))}
                          </TextField>
                          <TextField
                            fullWidth
                            select
                            {...getFieldProps('accountType')}
                            error={Boolean(touched.accountType && errors.accountType)}
                            helperText={touched.accountType && errors.accountType}
                          >
                            {ledgerAccountTypes.map((option) => {
                              if (option.name === formik.values.accountCategory) {
                                return option.children.map((child) => (
                                  <MenuItem key={child} value={child}>
                                    {child}
                                  </MenuItem>
                                ));
                              }
                              return null; // Add a default return statement here
                            })}
                          </TextField>
                        </Stack>
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          label="Account Number"
                          {...getFieldProps('accountNumber')}
                          error={Boolean(touched.accountNumber && errors.accountNumber)}
                          helperText={touched.accountNumber && errors.accountNumber}
                        />
                      </Grid>
                      {values.accountType === 'Bank' && (
                        <Grid item xs={12} md={6}>
                          <TextField fullWidth label="IFSC Code" {...getFieldProps('ifsc')} />
                        </Grid>
                      )}

                      {values.accountType === 'Bank' && (
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            label="Account Holder Name"
                            {...getFieldProps('accountHolderName')}
                            error={Boolean(touched.accountHolderName && errors.accountHolderName)}
                            helperText={touched.accountHolderName && errors.accountHolderName}
                          />
                        </Grid>
                      )}
                      <Grid item xs={12} md={6}>
                        <TextField select fullWidth label="Status" {...getFieldProps('isBlocked')}>
                          <MenuItem value={'false'}>Active</MenuItem>
                          <MenuItem value={'true'}>Blocked</MenuItem>
                        </TextField>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField fullWidth label="Notes" multiline {...getFieldProps('notes')} />
                      </Grid>
                    </Grid>
                    <Stack direction={'row'} justifyContent="flex-end" paddingTop="20px">
                      <Button onClick={closeAddLedgerModal}>Cancel</Button>
                      <LoadingButton type="submit" loading={isSubmitting}>
                        Add Ledger
                      </LoadingButton>
                    </Stack>
                  </Form>
                </FormikProvider>
              </DialogContent>
            </Box>
          </Dialog>
          {/* Add ledger dialog end */}
          <Container maxWidth="xl">
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography variant="h4" gutterBottom>
                Ledgers
              </Typography>
            </Stack>
            <Card>
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Stack direction="row" spacing={2} padding={2}>
                  <SearchStyle
                    value={name}
                    onChange={onSearch}
                    placeholder="Search ledger..."
                    startAdornment={
                      <InputAdornment position="start">
                        <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                      </InputAdornment>
                    }
                  />
                </Stack>
                <Stack padding={1} direction="row" justifyContent="flex-end" spacing={2} alignItems="center">
                  <Button startIcon={<AddIcon />} variant="contained" onClick={openAddLedgerModal}>
                    Add Ledger
                  </Button>

                  {/* <Filter applyFilters={applyFilters} /> */}
                </Stack>
              </Stack>

              <Scrollbar>
                <Box p={2}>
                  {ledgerData.map((record, index) => (
                    <Box key={index} marginTop={3}>
                      <Typography variant="h5"> {record.accountCategory}</Typography>
                      {record.accountTypes.map((accountType, index) => (
                        <Box key={index} marginX={4} marginTop={2}>
                          <Typography variant="h6">
                            {index + 1}. {accountType.accountType}
                          </Typography>
                          <Card key={index} marginX={4}>
                            <TableContainer>
                              <Table size="small">
                                <TableHead>
                                  <TableRow>
                                    <TableCell sx={{ width: '250px' }}>Name</TableCell>
                                    <TableCell sx={{ width: '200px' }}>Account Number</TableCell>
                                    {accountType.accountType === 'Bank' && (
                                      <TableCell sx={{ width: '200px' }}>Account Holder Name</TableCell>
                                    )}
                                    {accountType.accountType === 'Bank' && (
                                      <TableCell sx={{ width: '200px' }}>IFSC Code</TableCell>
                                    )}
                                    <TableCell sx={{ width: '120px' }}>Status</TableCell>
                                    <TableCell sx={{ width: '250px' }}>Notes</TableCell>
                                    <TableCell sx={{ width: '150px' }}>Balance</TableCell>
                                    <TableCell>Action</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {accountType.docs.map((doc, index) => (
                                    <TableRow key={index}>
                                      <TableCell>{doc.name}</TableCell>
                                      <TableCell>{doc.accountNumber}</TableCell>
                                      {accountType.accountType === 'Bank' && (
                                        <TableCell>{doc.accountHolderName}</TableCell>
                                      )}
                                      {accountType.accountType === 'Bank' && <TableCell>{doc.ifsc}</TableCell>}
                                      <TableCell>
                                        <FormControl sx={{ minWidth: 100 }}>
                                          <Select
                                            size="small"
                                            value={doc.isBlocked}
                                            onChange={(e) => handleStatusChange(e, doc, doc._id)}
                                          >
                                            <MenuItem value={'false'}>🟢 Active</MenuItem>
                                            <MenuItem value={'true'}>🔴 Blocked</MenuItem>
                                          </Select>
                                          {selectedIndex === doc._id && <Popup item={doc} handleClose={handleClose} />}
                                        </FormControl>
                                      </TableCell>
                                      <TableCell>{doc.notes}</TableCell>
                                      <TableCell>
                                        <Stack direction="column" spacing={1}>
                                          <Typography variant="caption">
                                            Credit : {doc.ledgerData && doc.ledgerData.totalCredit}
                                          </Typography>
                                          <Typography variant="caption">
                                            Debit : {doc.ledgerData && doc.ledgerData.totalDebit}
                                          </Typography>
                                          <Typography variant="caption">
                                            Balance : {doc.ledgerData && doc.ledgerData.balance}
                                          </Typography>
                                        </Stack>
                                      </TableCell>
                                      <TableCell>
                                        <TableCell>
                                          <Box>
                                            <Link to={`/dashboard/accounts/payments?ledgerId=${doc._id}`}>
                                              <Icon sx={{ color: 'gray' }}>
                                                <RemoveRedEyeIcon />
                                              </Icon>
                                            </Link>
                                          </Box>
                                        </TableCell>
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Card>
                        </Box>
                      ))}
                    </Box>
                  ))}
                </Box>
              </Scrollbar>
            </Card>
          </Container>
        </>
      )}
    </Page>
  );
}
