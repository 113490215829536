import axios from 'axios';
/* eslint-disable react/prop-types */
import { BorderColor, Edit, RemoveRedEye } from '@mui/icons-material';
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import CopyToClipboard from 'react-copy-to-clipboard';

import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import EmployeeEditForm from './EmployeeEditForm';
import Iconify from './Iconify';
import Popup from './popups/EmployeeStatusChange';

function DetailItem({ icon, title, data, background, sx, onClick }) {
  return (
    <Stack
      alignItems="center"
      spacing={1}
      direction="row"
      sx={{ height: '100%', background: '#f0f0f0', minHeight: '65px', borderRadius: '5px', ...sx }}
      onClick={onClick}
    >
      <Stack
        direction="row"
        alignItems="center"
        sx={{ height: '100%', borderRadius: '5px 0px 0px 5px' }}
        background={background}
        padding={1}
      >
        <Iconify icon={icon} style={{ height: '25px', width: '25px' }} />
      </Stack>

      <Stack direction="column" sx={{ padding: 1 }}>
        <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>{title}</Typography>
        <Typography style={{ wordBreak: 'break-all' }}>{data}</Typography>
      </Stack>
    </Stack>
  );
}

export default function EmployeeBasicInfo({ employee, getEmployee, managerId }) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [popup, setPopup] = useState(false);
  const [isTeleCaller, setIsTeleCaller] = useState(false);
  const [isLeadManager, setIsLeadManager] = useState(false);
  const [paymentData, setPaymentData] = useState({});

  const handleStatusChange = (event, item, index) => {
    console.log('item', item);
    if (`${item.isBlocked}` === event.target.value) {
      return;
    }
    setPopup(true);
  };

  const handleClose = (refresh = false, message = '') => {
    setPopup(false);
    if (refresh) {
      getEmployee();
    }
    if (message) {
      toast(message);
    }
  };

  const getIcon = (role) => {
    switch (role) {
      case 'TELE_CALLER':
        return 'mdi:phone-outline';
      case 'LEAD_MANAGER':
        return 'mdi:account-tie';
      default:
        return 'mdi:account';
    }
  };

  const setChipColor = (role) => {
    switch (role) {
      case 'TELE_CALLER':
        return 'bluee';
      case 'LEAD_MANAGER':
        return 'greenish';
      default:
        return 'primary';
    }
  };

  const getPaymentData = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/payment/salary/all`, {
        params: { employeeId: employee?._id },
      });
      setIsLoading(false);
      setPaymentData(res.data.data.records);
      console.log('res', res);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (employee) {
      getPaymentData();
      setIsLoading(!employee.fullname);
      const hasRole = (roleName) => employee.role?.some((role) => role.name === roleName);
      setIsTeleCaller(hasRole('TELE_CALLER'));
      setIsLeadManager(hasRole('LEAD_MANAGER'));
    }
  }, [employee]);

  return (
    <>
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItem: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <Box
            sx={{ boxShadow: '#d0cfcf 0px 1px 4px', borderRadius: '10px', bgcolor: 'background.paper' }}
            paddingX={{ xl: 2, lg: 2, md: 2, sm: 1, xs: 0.5 }}
            paddingTop={1}
            paddingBottom={3}
          >
            <Stack direction="row" alignItems="center" spacing={2} justifyContent="end" mb={4}>
              {employee.role.some((r) => r.name === 'LEAD_MANAGER') && (
                <Button
                  variant="contained"
                  endIcon={<RemoveRedEye />}
                  onClick={() => navigate(`/dashboard/crm/telecaller?managerId=${employee._id}`)}
                >
                  View Telecallers
                </Button>
              )}
              {isTeleCaller && managerId && (
                <Button
                  variant="contained"
                  startIcon={<RemoveRedEye />}
                  onClick={() => {
                    navigate(`/dashboard/general/employees/view/${managerId}`);
                  }}
                >
                  Lead Manager
                </Button>
              )}
              {isTeleCaller && (
                <Button
                  variant="contained"
                  startIcon={<RemoveRedEye />}
                  onClick={() => {
                    navigate('/dashboard/crm/lead', { state: { teleCaller: employee._id } });
                  }}
                >
                  Leads
                </Button>
              )}
              {isLeadManager && (
                <Button
                  variant="contained"
                  startIcon={<RemoveRedEye />}
                  onClick={() => {
                    navigate('/dashboard/crm/lead', { state: { leadManager: employee._id } });
                  }}
                >
                  Leads
                </Button>
              )}

              <FormControl sx={{ minWidth: 100 }}>
                <Select size="small" value={employee?.isBlocked} onChange={(e) => handleStatusChange(e, employee)}>
                  <MenuItem value={'false'}>🟢 Active</MenuItem>
                  <MenuItem value={'true'}>🔴 Blocked</MenuItem>
                </Select>
                {popup && <Popup item={employee} handleClose={handleClose} />}
              </FormControl>
            </Stack>
            <Box maxWidth="lg">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={5} lg={4}>
                  <Stack
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                      height: '100%',
                      width: '100%',
                    }}
                    spacing={2}
                  >
                    <Box
                      component="img"
                      src={
                        (employee?.profilePic && process.env.REACT_APP_API_URL + employee.profilePic) ||
                        'https://www.w3schools.com/howto/img_avatar.png'
                      }
                      sx={{ width: '150px', borderRadius: '50%', height: '150px', objectFit: 'cover' }}
                    />
                    {employee?.role?.map((role, index) => (
                      <Grid item xs={12} sm={6} md={6} key={index}>
                        <Chip
                          label={role?.name?.replaceAll('_', ' ')}
                          icon={<Iconify sx={{ width: '25px', height: '25px' }} icon={getIcon(role.name)} />}
                          color={setChipColor(role.name)}
                        />
                      </Grid>
                    ))}
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6} md={7} lg={8}>
                  {!editMode && (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        height: '100%',
                        alignItems: 'center',
                        maxWidth: '600px',
                        margin: 'auto',
                      }}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <DetailItem
                            title="Full Name"
                            data={employee?.fullname?.toUpperCase()}
                            icon={'mdi:user-circle'}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <DetailItem title="Email" data={employee?.email} icon={'mdi:email'} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <CopyToClipboard text={employee?._id} onCopy={() => toast.success('Employee ID Copied')}>
                            <DetailItem
                              title="Employee ID"
                              data={employee?._id}
                              icon={'mdi:account-badge-horizontal'}
                              sx={{ cursor: 'pointer' }}
                            />
                          </CopyToClipboard>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <DetailItem
                            title="Member Since"
                            data={moment(employee?.createdAt).format('DD MMM YYYY')}
                            icon={'mdi:calendar'}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <DetailItem
                            title="Pending Amount"
                            icon="mdi:currency-inr"
                            data={paymentData?.balanceAmount || 0}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                  {editMode && (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        height: '100%',
                        alignItems: 'center',
                        maxWidth: '600px',
                        margin: 'auto',
                      }}
                    >
                      <EmployeeEditForm employee={employee} setEditMode={setEditMode} getEmployee={getEmployee} />
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Box>
            {!editMode && (
              <Stack direction="row" alignItems="center" justifyContent="end" mt={2}>
                <IconButton onClick={() => setEditMode(!editMode)}>
                  <BorderColor />
                </IconButton>
              </Stack>
            )}
          </Box>
        </>
      )}
    </>
  );
}
