import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  Grid,
  Stack,
  TablePagination,
  TextareaAutosize,
  TextField,
  Typography,
} from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import LeadNoteCard from './LeadNoteCard';

export default function LeadNotes({ getLead }) {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [note, setNote] = useState('');
  const [pages, setPages] = useState(0);
  const [size, setSize] = useState(10);
  const [options, setOptions] = useState({ size, page: 0, leadId: id });
  const [notes, setNotes] = useState([]);
  const [notesCount, setNotesCount] = useState(0);
  const [open, setOpen] = useState(false);

  const addNote = async () => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/leadNote`, {
        leadId: id,
        note,
      });
      toast(res.data.message);
      setOpen(false);
      getLead();
      setNote('');
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  };

  const getNotes = async (options) => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/leadNote/all`, {
        params: options,
      });
      console.log('notes', res);
      setIsLoading(false);
      if (res.data.isError) {
        toast.error(res.data.message);
      } else {
        setNotes(res.data.data.records);
        setNotesCount(res.data.data.maxRecords);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePageChange = (event, newPage) => {
    const temp = { ...options, page: newPage };
    setPages(newPage);
    setOptions(temp);
    setIsLoading(true);
    getNotes(temp);
  };

  useEffect(() => {
    getNotes(options);
  }, []);

  useEffect(() => {
    const temp = { ...options, size, page: 0, leadId: id };
    setPages(0);
    setOptions(temp);
    getNotes(temp);
  }, [size]);


  return (
    <Box
      // sx={{ boxShadow: '#d0cfcf 0px 1px 4px', borderRadius: '10px', bgcolor: 'background.paper' }}
      paddingBottom={2}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography sx={{ fontSize: '20px', fontWeight: 'bold', mb: 1 }}>Notes</Typography>
        <Button variant="contained" onClick={() => setOpen(true)}>
          Add Note
        </Button>
      </Stack>

      {/* add note dialog  */}
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'end',
            alignItem: 'center',
          }}
          marginX={3}
          marginTop={3}
          marginBottom={1}
        >
          <TextField
            sx={{ width: '100%' }}
            multiline
            rows={4}
            placeholder="Add note here"
            variant="outlined"
            value={note}
            onChange={(e) => setNote(e.target.value)}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            direction: 'row',
            justifyContent: 'end',
            alignItem: 'center',
          }}
          marginX={2}
          marginTop={1}
          marginBottom={2}
        >
          <Button variant="contained" sx={{ mt: 1, mr: 1 }} onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="contained" sx={{ mt: 1 }} onClick={addNote}>
            Save
          </Button>
        </Box>
      </Dialog>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItem: 'center',
          direction: 'column',
          mt: 2,
          width: '100%',
        }}
      >
        {isLoading ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItem: 'center',
              height: '50vh',
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <Stack direction="column" sx={{ width: '100%' }} spacing={2}>
            {notes.length > 0 ? (
              notes.map((note, index) => <LeadNoteCard key={index} note={note} getLead={getLead} />)
            ) : (
              <Typography sx={{ fontSize: '16px', fontWeight: 'bold', mt: 2 }}>No notes found</Typography>
            )}
          </Stack>
        )}
      </Box>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        onRowsPerPageChange={(e) => {
          setSize(e.target.value);
        }}
        component={'div'}
        count={notesCount}
        showFirstButton
        showLastButton
        onPageChange={handlePageChange}
        rowsPerPage={size}
        page={pages}
      />
    </Box>
  );
}
