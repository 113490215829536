import { useState } from 'react';
// material
import { Container, Typography, Card, Tab, Box, Stack } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import ProjectQuotationTab from './ProjectQuotationTabs';
import ProjectPurchases from './ProjectPurchases';
import ProjectsAccountTab from './ProjectsAccountsTab';

// components

export default function ProjectDetailsPageTabs({ project }) {
  const [value, setValue] = useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Card>
      <Box sx={{ width: '100%' }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange}>
              <Tab label="Quotation" value="1" />
              <Tab label="Materials" value="2" />
              <Tab label="Accounts" value="3" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <ProjectQuotationTab project={project} />
          </TabPanel>
          <TabPanel value="2">
            <ProjectPurchases project={project} />
          </TabPanel>
          <TabPanel value="3">
            <ProjectsAccountTab project={project} />
          </TabPanel>
        </TabContext>
      </Box>
    </Card>
  );
}
