import { useState } from 'react';
// material
import { Container, Typography, Card, Tab, Box, Stack } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';

// components
import Page from '../components/Page';
import LeadSources from './LeadSources';
import LeadStatuss from './LeadStatus';
import LeadCategories from './LeadCategories';
import NavigateBack from '../components/smallComponents/NavigateBack';

const tabStyle = {
  '&.Mui-selected': {
    color: 'primaryDark.main',
  },
};

export default function Settings() {
  const [value, setValue] = useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Page title="Settings">
        <Container maxWidth="xl">
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="h4" gutterBottom>
              Settings
            </Typography>
            <NavigateBack />
          </Stack>
          <Card>
            <Box sx={{ width: '100%', marginTop: '20px' }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList onChange={handleChange}>
                    <Tab sx={tabStyle} label="Lead Sources" value="1" />
                    <Tab sx={tabStyle} label="Lead Status" value="2" />
                    <Tab sx={tabStyle} label="Lead Categories" value="3" />
                  </TabList>
                </Box>
                <TabPanel value="1">
                  <LeadSources />
                </TabPanel>
                <TabPanel value="2">
                  <LeadStatuss />
                </TabPanel>
                <TabPanel value="3">
                  <LeadCategories />
                </TabPanel>
              </TabContext>
            </Box>
          </Card>
        </Container>
      </Page>
    </>
  );
}
