import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import { useParams } from 'react-router-dom';
import React, { useState } from 'react';
import PaymentsEmployee from './PaymentsEmployee';
import SalaryTable from './SalaryTable';

function EmployeeTables() {
  const { id } = useParams();
  const [value, setValue] = useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box
      sx={{ boxShadow: '#d0cfcf 0px 1px 4px', borderRadius: '10px', bgcolor: 'background.paper' }}
      paddingX={{ xl: 2, lg: 2, md: 2, sm: 1, xs: 0.5 }}
      paddingTop={1}
      paddingBottom={1}
    >
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange}>
            <Tab label=" Payments" value="1" />
            <Tab label="Pending Salary" value="2" />
            <Tab label="Paid Salary" value="3" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <PaymentsEmployee employeeId={id} />{' '}
        </TabPanel>
        <TabPanel value="2">
          <SalaryTable status={'pending'} employeeId={id} />
        </TabPanel>
        <TabPanel value="3">
          <SalaryTable status={'completed'} employeeId={id} />
        </TabPanel>
      </TabContext>
    </Box>
  );
}

export default EmployeeTables;
